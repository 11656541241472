import React, { memo, useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ArrowBackIcon, DeleteIcon, EditIcon, InformationOutlineIcon, RecycleIcon } from "mdi-react";
import { css } from "emotion";
import { notification, Space, Tooltip } from "antd";

import Button from "../../../ui/Button";
import InlineSpinner from "../../../ui/InlineSpinner";
import ListItem from "../../../ui/ListItem";
import Page from "../../../ui/Page";
import ScrollView from "../../../ui/ScrollView";
import StatusBox from "../../../ui/StatusBox";
import Toggle from "../../../ui/Toggle";
import TopBar from "../../../ui/TopBar";
import AccessCheck from "../../../ui/AccessCheck";

import colors from "../../../../style/colors";

import pageNavigator from "../../../../utilities/page-navigator";

import usePageAndBackButtonURL from "../../../../hooks/usePageAndBackButtonURL";

import Modal from "./Modal";
import useOverviewData from "./hooks/useOverviewData";

import { hideModalPage, showDialog, showModalPage } from "../../../../actions/uiActions";

import getErrorMessage from "../../../../utilities/get-error-message";
import req from "../../../../utilities/request-utility";

const ManagePunchListOverview = (props) => {
  const { hideModalPage, match, selectedProject, showDialog, showModalPage } = props;
  const [deleting, setDeleting] = useState(false);
  const [restoring, setRestoring] = useState(false);
  const [punchListIdToBeDeleted, setPunchListIdToBeDeleted] = useState("");
  const [punchListIdToBeRestored, setPunchListIdToBeRestored] = useState("");
  const [showDeletedRecords, setShowDeletedRecords] = useState(false);

  const queryClient = useQueryClient();

  const { backButtonURL } = usePageAndBackButtonURL({ match });

  const { punchListData } = useOverviewData({ query: { isDeleted: showDeletedRecords } });

  const { data, error, loading } = punchListData;

  const closeModal = useCallback(() => {
    showDialog({
      title: "Hey there!",
      content: "Your changes will not be saved. Are you sure you want to close the form?",
      primaryActionTitle: "Yes, close the form",
      primaryAction: hideModalPage,
      secondaryActionTitle: "No, don't close the form",
    });
  }, []);

  const deletePunchList = useCallback(
    (id) => {
      showDialog({
        title: "Hey there!",
        content: "You are about to deactivate this punch list. Are you sure you want to deactivate it?",
        primaryActionTitle: "Yes, deactivate punch list",
        primaryAction: () => {
          setDeleting(true);

          const handleOk = () => {
            queryClient.invalidateQueries(["punchList", { isDeleted: showDeletedRecords }]);

            setDeleting(false);
            setPunchListIdToBeDeleted("");

            notification.success({
              duration: 7,
              message: "SUCCESS",
              description: `You have successfully deactivated a punch list`,
            });
          };

          const handleError = (err) => {
            const error = getErrorMessage(err);

            setDeleting(false);
            setPunchListIdToBeDeleted("");

            notification.error({ duration: 7, message: "FAILED", description: error });
          };

          req().put(`semcompletion/v2/punches/punch-list/${id}`, { isDeleted: true }).then(handleOk).catch(handleError);
        },
        secondaryActionTitle: "No, don't deactivate punch list",
      });
    },
    [showDeletedRecords]
  );

  const restorePunchList = useCallback(
    (id) => {
      showDialog({
        title: "Hey there!",
        content: "You are about to restore this punch list. Are you sure you want to restore it?",
        primaryActionTitle: "Yes, restore punch list",
        primaryAction: () => {
          setRestoring(true);

          const handleOk = () => {
            queryClient.invalidateQueries(["punchList", { isDeleted: showDeletedRecords }]);

            setRestoring(false);
            setPunchListIdToBeRestored("");

            notification.success({
              duration: 7,
              message: "SUCCESS",
              description: `You have successfully restored a punch list`,
            });
          };

          const handleError = (err) => {
            const error = getErrorMessage(err);

            setRestoring(false);
            setPunchListIdToBeRestored("");

            notification.error({ duration: 7, message: "FAILED", description: error });
          };

          req().put(`semcompletion/v2/punches/punch-list/${id}`, { isDeleted: false }).then(handleOk).catch(handleError);
        },
        secondaryActionTitle: "No, don't restore punch list",
      });
    },
    [showDeletedRecords]
  );

  return (
    <Page className={container()}>
      <TopBar
        title={`Manage Punch List (${selectedProject.number} - ${selectedProject.description})`}
        actionLeft={<ArrowBackIcon onClick={() => pageNavigator(backButtonURL, "backward")} />}
      />

      <AccessCheck pageId={props.match.params.pageId}>
        <div className="header">
          <div className="row">
            <Button
              onClick={() =>
                showModalPage({
                  closeCallback: closeModal,
                  content: <Modal />,
                  title: `Add Punch List (${selectedProject.number} - ${selectedProject.description})`,
                })
              }
              style={{ flexBasis: "30%", flexShrink: 0, width: "100%" }}
            >
              Add Punch List
            </Button>
            <Space>
              <Toggle on={showDeletedRecords} onClick={() => setShowDeletedRecords(!showDeletedRecords)} />
              <span>Show deactivated records</span>
            </Space>
          </div>
        </div>
        {showDeletedRecords ? (
          <div className="remark">
            <InformationOutlineIcon />
            <p className="line-break">You are viewing deactivated records.</p>
          </div>
        ) : (
          ""
        )}
        <ScrollView>
          {loading && !error && <InlineSpinner />}
          {!loading && error && <StatusBox />}
          {!loading && !error && data.length !== 0 && (
            <div style={{ padding: "1rem 0px" }}>
              {data.map((d) => {
                return (
                  <ListItem
                    iconRight={
                      !showDeletedRecords ? (
                        <Space>
                          <Tooltip title="Edit">
                            <EditIcon
                              onClick={() =>
                                showModalPage({
                                  closeCallback: closeModal,
                                  content: <Modal editMode={true} initialForm={d} />,
                                  title: `Edit Punch List (${selectedProject.number} - ${selectedProject.description})`,
                                })
                              }
                            />
                          </Tooltip>
                          {deleting && punchListIdToBeDeleted === d.id ? (
                            <InlineSpinner />
                          ) : (
                            <Tooltip title="Deactivate">
                              <DeleteIcon
                                onClick={() => {
                                  setPunchListIdToBeDeleted(d.id);
                                  deletePunchList(d.id);
                                }}
                                style={{ color: "red" }}
                              />
                            </Tooltip>
                          )}
                        </Space>
                      ) : restoring && punchListIdToBeRestored === d.id ? (
                        <InlineSpinner />
                      ) : (
                        <Tooltip title="Activate">
                          <RecycleIcon
                            onClick={() => {
                              setPunchListIdToBeRestored(d.id);
                              restorePunchList(d.id);
                            }}
                            style={{ color: "#103447" }}
                          />
                        </Tooltip>
                      )
                    }
                    subTitle={`${d.idPrefix} - ${d.projectType}`}
                    title={d.name}
                  />
                );
              })}
            </div>
          )}
        </ScrollView>
      </AccessCheck>
    </Page>
  );
};

const container = () => css`
  .header {
    background-color: ${colors.white};
    padding: 1rem;

    .punch-count-container {
      flex-basis: 50%;
      padding: 0 0.5rem;
      display: flex;
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.85rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      .dropdown {
        flex-grow: 1;
        margin: 0 0.35rem;
        flex-basis: 25%;

        &:first-of-type {
          margin-left: 0;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  .remark {
    display: flex;
    padding: 0.5rem;
    background-color: ${colors.yellowLight};
    margin: 0.75rem;
    border: 2px solid ${colors.yellow};
    border-radius: 5px;

    svg {
      margin-right: 0.5rem;
    }
  }

  button svg {
    display: inline-block;
    margin-bottom: -0.35rem;
  }
`;

const mapStateToProps = (state) => ({
  selectedProject: state.semcompletion.selectedProject,
});

const mapDispatchToProps = (dispatch) => ({
  showModalPage: bindActionCreators(showModalPage, dispatch),
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
  showDialog: bindActionCreators(showDialog, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(ManagePunchListOverview));
