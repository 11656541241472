import React, { Component } from "react";
import { css } from "emotion";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import smoothScrollToTop from "../../utilities/smooth-scroll-to-top";

const containerStyle = {
  backgroundColor: "#103447",
  height: 35,
  position: "fixed",
  bottom: 20,
  width: 35,
  WebkitTransition: "all 0.5s ease-in-out",
  transition: "all 0.5s ease-in-out",
  transitionProperty: "opacity, right",
  cursor: "pointer",
  right: 20,
  zIndex: 1000,
  borderRadius: "50%",
  padding: 5,
};

const svgStyle = {
  display: "inline-block",
  width: "100%",
  height: "100%",
  strokeWidth: 0,
  stroke: "white",
  fill: "white",
};

class ScrollView extends Component {
  constructor(props) {
    super(props);
    this.targetRef = React.createRef();
    this.onScroll = this.onScroll.bind(this);

    this.state = {
      lastScrollTop: 100,
      buttonVisible: false,
    };
  }

  componentDidMount() {
    disableBodyScroll(this.targetRef.current);
    this.targetRef.current.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    enableBodyScroll(this.targetRef.current);
    this.targetRef.current.removeEventListener("scroll", this.handleScroll);
  }

  onScroll(e) {
    if (
      typeof this.props.onScrollEnd === "function" &&
      e.target.scrollTop + e.target.getBoundingClientRect().height + (200 || this.props.scrollThreshold) >
        e.target.scrollHeight
    ) {
      this.props.onScrollEnd();
    }

    if (typeof this.props.onScroll === "function") {
      // For legacy implementations where the scrollView's scroll is handled in parent component
      this.props.onScroll(e);
    }
  }

  handleScroll = () => {
    const scrollTop = this.targetRef.current.scrollTop;
    if (scrollTop > this.state.lastScrollTop) {
      this.setState({ buttonVisible: true });
    } else {
      this.setState({ buttonVisible: false });
    }
  };

  scrollToTop() {
    // smoothScrollToTop(this.targetRef.current, 1000);
    this.targetRef.current.scrollTo({ top: 0, behavior: "smooth" });
    if (this.props.setScrollTop) this.props.setScrollTop(false);
  }

  render() {
    if (this.props.scrollTop) {
      this.scrollToTop();
    }

    return (
      <div
        id={this.props.id}
        ref={this.targetRef}
        className={`scroll-view ${componentStyle(this.props)} ${this.props.className || ""} ${
          this.props.maxWidth ? "use-max-width" : ""
        } ${this.props.minHeight ? "use-min-height" : ""}`}
        style={this.props.style}
        onScroll={this.onScroll}
        data-test-id={this.props["data-test-id"]}
      >
        {this.props.children}

        {this.state.buttonVisible && (
          <div onClick={() => this.scrollToTop()} style={containerStyle}>
            <svg
              viewBox="0 0 32 32"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0"
              y="0"
              xmlSpace="preserve"
              style={svgStyle}
            >
              <path
                transform="scale(1.4) translate(1,-5)"
                d="M19.196 23.429q0 0.232-0.179 0.411l-0.893 0.893q-0.179 0.179-0.411 0.179t-0.411-0.179l-7.018-7.018-7.018 7.018q-0.179 0.179-0.411 0.179t-0.411-0.179l-0.893-0.893q-0.179-0.179-0.179-0.411t0.179-0.411l8.321-8.321q0.179-0.179 0.411-0.179t0.411 0.179l8.321 8.321q0.179 0.179 0.179 0.411zM19.196 16.571q0 0.232-0.179 0.411l-0.893 0.893q-0.179 0.179-0.411 0.179t-0.411-0.179l-7.018-7.018-7.018 7.018q-0.179 0.179-0.411 0.179t-0.411-0.179l-0.893-0.893q-0.179-0.179-0.179-0.411t0.179-0.411l8.321-8.321q0.179-0.179 0.411-0.179t0.411 0.179l8.321 8.321q0.179 0.179 0.179 0.411z" // eslint-disable-line
              />
            </svg>
          </div>
        )}
      </div>
    );
  }
}

const componentStyle = (props) => css`
  flex: ${typeof props.isPunchFormOpen === "undefined" || props.isPunchFormOpen ? "auto" : "0"};
  overflow-x: ${props.allowHorizontalScroll === true ||
  (typeof props.isPunchFormOpen !== "undefined" && props.isPunchFormOpen)
    ? "auto"
    : typeof props.isPunchFormOpen !== "undefined" && !props.isPunchFormOpen
    ? "visible"
    : "hidden"};
  overflow-y: ${typeof props.isPunchFormOpen !== "undefined" && props.isPunchFormOpen ? "auto" : "visible"};
  -webkit-overflow-scrolling: touch;

  &.use-max-width {
    max-width: ${props.maxWidth}px;
    margin: 0 auto;
  }

  &.use-min-height {
    min-height: ${props.minHeight}px;
  }
`;

export default React.forwardRef((props, ref) => <ScrollView {...props} forwardedRef={ref} />);
