import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import params from "jquery-param";

import req from "../../../../../utilities/request-utility";

const useOverviewData = ({ query }) => {
  const selectedProject = useSelector((s) => s.semcompletion.selectedProject);

  const {
    isLoading: metaDataIsLoading,
    error: metaDataError,
    data: { data: metaDataData = {} } = {},
  } = useQuery(`${selectedProject ? selectedProject.id : "project"}-cable-metaData`, () =>
    req()(`semcompletion/cables/meta-data`)
  );

  const metaData = { data: metaDataData, loading: metaDataIsLoading, error: metaDataError };

  const {
    data: { data: cablesPageData = [] } = {},
    error: cablesPageError = null,
    isFetching: cablesPageFetching = false,
    refetch: cablesPageRefetch,
  } = useQuery(
    ["cablesPage", query],
    (param) => {
      return req()(`semcompletion/cables?${params(query)}`);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const cablesData = {
    data: cablesPageData,
    error: cablesPageError,
    loading: cablesPageFetching,
    refetch: cablesPageRefetch,
  };

  return { cablesData, metaData };
};

export default useOverviewData;
