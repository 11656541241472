import React, { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  ArchiveIcon,
  ChevronRightIcon,
  DeleteIcon,
  DotsVerticalIcon,
  EditIcon,
  FilePdfIcon,
  LinkIcon,
  RestoreIcon,
  YoutubeIcon,
  VideoIcon,
} from "mdi-react";
import { notification, Typography } from "antd";
import { has, isEqual } from "lodash";

import ListItem from "../ui/ListItem";

import {
  setLibraryFolderQuery,
  setManageLibraryFileQuery,
  setManageLibraryFolderQuery,
  setManageLibraryURLQuery,
  setSelectedFolder,
  setUrlSuffix,
} from "../../actions/libraryActions";
import { hideModalPage, showDialog, showModalPage } from "../../actions/uiActions";

import getPageFromId from "../../utilities/get-page-from-id";
import getYoutubeId from "../../utilities/get-youtube-id";
import linkPathCreator from "../../utilities/get-link-path-from-page-object";
import pageNavigator from "../../utilities/page-navigator";
import req from "../../utilities/request-utility";

import FolderModal from "./admin/manageLibrary/Modal";
import FileAndURLModal from "./admin/manageLibrary/UploadForm";

import PDFViewer from "./PDFViewer";
import VideoPlayer from "./VideoPlayer";

import breakpoints from "../../config/breakpoints";

import { showContextMenu } from "../../actions/uiActions";

const LibraryRenderer = ({
  dataId,
  folderFilesData,
  folderFilesRefetch,
  folderOptions,
  folderOptionsRefetch,
  folderFileOptions,
  folderUrlOptions,
  folderRefetch,
  foldersData,
  folderUrlsData,
  folderUrlsRefetch,
  isAdmin,
  pageId,
  showArchive,
}) => {
  const [isArchivingOrRestoring, setIsArchivingOrRestoring] = useState("");

  const dispatch = useDispatch();

  const isPortrait = useMediaQuery({ orientation: "portrait" });

  const { libraryFolderQuery, pages, selectedFolder, selectedProject, urlSuffix } = useSelector(
    ({ library, pages, semcompletion }) => ({
      libraryFolderQuery: library.libraryFolderQuery,
      pages: pages.pages,
      selectedFolder: library.selectedFolder,
      selectedProject: semcompletion.selectedProject,
      urlSuffix: library.urlSuffix,
    }),
    isEqual
  );

  const page = getPageFromId(pages, pageId);

  const getFolderChildItems = useCallback(
    (id) => {
      const childFolders = folderOptions.filter((d) => d.parentFolder === id);
      const childFolderFilesIds = folderFileOptions.filter((d) => d.folderId === id).map((d) => d.id);
      const childFolderUrlsIds = folderUrlOptions.filter((d) => d.folderId === id).map((d) => d.id);

      let childData = {
        childFoldersIds: [],
        childFileIds: [...childFolderFilesIds],
        childUrlIds: [...childFolderUrlsIds],
      };

      if (childFolders.length === 0) {
        if (childData.childFileIds.length !== 0 || childData.childUrlIds.length !== 0) return childData;
        return null;
      }

      childFolders.map((d) => {
        childData.childFoldersIds.push(d.id);

        const childResult = getFolderChildItems(d.id);

        if (childResult) {
          childData = {
            ...childData,
            childFoldersIds: [...childData.childFoldersIds, ...childResult.childFoldersIds],
            childFileIds: [...childData.childFileIds, ...childResult.childFileIds],
            childUrlIds: [...childData.childUrlIds, ...childResult.childUrlIds],
          };
        }
      });

      return childData;
    },
    [folderOptions, folderFileOptions, folderUrlOptions]
  );

  const handleShowContextMenu = useCallback(
    (e, data, type) => {
      e.stopPropagation();

      const contextMenu = [];

      if (data.active)
        switch (type) {
          case "folder":
            contextMenu.push({
              callback: () =>
                dispatch(
                  showModalPage({
                    closeCallback: () =>
                      dispatch(
                        showDialog({
                          content: "Your changes will not be saved. Are you sure you want to close the form?",
                          primaryAction: () => dispatch(hideModalPage()),
                          primaryActionTitle: "Yes, close the form",
                          secondaryActionTitle: "No, don't close the form",
                          title: "Hey there!",
                        })
                      ),
                    content: (
                      <FolderModal
                        folderOptions={folderOptions}
                        folderOptionsRefetch={folderOptionsRefetch}
                        folderRefetch={folderRefetch}
                        form={{ id: data.id, name: data.name, parentFolder: data.parentFolder }}
                      />
                    ),
                    title: `Edit Folder (${selectedProject.number} - ${selectedProject.description})`,
                  })
                ),
              icon: <EditIcon />,
              title: "Edit",
            });
            break;
          case "file":
            contextMenu.push({
              callback: () =>
                dispatch(
                  showModalPage({
                    closeCallback: () =>
                      dispatch(
                        showDialog({
                          content: "Your changes will not be saved. Are you sure you want to close the form?",
                          primaryAction: () => dispatch(hideModalPage()),
                          primaryActionTitle: "Yes, close the form",
                          secondaryActionTitle: "No, don't close the form",
                          title: "Hey there!",
                        })
                      ),
                    content: (
                      <FileAndURLModal
                        currentFile={data}
                        folderFilesRefetch={folderFilesRefetch}
                        folderOptions={folderOptions}
                        folderRefetch={folderRefetch}
                      />
                    ),
                    title: `Edit File (${selectedProject.number} - ${selectedProject.description})`,
                  })
                ),
              icon: <EditIcon />,
              title: "Edit",
            });
            break;
          case "url":
            contextMenu.push({
              callback: () =>
                dispatch(
                  showModalPage({
                    closeCallback: () =>
                      dispatch(
                        showDialog({
                          content: "Your changes will not be saved. Are you sure you want to close the form?",
                          primaryAction: () => dispatch(hideModalPage()),
                          primaryActionTitle: "Yes, close the form",
                          secondaryActionTitle: "No, don't close the form",
                          title: "Hey there!",
                        })
                      ),
                    content: (
                      <FileAndURLModal
                        currentURL={data}
                        folderOptions={folderOptions}
                        folderRefetch={folderRefetch}
                        folderUrlsRefetch={folderUrlsRefetch}
                      />
                    ),
                    title: `Edit URL (${selectedProject.number} - ${selectedProject.description})`,
                  })
                ),
              icon: <EditIcon />,
              title: "Edit",
            });
            break;
        }
      else {
        contextMenu.push({
          icon: <DeleteIcon />,
          title: "Delete",
          callback: async () =>
            dispatch(
              showDialog({
                content:
                  type === "folder"
                    ? "Deleting this folder will also permanently remove all items inside it. Are you sure you want to continue?"
                    : "Deleting this item will permanently remove it. Are you sure you want to continue?",
                primaryAction: async () => {
                  setIsArchivingOrRestoring(data.id);

                  switch (type) {
                    case "folder":
                      const folderChildItems = getFolderChildItems(data.id);

                      if (folderChildItems === null) {
                        await req().delete(`semcompletion/library/folders/${data.id}/delete`);

                        folderRefetch();

                        notification.success({
                          duration: 7,
                          message: "Success",
                          description: `Folder deleted`,
                        });

                        break;
                      }

                      let { childFoldersIds, childFileIds, childUrlIds } = folderChildItems;

                      childFoldersIds = [...childFoldersIds, data.id];

                      const childFoldersDeleteRequests = [];
                      const childFilesDeleteRequests = [];
                      const childUrlsDeleteRequests = [];

                      childFoldersIds.map((d) =>
                        childFoldersDeleteRequests.push(req().delete(`semcompletion/library/folders/${d}/delete`))
                      );

                      childFileIds.map((d) =>
                        childFilesDeleteRequests.push(
                          req().delete(`semcompletion/library/files/${d}/${selectedProject.number}/delete`)
                        )
                      );

                      childUrlIds.map((d) =>
                        childUrlsDeleteRequests.push(req().delete(`semcompletion/library/urls/${d}/delete`))
                      );

                      await Promise.all([
                        ...childFoldersDeleteRequests,
                        ...childFilesDeleteRequests,
                        ...childUrlsDeleteRequests,
                      ]);

                      folderRefetch();
                      folderUrlsRefetch();
                      folderFilesRefetch();

                      notification.success({
                        duration: 7,
                        message: "Success",
                        description: `Folder deleted`,
                      });
                      break;
                    case "url":
                      await req().delete(`semcompletion/library/urls/${data.id}/delete`);

                      folderUrlsRefetch();

                      notification.success({
                        duration: 7,
                        message: "Success",
                        description: `URL deleted`,
                      });
                      break;
                    case "file":
                      await req().delete(`semcompletion/library/files/${data.id}/${selectedProject.number}/delete`);

                      folderFilesRefetch();

                      notification.success({
                        duration: 7,
                        message: "Success",
                        description: `File deleted`,
                      });
                      break;
                  }
                },
                primaryActionTitle: `Yes, delete the ${type === "folder" ? "folder" : "item"}`,
                secondaryActionTitle: `No, don't delete the ${type === "folder" ? "folder" : "item"}`,
                title: "Hey there!",
              })
            ),
        });
      }

      contextMenu.push({
        icon: data.active ? <ArchiveIcon /> : <RestoreIcon />,
        title: data.active ? "Archive" : "Restore",
        callback: async () => {
          try {
            setIsArchivingOrRestoring(data.id);

            switch (type) {
              case "folder":
                await req().put(`semcompletion/library/folders/${data.id}/archive`, { active: !data.active });

                folderRefetch();

                notification.success({
                  duration: 7,
                  message: "Success",
                  description: `Folder ${data.active ? "archived" : "restored"}`,
                });
                break;
              case "file":
                await req().put(`semcompletion/library/files/${data.id}/archive`, { active: !data.active });

                folderFilesRefetch();

                notification.success({
                  duration: 7,
                  message: "Success",
                  description: `File ${data.active ? "archived" : "restored"}`,
                });
                break;
              case "url":
                await req().put(`semcompletion/library/urls/${data.id}/archive`, { active: !data.active });

                folderUrlsRefetch();

                notification.success({
                  duration: 7,
                  message: "Success",
                  description: `URL ${data.active ? "archived" : "restored"}`,
                });
                break;
            }

            setIsArchivingOrRestoring("");
          } catch (error) {
            setIsArchivingOrRestoring("");
            notification.error({ duration: 7, message: "Error", description: error.message });
          }
        },
      });

      dispatch(showContextMenu(contextMenu));
    },
    [folderOptions]
  );

  const handleNavigate = useCallback(
    (e, folder) => {
      e.stopPropagation();

      const newUrlSuffix = urlSuffix !== "" ? `${urlSuffix}/${folder.id}` : `${folder.id}`;

      dispatch(setUrlSuffix(newUrlSuffix));
      dispatch(setSelectedFolder(folder));
      dispatch(setLibraryFolderQuery({ ...libraryFolderQuery, parentFolderId: folder.id }));
      dispatch(setManageLibraryFileQuery({ active: !showArchive }));
      dispatch(setManageLibraryFolderQuery({ active: !showArchive, parentFolderId: folder.id }));
      dispatch(setManageLibraryURLQuery({ active: !showArchive }));

      pageNavigator(linkPathCreator({ ...page, dataId: folder.id, urlSuffix: newUrlSuffix }, "absolute"), "forward");
    },
    [urlSuffix, page, libraryFolderQuery, showArchive]
  );

  return (
    <>
      {selectedFolder ? (
        <Typography.Title
          level={4}
          style={{ marginLeft: "auto", marginRight: "auto", width: isPortrait ? breakpoints.xs : breakpoints.lg }}
        >
          {selectedFolder.name}
        </Typography.Title>
      ) : (
        <></>
      )}
      {foldersData
        .filter((d) => {
          if (!d.parentFolder) return d;
          return d.parentFolder && dataId !== "" && d.parentFolder === dataId;
        })
        .map((d) => {
          return (
            <ListItem
              clickable={d.id !== isArchivingOrRestoring && d.active}
              iconRight={
                isAdmin ? (
                  <div style={{ display: "flex" }}>
                    <DotsVerticalIcon onClick={(e) => handleShowContextMenu(e, d, "folder")} /> <ChevronRightIcon />
                  </div>
                ) : (
                  <ChevronRightIcon />
                )
              }
              key={d.id}
              maxWidth={isPortrait ? breakpoints.xs : breakpoints.lg}
              onClick={(e) => (d.active ? handleNavigate(e, d) : null)}
              style={d.id === isArchivingOrRestoring ? { opacity: 0.5 } : {}}
              title={d.name}
            />
          );
        })}
      {[...folderFilesData, ...folderUrlsData]
        .sort((a, b) => {
          const dateA = new Date(a.createDate);
          const dateB = new Date(b.createDate);

          if (dateA > dateB) return 1;
          if (dateA < dateB) return -1;
          return 0;
        })
        .map((d) => {
          if (has(d, "file"))
            return (
              <ListItem
                clickable={d.id !== isArchivingOrRestoring && d.active}
                iconRight={
                  isAdmin ? (
                    <div style={{ display: "flex" }}>
                      <DotsVerticalIcon onClick={(e) => handleShowContextMenu(e, d, "file")} />
                      {!d.fileName.includes(".pdf") ? <VideoIcon /> : <FilePdfIcon />}
                    </div>
                  ) : !d.fileName.includes(".pdf") ? (
                    <VideoIcon />
                  ) : (
                    <FilePdfIcon />
                  )
                }
                key={d.id}
                maxWidth={isPortrait ? breakpoints.xs : breakpoints.lg}
                onClick={() =>
                  d.active
                    ? dispatch(
                        showModalPage({
                          content: !d.fileName.includes(".pdf") ? (
                            <VideoPlayer url={d.file} />
                          ) : (
                            <PDFViewer bookmarks={d.bookmarks} file={d.file} fileId={d.id} module="library" />
                          ),
                          title: `${d.fileName} (${selectedProject.number} - ${selectedProject.description})`,
                        })
                      )
                    : null
                }
                title={d.fileNameWithoutTimestamp}
                style={d.id === isArchivingOrRestoring ? { opacity: 0.5 } : {}}
              />
            );

          return (
            <ListItem
              clickable={d.id !== isArchivingOrRestoring && d.active}
              iconRight={
                isAdmin ? (
                  <div style={{ display: "flex" }}>
                    <DotsVerticalIcon onClick={(e) => handleShowContextMenu(e, d, "url")} />{" "}
                    {d.url.match(
                      /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/
                    ) ? (
                      <YoutubeIcon />
                    ) : (
                      <LinkIcon />
                    )}
                  </div>
                ) : d.url.match(
                    /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/
                  ) ? (
                  <YoutubeIcon />
                ) : (
                  <LinkIcon />
                )
              }
              key={d.id}
              maxWidth={isPortrait ? breakpoints.xs : breakpoints.lg}
              onClick={() =>
                d.active
                  ? d.url.match(
                      /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/
                    )
                    ? dispatch(
                        showModalPage({
                          content: <VideoPlayer url={d.url} />,
                          title: `${d.textToDisplay} (${selectedProject.number} - ${selectedProject.description})`,
                        })
                      )
                    : window.open(
                        !d.url.includes("www") && !d.url.includes("http")
                          ? `https://www.${d.url}`
                          : !d.url.includes("http") && d.url.includes("www")
                          ? `https://${d.url}`
                          : d.url.includes("http") && !d.url.includes("www")
                          ? `${d.url.split("//").join("//www.")}`
                          : d.url,
                        "_blank"
                      )
                  : null
              }
              title={d.textToDisplay !== null && d.textToDisplay !== "" ? d.textToDisplay : d.url}
              style={d.id === isArchivingOrRestoring ? { opacity: 0.5 } : {}}
            />
          );
        })}
    </>
  );
};

export default memo(LibraryRenderer);
