import React, { useEffect } from "react";
import queryString from "query-string";
import { notification } from "antd";

import Button from "../ui/Button";
import { ExternalLinkIcon, AlertDecagramIcon } from "mdi-react";
import { signInWithAdToken } from "../../actions/authActions";
import { useDispatch } from "react-redux";
import { clearLoginTypes } from "../../actions/appConfigActions";
import getAppName from "../../utilities/get-app-name";

const AdLogin = (props) => {
  const dispatch = useDispatch();

  /** The AD-login process is fairly simple:
   *
   * 1) The react app loads, it then fetches the app-config for current application. If
   *    this app-config has "ad" as one of the "allowedLoginTypes" then AD-login is initiated
   * 2) The app redirects to the AD-login-page located at `https://login.ekkoapp.dk/api/v2/sso/${appName}`
   *    Examples: https://login.ekkoapp.dk/api/v2/sso/nrgi-rep https://login.ekkoapp.dk/api/v2/sso/test etc.
   *    The appName parameter decides which ad should be used and where the page redirects back to
   * 3) If the user succesfully logs in, they will be redirected back to our app at ekkoapp.app/${appName}/?token=${userToken}
   *    The token returned is valid for a few seconds and matches a email.
   * 4) This token is posted to an endpoint at our api (/api/auth/sign-in-with-ad-token/) At this endpoint the
   *    api tries to exchange the token for a user. If this succeeds, a regular user and token will be returned
   *    just as if the user had logged in using password and email/phone instead
   * 5) The returned user and token is inserted into localstorage. From here on out there is nothing special about it.
   *
   *
   */

  useEffect(() => {
    let queryStrings = queryString.parse(window.location.search);
    if (queryStrings.token) {
      dispatch(signInWithAdToken(queryStrings.token));
    } else {
      if (process.env.NODE_ENV === "development") {
        return console.warn("AD-Redirect prevented because app is running in development mode");
      }

      // BUGFIX! Don't redirect for iOS version 12.4.X as this won't work in this specific version.
      if (navigator.userAgent.search(/CPU iPhone OS 12_4/gi) !== -1)
        return notification.error({
          duration: 7,
          message: "FAILED",
          description:
            "Your device doesn't support AD login. If possible upgrade iOS. If you cannot upgrade your device try to log in with a different login method using the 'Need help?' button.",
        });

      dispatch(clearLoginTypes());
      window.location = `https://login.ekkoapp.dk/api/v2/sso/${getAppName()}`;
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Button
        onClick={() => {
          window.location = `https://login.ekkoapp.dk/api/v2/sso/${getAppName()}`;
        }}
      >
        <ExternalLinkIcon
          style={{ width: "1.25rem", height: "1.25rem", marginRight: "0.5rem", verticalAlign: "text-bottom" }}
        />
        Go to AD-login
      </Button>
    </div>
  );
};

export default AdLogin;
