import getAppName from "./get-app-name";

export default function (apiConfig) {
  const manifestEl = document.getElementById("elManifest");
  const apiDomain = manifestEl.getAttribute("data-base-url");

  const apiURL = `https://${apiDomain}/api/`;

  manifestEl.setAttribute("href", `${apiURL}apps/manifest/${getAppName()}`);
}
