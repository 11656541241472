import React from "react";
import { Collapse, Switch } from "antd";
import { sortBy } from "lodash";

const ListItemCollapsible = ({ loading = false, onChangeStatus = () => {}, page = {} }) => {
  const { id, pages: childPages = [], title = "" } = page;

  const hasChildPages = childPages.length;

  if (page.module === "SemcompletionProjectChooser") return null;

  return (
    <Collapse collapsible={"icon"} expandIconPosition={"end"}>
      <Collapse.Panel
        showArrow={hasChildPages}
        header={title}
        key={id}
        extra={
          <Switch
            key={page.pageId}
            checkedChildren="On"
            unCheckedChildren="Off"
            checked={page.active}
            onChange={async (e) => {
              let modifiedPages = [{ id: page.id, active: e ? 1 : 0 }];

              //also turn off child pages
              if (hasChildPages) childPages.map((childPage) => modifiedPages.push({ id: childPage.id, active: e ? 1 : 0 }));

              onChangeStatus(modifiedPages);
            }}
            loading={loading}
            style={{ backgroundColor: page.active ? "#103447" : "#abaeac" }}
          />
        }
      >
        {sortBy(childPages, "title").map((childPage) => (
          <Collapse key={childPage.id} collapsible={"icon"}>
            <Collapse.Panel
              showArrow={false}
              header={childPage.title}
              key={childPage.id}
              extra={
                <Switch
                  key={childPage.pageId}
                  checkedChildren="On"
                  unCheckedChildren="Off"
                  checked={childPage.active}
                  onChange={(e) => onChangeStatus([{ id: childPage.id, active: e ? 1 : 0 }])}
                  loading={loading}
                  disabled={!page.active}
                  style={{ backgroundColor: childPage.active ? "#103447" : "#abaeac" }}
                />
              }
            />
          </Collapse>
        ))}
      </Collapse.Panel>
    </Collapse>
  );
};

export default ListItemCollapsible;
