import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { keyBy } from "lodash";
import { Result } from "antd";
import { css } from "emotion";

// UTILITIES
import req from "../../../../utilities/request-utility";

// ui-Components
import DropDown from "../../../ui/DropDown";
import FormItem from "../../../ui/FormItem";
import InlineSpinner from "../../../ui/InlineSpinner";

// COMPONENTS
import ProjectsList from "./ProjectsList";

const MenuAccess = ({ onUpdate, userId = null }) => {
  const [customer, setCustomer] = useState(null);
  const [projects, setProjects] = useState([]);
  const [projectsList, setProjectsList] = useState([]);

  const { data: { data: customersData = [] } = {} } = useQuery("Customers", () => req()("/semcompletion/customers"), {
    refetchOnWindowFocus: false,
  });

  const { isLoading: projectsLoading, data: { data: projectsData = [] } = {} } = useQuery(
    ["Projects", customer],
    ({ queryKey }) => {
      const [_, customer] = queryKey;
      return req()(`/semcompletion/projects?customer=${customer}`);
    },
    {
      enabled: !!customer,
      refetchOnWindowFocus: false,
    }
  );

  const { isLoading: userProjectsLoading, data: { data: userProjects = [] } = {} } = useQuery(
    ["UserProjects", userId],
    ({ queryKey }) => {
      const [_, userId] = queryKey;
      return req()(`/semcompletion/projects?user=${userId}`);
    },
    { refetchOnWindowFocus: false }
  );

  const onChangeStatus = (addedProjects) => {
    const addedProjectsObj = keyBy(addedProjects, "id");

    let toBeAdded = [];
    const newProjectsList = projects.map((d) => {
      if (addedProjectsObj[d.id]) return { ...d, active: addedProjectsObj[d.id].active };
      return d;
    });

    const alreadyExist = projects.find((d) => addedProjectsObj[d.id]);
    if (!alreadyExist) toBeAdded.push({ ...addedProjects[0] });

    setProjects([...newProjectsList, ...toBeAdded]);
  };

  // set assigned projects to projects state
  useEffect(() => {
    setProjects(userProjects.map((d) => ({ ...d, active: d.active ? 1 : 0 })));
  }, [userId, JSON.stringify(userProjects)]);

  // get assigned projects to user and adjust projects list
  useEffect(() => {
    if (projectsData.length > 0 || projects.length > 0) {
      const assignedProjectsObj = keyBy(projects, "id");
      const newProjects = projectsData.map((d) => ({
        ...d,
        active: assignedProjectsObj[d.id] ? (assignedProjectsObj[d.id].active ? 1 : 0) : 0,
      }));
      setProjectsList(newProjects);
    }
  }, [userId, JSON.stringify(projectsData), JSON.stringify(projects)]);

  useEffect(() => {
    // always call onUpdate if there's any changes in the projects
    if (onUpdate) onUpdate(projects.map((d) => ({ active: d.active, id: d.id })));
  }, [projects]);

  const activeProjects = useMemo(() => projects.filter((d) => d.active), [JSON.stringify(projects)]);

  return (
    <div className={container()}>
      {!userProjectsLoading && activeProjects.length > 0 && (
        <div className="assignedProjects">
          <FormItem label="Assigned Projects">
            {activeProjects.map((d) => {
              return <ProjectsList key={d.id} loading={false} onChangeStatus={onChangeStatus} project={d} />;
            })}
          </FormItem>
        </div>
      )}

      <div className="customerDropdown">
        <FormItem label="Customer" required={true}>
          <DropDown
            name="customer"
            onChange={(e) => setCustomer(e.target.value)}
            options={projectsList ? customersData.map((d) => ({ label: d.name, value: d.id })) : []}
            placeholder="Click to choose"
            value={customer}
          />
        </FormItem>
      </div>

      {!customer && <Result status="warning" title="Select a customer to display projects" />}
      {customer && projectsLoading && <InlineSpinner />}
      {customer && !projectsLoading && projectsList.length === 0 && <Result status="warning" title="No projects found" />}

      {customer &&
        !projectsLoading &&
        projectsList.length > 0 &&
        projectsList.map((d) => {
          return <ProjectsList key={d.id} loading={false} onChangeStatus={onChangeStatus} project={d} />;
        })}
    </div>
  );
};

const container = () => css`
  .assignedProjects,
  .customerDropdown {
    margin-bottom: 1rem;
  }
`;

export default MenuAccess;
