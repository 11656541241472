import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQueryClient } from "react-query";
import { css } from "emotion";
import { Col, notification, Row, Typography } from "antd";
import {
  ArrowBackIcon,
  CheckCircleIcon,
  CloseCircleIcon,
  DeleteIcon,
  EditIcon,
  InformationOutlineIcon,
  PlusIcon,
  RecycleIcon,
} from "mdi-react";
import { isEqual, keyBy } from "lodash";

import Modal from "./Modal";
import useOverviewData from "./hooks/useOverviewData";

import { showModalPage } from "../../../../actions/uiActions";

import Button from "../../../ui/Button";
import InlineSpinner from "../../../ui/InlineSpinner";
import Page from "../../../ui/Page";
import ScrollView from "../../../ui/ScrollView";
import StatusBox from "../../../ui/StatusBox";
import TopBar from "../../../ui/TopBar";
import Toggle from "../../../ui/Toggle";
import AccessCheck from "../../../ui/AccessCheck";

import getErrorMessage from "../../../../utilities/get-error-message";
import pageNavigator from "../../../../utilities/page-navigator";
import req from "../../../../utilities/request-utility";

import usePageAndBackButtonURL from "../../../../hooks/usePageAndBackButtonURL";

import colors from "../../../../style/colors";

const ManageCablesOverview = (props) => {
  const [data, setData] = useState([]);
  const [showDeletedRecords, setShowDeletedRecords] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isDataUpdated, setIsDataUpdated] = useState(false);

  const { selectedProject } = useSelector(
    ({ semcompletion }) => ({ selectedProject: semcompletion.selectedProject }),
    isEqual
  );

  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const { backButtonURL } = usePageAndBackButtonURL(props);

  const { cablesData, metaData } = useOverviewData({ query: { isDeleted: showDeletedRecords } });

  const { data: cablesPageData, error: cablesPageError, loading: cablesPageFetching } = cablesData;

  const { data: cablesMetaData } = metaData;

  const customerSystemsObj = useMemo(() => keyBy(cablesMetaData?.systems, "id"), [cablesMetaData?.systems]);
  const projectAreasObj = useMemo(() => keyBy(cablesMetaData?.projectAreas, "id"), [cablesMetaData?.projectAreas]);
  const jobpackageObj = useMemo(() => keyBy(cablesMetaData?.jobPackages, "id"), [cablesMetaData?.jobPackages]);
  const trayTypesObj = useMemo(() => keyBy(cablesMetaData?.trayTypes, "id"), [cablesMetaData?.trayTypes]);
  const projectEquipmentsObj = useMemo(
    () => keyBy(cablesMetaData?.projectEquipments, "id"),
    [cablesMetaData?.projectEquipments]
  );
  const maintenancePlantsObj = useMemo(
    () => keyBy(cablesMetaData?.maintenancePlants, "id"),
    [cablesMetaData?.maintenancePlants]
  );
  const projectManualCableTypesObj = useMemo(
    () => keyBy(cablesMetaData?.projectManualCableTypes, "id"),
    [cablesMetaData?.projectManualCableTypes]
  );

  const handleDelete = useCallback(
    (record, isDeleted) => {
      const handleOk = () => {
        queryClient.refetchQueries(["cablesPage", { isDeleted: showDeletedRecords }]);
        notification.success({ duration: 7, message: "SUCCESS", description: "You have successfully updated a cable" });
        setDeleteLoading(false);
      };

      const handleError = (err) => {
        const error = getErrorMessage(err);
        notification.error({ duration: 7, message: "FAILED", description: error });
        setDeleteLoading(false);
      };

      setDeleteLoading(true);

      const newData = {
        system: record?.systemId,
        projectArea: record?.areaId,
        cableNo: record?.cableNo,
        partNo: record?.partNo,
        jobpackagePull: record?.jobPackagePullId,
        cable: record?.cableId,
        trayType: record?.trayTypeId,
        length: record?.length,
        actualLength: record?.actualLength,
        pulledFrom: record?.pulledFrom,
        pulledTo: record?.pulledTo,
        isDeleted,
        isPulled: record?.isPulled,
        pullOffshore: record?.pullOffshore,
        jobpackageTerm: record?.jobPackageTerminationId,
        jobpackageTerm2: record?.jobPackageTerminationId2,
        fromTagno: record?.fromTagId,
        toTagno: record?.toTagId,
        mountedFrom: record?.mountedFrom,
        mountedTo: record?.mountedTo,
        termFromOffshore: record?.termFromOffshore,
        termToOffshore: record?.termToOffshore,
        remark: record?.remark,
        mccrOk: record?.mccrOk,
        mcOffshore: record?.mcOffshore,
        jobpackageMCCR: record?.jobPackageMccrId,
        mcPackage: record?.mcPackage,
        maintenancePlant: record?.maintenancePlantId,
        mctNo: record?.mctNo,
        cableTrayNo: record?.cableTrayNo,
        redundantCableTag: record?.redundantCableTag,
      };

      req().put(`semcompletion/cables/${record.id}`, newData).then(handleOk).catch(handleError);
    },
    [showDeletedRecords, deleteLoading]
  );

  const updateExpanded = useCallback(
    (id) => {
      const newData = data.map((d) => {
        if (d.id === id) d.expanded = !d.expanded;
        return d;
      });

      setData(newData);
    },
    [data]
  );

  const getIsDataUpdated = useCallback((isUpdated) => setIsDataUpdated(isUpdated), []);

  useEffect(() => {
    queryClient.refetchQueries(["cablesPage", { isDeleted: showDeletedRecords }]);
  }, [showDeletedRecords]);

  useEffect(() => {
    (() => {
      if (cablesPageData.length === 0) return setData([]);

      if (cablesPageData.length > 0) {
        const newData = cablesPageData.map((d) => {
          d.expanded = false;
          return d;
        });

        setData(newData);
      }
    })();
  }, [cablesPageData.length]);

  useEffect(() => {
    if (isDataUpdated) {
      setTimeout(() => {
        setData([...cablesPageData]);
        setIsDataUpdated(false);
      }, 1500);
    }
  }, [JSON.stringify(cablesPageData), isDataUpdated]);

  return (
    <Page className={container()}>
      <TopBar
        title={`Manage Cables (${selectedProject.number} - ${selectedProject.description})`}
        actionLeft={<ArrowBackIcon onClick={() => pageNavigator(backButtonURL, "backward")} />}
      />
      <AccessCheck pageId={props.match.params.pageId}>
        <div className="header">
          <div className="row">
            <Button
              onClick={() =>
                dispatch(
                  showModalPage({
                    content: <Modal editMode={false} initialForm={null} showDeletedRecords={showDeletedRecords} />,
                    title: `Add Cables (${selectedProject.number} - ${selectedProject.description})`,
                  })
                )
              }
              style={{ marginBottom: 10, width: "40%" }}
            >
              Add Cable
            </Button>
            <div style={{ alignItems: "center", display: "flex" }}>
              <Toggle
                on={showDeletedRecords}
                onClick={() => setShowDeletedRecords(!showDeletedRecords)}
                style={{ marginRight: 10 }}
              />
              <span>Show deleted records</span>
            </div>
          </div>
        </div>
        {showDeletedRecords ? (
          <div className="remark">
            <InformationOutlineIcon />
            <p className="line-break">You are viewing deleted records.</p>
          </div>
        ) : (
          ""
        )}
        <ScrollView>
          {cablesPageFetching && !cablesPageError && <InlineSpinner />}
          {!cablesPageFetching && cablesPageError && <StatusBox />}
          {!cablesPageFetching && !cablesPageError && (
            <div style={{ padding: "1rem 0px" }}>
              {data.map((d, i) => {
                return (
                  <div
                    className="list-item"
                    key={d.id}
                    style={{
                      margin: d.expanded
                        ? i + 1 === data.length
                          ? `1rem 0 5rem`
                          : `1rem 0`
                        : i + 1 === data.length
                        ? `0 0 50px 0`
                        : `0 0 -1px 0`,
                    }}
                  >
                    <div className="list-item header" style={{ borderBottomWidth: d.expanded ? "0px" : "1px" }}>
                      <div className="title-container">
                        <h2>{d.cableNo}</h2>
                      </div>
                      <div>
                        {!showDeletedRecords ? (
                          <>
                            <EditIcon
                              onClick={() => {
                                dispatch(
                                  showModalPage({
                                    content: (
                                      <Modal
                                        editMode={true}
                                        getIsDataUpdated={getIsDataUpdated}
                                        initialForm={d}
                                        showDeletedRecords={showDeletedRecords}
                                      />
                                    ),
                                    title: `Edit Cable (${selectedProject.number} - ${selectedProject.description})`,
                                  })
                                );
                              }}
                            />
                            {deleteLoading ? (
                              <InlineSpinner size={18} />
                            ) : (
                              <DeleteIcon onClick={() => handleDelete(d, true)} />
                            )}
                          </>
                        ) : (
                          <>
                            {deleteLoading ? (
                              <InlineSpinner size={18} />
                            ) : (
                              <RecycleIcon onClick={() => handleDelete(d, false)} />
                            )}
                          </>
                        )}
                        <PlusIcon
                          onClick={(e) => updateExpanded(d.id)}
                          className={d.expanded ? "rotate" : "rotate-neutral"}
                        />
                      </div>
                    </div>
                    <div className={`task-expanded ${d.expanded ? "show" : "hide"}`}>
                      <Row className="row">
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Cable No.: </b>
                            </Typography.Text>
                            <Typography.Text>{d.cableNo}</Typography.Text>
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Part No.: </b>
                            </Typography.Text>
                            <Typography.Text>{d.partNo}</Typography.Text>
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Remark: </b>
                            </Typography.Text>
                            <Typography.Text>{d.remark}</Typography.Text>
                          </div>
                        </Col>
                      </Row>
                      <Row className="row">
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Length: </b>
                            </Typography.Text>
                            <Typography.Text>{d.length}</Typography.Text>
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Actual Length: </b>
                            </Typography.Text>
                            <Typography.Text>{d.actualLength}</Typography.Text>
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>MC Package: </b>
                            </Typography.Text>
                            <Typography.Text>{d.mcPackage}</Typography.Text>
                          </div>
                        </Col>
                      </Row>
                      <Row className="row">
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>MCT No.: </b>
                            </Typography.Text>
                            <Typography.Text>{d.mctNo}</Typography.Text>
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Cable Tray No.: </b>
                            </Typography.Text>
                            <Typography.Text>{d.cableTrayNo}</Typography.Text>
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Redundant Cable Tag: </b>
                            </Typography.Text>
                            <Typography.Text>{d.redundantCableTag}</Typography.Text>
                          </div>
                        </Col>
                      </Row>
                      <Row className="row">
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Customer System: </b>
                            </Typography.Text>
                            {customerSystemsObj[d.systemId] && (
                              <Typography.Text ellipsis>{`${customerSystemsObj[d.systemId].systemNumber} - ${
                                customerSystemsObj[d.systemId].name
                              }`}</Typography.Text>
                            )}
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Project Area: </b>
                            </Typography.Text>
                            {projectAreasObj[d.areaId] && (
                              <Typography.Text ellipsis>{`${projectAreasObj[d.areaId].code} - ${
                                projectAreasObj[d.areaId].name
                              }`}</Typography.Text>
                            )}
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Cable: </b>
                            </Typography.Text>
                            {projectManualCableTypesObj[d.cableId] && (
                              <Typography.Text ellipsis>{`${projectManualCableTypesObj[d.cableId].code} - ${
                                projectManualCableTypesObj[d.cableId].type
                              }`}</Typography.Text>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className="row">
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Jobpackage Term: </b>
                            </Typography.Text>
                            {jobpackageObj[d.jobPackageTerminationId] && (
                              <Typography.Text ellipsis>{jobpackageObj[d.jobPackageTerminationId].title}</Typography.Text>
                            )}
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Jobpackage Term 2: </b>
                            </Typography.Text>
                            {jobpackageObj[d.jobPackageTerminationId2] && (
                              <Typography.Text ellipsis>{jobpackageObj[d.jobPackageTerminationId2].title}</Typography.Text>
                            )}
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Jobpackage MCCR: </b>
                            </Typography.Text>
                            {jobpackageObj[d.jobPackageMccrId] && (
                              <Typography.Text ellipsis>{jobpackageObj[d.jobPackageMccrId].title}</Typography.Text>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className="row">
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Jobpackage Pull: </b>
                            </Typography.Text>
                            {jobpackageObj[d.jobPackagePullId] && (
                              <Typography.Text ellipsis>{jobpackageObj[d.jobPackagePullId].title}</Typography.Text>
                            )}
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Tray Type: </b>
                            </Typography.Text>
                            {trayTypesObj[d.trayTypeId] && (
                              <Typography.Text ellipsis>{trayTypesObj[d.trayTypeId].code}</Typography.Text>
                            )}
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Maintenance Plant: </b>
                            </Typography.Text>
                            {maintenancePlantsObj[d.maintenancePlantId] && (
                              <Typography.Text ellipsis>{`${maintenancePlantsObj[d.maintenancePlantId].code} - ${
                                maintenancePlantsObj[d.maintenancePlantId].name
                              }`}</Typography.Text>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className="row">
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>From Tag No.: </b>
                            </Typography.Text>
                            {projectEquipmentsObj[d.fromTagId] && (
                              <Typography.Text ellipsis>{projectEquipmentsObj[d.fromTagId].tagNo}</Typography.Text>
                            )}
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>To Tag No.: </b>
                            </Typography.Text>
                            {projectEquipmentsObj[d.toTagId] && (
                              <Typography.Text ellipsis>{projectEquipmentsObj[d.toTagId].tagNo}</Typography.Text>
                            )}
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>MC Offshore: </b>
                            </Typography.Text>
                            <Typography.Text>{d.mcOffshore}</Typography.Text>
                          </div>
                        </Col>
                      </Row>
                      <Row className="row">
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Pulled From: </b>
                            </Typography.Text>
                            <Typography.Text>
                              {d.pulledFrom ? (
                                <CheckCircleIcon color={colors.green} />
                              ) : (
                                <CloseCircleIcon color={colors.red} />
                              )}
                            </Typography.Text>
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Pulled To: </b>
                            </Typography.Text>
                            <Typography.Text>
                              {d.pulledTo ? (
                                <CheckCircleIcon color={colors.green} />
                              ) : (
                                <CloseCircleIcon color={colors.red} />
                              )}
                            </Typography.Text>
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Is Pulled: </b>
                            </Typography.Text>
                            <Typography.Text>
                              {d.isPulled ? (
                                <CheckCircleIcon color={colors.green} />
                              ) : (
                                <CloseCircleIcon color={colors.red} />
                              )}
                            </Typography.Text>
                          </div>
                        </Col>
                      </Row>
                      <Row className="row">
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Mounted From: </b>
                            </Typography.Text>
                            <Typography.Text>
                              {d.mountedFrom ? (
                                <CheckCircleIcon color={colors.green} />
                              ) : (
                                <CloseCircleIcon color={colors.red} />
                              )}
                            </Typography.Text>
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Mounted To: </b>
                            </Typography.Text>
                            <Typography.Text>
                              {d.mountedTo ? (
                                <CheckCircleIcon color={colors.green} />
                              ) : (
                                <CloseCircleIcon color={colors.red} />
                              )}
                            </Typography.Text>
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Pull Offshore: </b>
                            </Typography.Text>
                            <Typography.Text>
                              {d.pullOffshore ? (
                                <CheckCircleIcon color={colors.green} />
                              ) : (
                                <CloseCircleIcon color={colors.red} />
                              )}
                            </Typography.Text>
                          </div>
                        </Col>
                      </Row>
                      <Row className="row">
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Term From Offshore: </b>
                            </Typography.Text>
                            <Typography.Text>
                              {d.termFromOffshore ? (
                                <CheckCircleIcon color={colors.green} />
                              ) : (
                                <CloseCircleIcon color={colors.red} />
                              )}
                            </Typography.Text>
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Term To Offshore: </b>
                            </Typography.Text>
                            <Typography.Text>
                              {d.termToOffshore ? (
                                <CheckCircleIcon color={colors.green} />
                              ) : (
                                <CloseCircleIcon color={colors.red} />
                              )}
                            </Typography.Text>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </ScrollView>
      </AccessCheck>
    </Page>
  );
};

const container = () => css`
  .header {
    background-color: ${colors.white};
    padding: 1rem;

    .punch-count-container {
      flex-basis: 50%;
      padding: 0 0.5rem;
      display: flex;
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.85rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      .dropdown {
        flex-grow: 1;
        margin: 0 0.35rem;
        flex-basis: 25%;

        &:first-of-type {
          margin-left: 0;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  .row {
    padding-top: 0.5rem;
  }

  .col {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    padding: 0 0.75rem 0.25rem 0.75rem;
    width: 33%;

    .container {
      display: flex;
      justify-content: flex-start;
      width: 100%;

      .title {
        margin-right: 3px;
      }
    }
  }

  .title-container {
    padding-left: 9px;
  }

  .list-item {
    background-color: ${colors.white};
    border-top: 1px #d1d1d1 solid;
    border-bottom: 1px #d1d1d1 solid;
    transition: margin 240ms ease;

    &.header {
      display: flex;
      padding: 0.75rem;
      background-color: ${colors.white};
      overflow: hidden;
      transition: background-color 160ms ease;
      transition-delay: 240ms;

      &:active {
        background-color: ${colors.ultraLightGrey};
        transition: background-color 80ms ease;
      }

      svg {
        height: 1.75rem;
        width: 1.75rem;
        margin-right: 0.5rem;
      }

      div:first-child {
        display: flex;
      }

      div:last-child {
        color: ${colors.darkGrey};
        margin-left: auto;
        display: flex;
        align-items: center;

        svg:last-child {
          margin-right: 0;
        }
      }
    }

    &.small-element-container {
      display: flex;
      padding-top: 3px;

      /* Makes text crop if overflowing (with ellipsis: ...) */
      .crop-text {
        border-width: 0px;
        text-overflow: ellipsis;
      }

      .column {
        border-width: 0px;
        display: flex;
        flex-direction: column;

        div {
          align-items: center;
          display: flex;
          padding: 0 0.75rem 0.25rem 0.75rem;

          .element-title {
            font-size: 1rem;
            font-weight: 700;
          }
        }
      }
    }
  }

  .hide {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 240ms ease, opacity 240ms ease, overflow 240ms ease;
  }

  .show {
    max-height: 1000px;
    opacity: 1;
    transition: max-height 240ms ease, opacity 240ms ease;
  }

  .rotate {
    transform: rotate(-45deg);
    transition: transform 240ms ease;
  }
  .rotate-neutral {
    transform: rotate(0deg);
    transition: transform 240ms ease;
  }

  .remark {
    display: flex;
    padding: 0.5rem;
    background-color: ${colors.yellowLight};
    margin: 0.75rem;
    border: 2px solid ${colors.yellow};
    border-radius: 5px;

    svg {
      margin-right: 0.5rem;
    }
  }

  button svg {
    display: inline-block;
    margin-bottom: -0.35rem;
  }

  h2 {
    display: flex;
    align-items: center;
    color: ${colors.black};
    font-size: 1.125rem;
    font-weight: 700;
  }
`;

export default memo(ManageCablesOverview);
