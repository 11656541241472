import React, { useState, useEffect, useRef } from "react";
import { EmailOutlineIcon } from "mdi-react";
import PasswordDots from "./PasswordDots";
import Numpad from "../ui/Numpad";
import keyPressProxy from "./utilities/keyPressProxy";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "../../actions/authActions";

const PhoneLogin = props => {
  const dispatch = useDispatch();
  const signingIn = useSelector(s => s.auth.signingIn);
  const [state, setState] = useState({
    phone: "",
    password: "",
    phoneEntered: false,
    passwordEntered: false,
    showNumpad: true
  });

  const refProps = useRef(props);
  const refState = useRef(state);
  useEffect(() => {
    refState.current = state;
    refProps.current = props;
  }, [state, props]);

  useEffect(() => {
    // Bind event listeners for keys
    document.addEventListener("keyup", keyProxy);
    document.addEventListener("keydown", handleTabKey);

    return () => {
      // Unbind event listeners for keys
      document.removeEventListener("keyup", keyProxy);
      document.removeEventListener("keydown", handleTabKey);
    };
    // eslint-disable-next-line
  }, []);

  let { showNumpad, password, phoneEntered } = state;

  function handleTabKey(e) {
    if (refProps.current.enableInput === false) return;

    // Moves focus between phone and password with tab and shift-tab
    let { phoneEntered } = refState.current;
    let emailInputHasFocus = document.querySelector("input[type='email']") === document.activeElement;

    // If key is tab and email has focus move focus to passsword
    if (e.key === "Tab" && emailInputHasFocus) {
      e.preventDefault();
      document.querySelector("input[type='email']").blur();
      setState({ ...refState.current, phoneEntered: true });
    }

    // If keys is tab+shift and password has focus move focus to email input
    if (e.shiftKey && e.key === "Tab" && phoneEntered) {
      e.preventDefault();
      document.querySelector("input[type='email']").focus();
      setState({ ...refState.current, phoneEntered: false });
    }
  }

  function keyProxy(e) {
    handleKeyPress(keyPressProxy(e));
  }

  function handleKeyPress(key) {
    if (refProps.current.enableInput === false) return;

    // let state = refState.current;
    let freshState = refState.current;
    let { phoneEntered, passwordEntered, password, email } = refState.current;

    // Handle delete button
    if (key === "delete") {
      if (phoneEntered && !passwordEntered && password.length > 0) {
        setState({ ...freshState, password: password.slice(0, -1) });
      } else if (phoneEntered && passwordEntered) {
        setState({ ...freshState, passwordEntered: false });
      } else if (phoneEntered && !passwordEntered) {
        setState({ ...freshState, phoneEntered: false });
      }
      return;
    }

    let emailInputHasFocus = document.querySelector("input[type='email']") === document.activeElement;

    if (!emailInputHasFocus && phoneEntered && !passwordEntered && password.length < 3) {
      setState({ ...freshState, password: password + key });
    } else if (!emailInputHasFocus && !passwordEntered && password.length === 3) {
      setState({ ...freshState, password: password + key, passwordEntered: true });
      tryLogin({
        email,
        password: password + key
      });
    }
  }

  function tryLogin(credentials) {
    dispatch(signIn(credentials));

    setState({
      ...refState.current,
      password: "",
      passwordEntered: false
    });
  }

  return (
    <>
      <div className="input email">
        <EmailOutlineIcon />
        <input
          type="email"
          placeholder="Enter your email here"
          value={state.email}
          onChange={e => setState({ ...state, email: e.target.value })}
          onFocus={() => setState({ ...state, showNumpad: false, phoneEntered: false })}
          onBlur={() => setState({ ...state, showNumpad: true })}
        />
      </div>

      <PasswordDots
        hide={signingIn}
        active={phoneEntered}
        password={password}
        onClick={() => {
          setState({
            ...state,
            phoneEntered: true
          });
        }}
      />

      <Numpad active={showNumpad} onKeyPress={key => handleKeyPress(key)} />
    </>
  );
};

export default PhoneLogin;
