import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "emotion";
import { useQuery, useMutation } from "react-query";
import { size } from "lodash";

import Page from "../ui/Page";
import ScrollView from "../ui/ScrollView";
import TopBar from "../ui/TopBar";
import ActionWrapper from "../ui/ActionWrapper";
import { ArrowLeftIcon } from "mdi-react";
import DropDown from "../ui/DropDown";

import usePageAndBackButtonURL from "../../hooks/usePageAndBackButtonURL";
import pageNavigator from "../../utilities/page-navigator";
import req from "../../utilities/request-utility";
import colors from "../../style/colors";
import { SC_SET_SELECTED_PROJECT, SC_SET_SELECTED_PROJECT_AREA } from "../../actions/actionTypes";

const SemcompltionProjectChooser = (props) => {
  const { page, backButtonURL } = usePageAndBackButtonURL(props);
  const { selectedProject, selectedProjectArea } = useSelector((s) => s.semcompletion);
  const primaryColor = useSelector((s) => s.appConfig.primaryColor);
  const dispatch = useDispatch();

  const { data: { data: projects = [] } = {}, isLoading: isProjectLoading } = useQuery("Projects", () =>
    req()("semcompletion/projects/")
  );

  const { data: { data: savedSelectedProject = null } = {}, refetch: savedSelectedProjectRefetch } = useQuery(
    "SelectedProject",
    () => req()("semcompletion/projects/selected")
  );

  const {
    data: { data: projectAreas = [] } = {},
    isLoading: isProjectAreaLoading,
    isRefetching: isProjectAreaRefetching,
    refetch: projectAreasRefetch,
  } = useQuery(
    ["ProjectAreas", savedSelectedProject],
    () => req()(`semcompletion/projectAreas?projectId=${savedSelectedProject ? savedSelectedProject.id : ""}`),
    {
      enabled: !!savedSelectedProject,
    }
  );

  const { data: { data: savedSelectedProjectArea = null } = {}, refetch: savedSelectedProjectAreaRefetch } = useQuery(
    "SelectedProjectArea",
    () => req()("semcompletion/projects/selected/project-area")
  );

  const { mutateAsync: updateSelectedProject } = useMutation("updateSelectedProject", (projectId) =>
    req().put(`semcompletion/projects/selected/${projectId}`)
  );

  const { mutateAsync: updateSelectedProjectArea } = useMutation("updateSelectedProjectArea", (projectAreaId) =>
    req().put(`semcompletion/projects/selected/project-area/${projectAreaId}`)
  );

  const { mutateAsync: deleteSelectedProjectArea } = useMutation("deleteSelectedProjectArea", () =>
    req().delete("semcompletion/projects/selected/project-area")
  );

  const changeDefaultProject = async (projectId) => {
    let [newProject] = projects.filter((p) => p.id === projectId);
    dispatch({
      type: SC_SET_SELECTED_PROJECT,
      payload: newProject,
    });

    await updateSelectedProject(newProject.id);
    await deleteSelectedProjectArea();

    savedSelectedProjectRefetch();
    projectAreasRefetch();
  };

  const changeDefaultProjectArea = async (projectAreaId) => {
    let [newProjectArea] = projectAreas.filter((p) => p.id === projectAreaId);

    dispatch({
      type: SC_SET_SELECTED_PROJECT_AREA,
      payload: {
        id: newProjectArea.id,
        code: newProjectArea.projectAreaCode,
        name: newProjectArea.projectAreaName,
        projectId: newProjectArea.projectId,
      },
    });

    await updateSelectedProjectArea(newProjectArea.id);
  };

  const clearDefaultProjectArea = async () => {
    dispatch({
      type: SC_SET_SELECTED_PROJECT_AREA,
      payload: { id: "", code: "", name: "", projectId: "" },
    });

    await deleteSelectedProjectArea();
    savedSelectedProjectAreaRefetch();
  };

  useEffect(() => {
    if (projects.length > 0) {
      if (size(savedSelectedProject) === 0)
        dispatch({ type: SC_SET_SELECTED_PROJECT, payload: { id: "", number: "", description: "", projectType: "" } });
      else dispatch({ type: SC_SET_SELECTED_PROJECT, payload: savedSelectedProject });
    } else dispatch({ type: SC_SET_SELECTED_PROJECT, payload: { id: "", number: "", description: "", projectType: "" } });
  }, [projects.length, savedSelectedProject]);

  useEffect(() => {
    if (projectAreas.length > 0) {
      if (size(savedSelectedProjectArea) === 0)
        dispatch({ type: SC_SET_SELECTED_PROJECT_AREA, payload: { id: "", code: "", name: "", projectId: "" } });
      else dispatch({ type: SC_SET_SELECTED_PROJECT_AREA, payload: savedSelectedProjectArea });
    } else dispatch({ type: SC_SET_SELECTED_PROJECT_AREA, payload: { id: "", code: "", name: "", projectId: "" } });
  }, [projectAreas.length, savedSelectedProjectArea]);

  useEffect(() => {
    savedSelectedProjectAreaRefetch();
  }, [selectedProject]);

  return (
    <Page>
      <TopBar
        actionLeft={
          <ActionWrapper onClick={() => pageNavigator(backButtonURL, "backward")}>
            <ArrowLeftIcon />
          </ActionWrapper>
        }
        title={page.title}
      />

      <ScrollView className={componentStyles(primaryColor)}>
        <div className="wrapper">
          <div className="current-project-status">
            <p>
              <strong>Current project:</strong>
              {selectedProject.id && selectedProject.number && selectedProject.description ? (
                <>
                  {selectedProject.number} - {selectedProject.description}
                </>
              ) : (
                <span>No project selected</span>
              )}
            </p>
          </div>

          <label htmlFor="projectChooser" style={{ marginBottom: "0.5rem", display: "block" }}>
            Choose a project:
          </label>
          <DropDown
            allowClear={false}
            className={selectedProject.id ? "withValue" : ""}
            name="projectChooser"
            loading={isProjectLoading}
            onChange={(e) => changeDefaultProject(e.target.value)}
            options={projects.map((p) => ({ label: `${p.number} - ${p.description}`, value: p.id }))}
            style={{ marginBottom: "2rem" }}
            value={selectedProject.id}
          />
          <label htmlFor="projectAreaChooser" style={{ marginBottom: "0.5rem", display: "block" }}>
            Choose a project area:
          </label>
          <DropDown
            className={selectedProjectArea.id ? "withValue" : ""}
            name="projectAreaChooser"
            disabled={isProjectAreaLoading || isProjectAreaRefetching}
            loading={isProjectAreaLoading || isProjectAreaRefetching}
            onChange={(e) => changeDefaultProjectArea(e.target.value)}
            onClear={clearDefaultProjectArea}
            options={projectAreas.map((p) => ({ label: `${p.projectAreaCode} - ${p.projectAreaName}`, value: p.id }))}
            style={{ marginBottom: "2rem" }}
            value={selectedProjectArea ? selectedProjectArea.id : ""}
          />
        </div>
      </ScrollView>
    </Page>
  );
};

const componentStyles = (primaryColor) => css`
  background-color: ${colors.white};
  padding: 2rem 1rem;

  .wrapper {
    max-width: 800px;
    margin: 0 auto;
  }

  .current-project-status {
    border-radius: 3px;
    margin-bottom: 1rem;
    display: flex;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px ${colors.lightGrey} solid;

    svg {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.5rem;
      color: ${colors.darkGrey};
    }
  }

  .seperator {
    height: 1px;
    background-color: ${colors.lightGrey};
    margin-bottom: 1rem;
  }

  label {
    font-weight: 700;
  }

  p {
    strong {
      margin-right: 0.5rem;
    }

    span {
      color: ${colors.darkGrey};
      font-size: 0.9rem;
    }
    svg {
      width: 1rem;
      height: 1rem;
      color: ${colors.darkGrey};
      margin: 0 0.15rem -3px 0rem;
    }
  }
`;

export default SemcompltionProjectChooser;
