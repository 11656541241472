export default function getPageFromId(pages, id) {
  if (!pages || !id) {
    console.log("[WARNING] get-page-from-id.js called without pages or id");
    return;
  }

  for (let i = 0; i < pages.length; i++) {
    if (Number(pages[i].id) === Number(id)) return pages[i];

    if (pages[i].pages) {
      let found = getPageFromId(pages[i].pages, id);
      if (found) return found;
    }
  }
}
