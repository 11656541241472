import React, { memo } from "react";
import ReactPlayer from "react-player";

const VideoPlayer = ({ url }) => {
  return (
    <div style={{ height: "95%", width: "100%" }}>
      <ReactPlayer
        config={{ attributes: { autoPlay: true, controlsList: "nodownload" } }}
        controls={true}
        height="100%"
        url={url}
        width="100%"
      />
    </div>
  );
};

export default memo(VideoPlayer);
