import React from "react";
import { Collapse, Switch } from "antd";

const ProjectsList = ({ loading = false, onChangeStatus = () => {}, project = {} }) => {
  const { active = false, id, description, number } = project;

  return (
    <Collapse collapsible={"icon"} expandIconPosition={"end"}>
      <Collapse.Panel
        showArrow={false}
        header={`${number} - ${description}`}
        key={id}
        extra={
          <Switch
            key={id}
            checkedChildren="On"
            unCheckedChildren="Off"
            checked={active}
            onChange={(e) => {
              onChangeStatus([{ ...project, active: e ? 1 : 0 }]);
            }}
            loading={loading}
            style={{ backgroundColor: active ? "#103447" : "#abaeac" }}
          />
        }
      ></Collapse.Panel>
    </Collapse>
  );
};

export default ProjectsList;
