import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { css } from "emotion";
import AnimateHeight from "react-animate-height";
import {
  AccountAlertIcon,
  AccountCheckIcon,
  ArrowExpandIcon,
  CheckIcon,
  FilePdfIcon,
  PencilIcon,
  PlusIcon,
} from "mdi-react";

import colors from "../../../style/colors";
import { hideModalPage, showLightBox, showModalPage } from "../../../actions/uiActions";

import ButtonRounded from "../../ui/ButtonRounded";
import ListItem from "../../ui/ListItem";
import PDFViewer from "../PDFViewer";

import getFileExtension from "../../../utilities/getFileExtension";

function PunchActivityLog({ activityLog = [], isAllExpanded = false }) {
  const isIpadMiniPortrait = useMediaQuery({ orientation: "portrait", maxWidth: 768 });
  const isIpadMiniLandscape = useMediaQuery({ orientation: "landscape", maxWidth: 1024 });
  const isIpadAirPortrait = useMediaQuery({ orientation: "portrait", maxWidth: 820 });
  const isIpadAirLandscape = useMediaQuery({ orientation: "landscape", maxWidth: 1180 });
  const isIpadProPortrait = useMediaQuery({ orientation: "portrait", maxWidth: 1024 });
  const isIpadProLandscape = useMediaQuery({ orientation: "landscape", maxWidth: 1366 });

  return (
    <div
      className={container({
        isIpadMiniPortrait,
        isIpadMiniLandscape,
        isIpadAirLandscape,
        isIpadAirPortrait,
        isIpadProPortrait,
        isIpadProLandscape,
      })}
    >
      {activityLog.map((logEntry) => (
        <LogEntry key={logEntry.id} isAllExpanded={isAllExpanded} logEntry={logEntry} />
      ))}
    </div>
  );
}

function LogEntry({ isAllExpanded, logEntry = {} }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();

  if (logEntry.changeLog) {
    logEntry.changeLog.data =
      typeof logEntry.changeLog.data === "string" ? JSON.parse(logEntry.changeLog.data) : logEntry.changeLog.data;

    logEntry.changeLog.updatedFields =
      typeof logEntry.changeLog.updatedFields === "string"
        ? JSON.parse(logEntry.changeLog.updatedFields)
        : logEntry.changeLog.updatedFields;
  }

  const punchImages = useMemo(
    () =>
      logEntry.punchStatusImages
        .filter((image) => getFileExtension(image.imageUrl).extension !== "pdf")
        .map((image) => ({ ...image, ...getFileExtension(image.imageUrl) })),
    [logEntry]
  );
  const punchPdfs = useMemo(
    () =>
      logEntry.punchStatusImages
        .filter((image) => getFileExtension(image.imageUrl).extension === "pdf")
        .map((image) => ({ ...image, ...getFileExtension(image.imageUrl) })),
    [logEntry]
  );

  useEffect(() => {
    if (logEntry.comment || logEntry.image || logEntry.changeLog) setIsExpanded(isAllExpanded);
  }, [isAllExpanded]);

  return (
    <div className={"log-entry " + logEntry.action.toLowerCase()}>
      <div className="header">
        <ActionEmblem action={logEntry.action} />

        {logEntry.date && (
          <p className="time">
            {logEntry.date.slice(0, 4)}-{logEntry.date.slice(4, 6)}-{logEntry.date.slice(6, 8)}
          </p>
        )}
        <p className="user">
          <b>{logEntry.user}</b>
        </p>
        {(logEntry.comment || logEntry.image || logEntry.changeLog) && (
          <ButtonRounded secondary={true} small={true} onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? "Hide" : "Show"} details
          </ButtonRounded>
        )}
      </div>
      <AnimateHeight height={isExpanded ? "auto" : 0} animateOpacity={true}>
        <div className="details-content">
          {logEntry.comment && (
            <div className="comment-container">
              <p className="comment">{logEntry.comment}</p>
            </div>
          )}
          {logEntry.changeLog && (
            <div className="details-grid">
              <div className="item item-title">Field Name</div>
              <div className="item item-title">Previous Value</div>
              <div className="item item-title">New Value</div>
              {logEntry.changeLog.data.map((d, i) => (
                <Fragment key={d.id}>
                  <div className="item">{logEntry.changeLog.updatedFields[i]}</div>
                  <div className="item">{d.prevValue}</div>
                  <div className="item">{d.value}</div>
                </Fragment>
              ))}
            </div>
          )}

          <br />
          {punchPdfs.length !== 0 && (
            <div className="row">
              <div className="col-100">
                <span className="label">PDFs</span>
                {punchPdfs.map((pdf) => {
                  return (
                    <ListItem
                      clickable={true}
                      iconRight={<FilePdfIcon />}
                      key={pdf.imageUrl}
                      onClick={(e) =>
                        dispatch(
                          showModalPage({
                            closeCallback: () => dispatch(hideModalPage()),
                            content: <PDFViewer file={pdf.imageUrl} />,
                            title: pdf.displayFileName,
                          })
                        )
                      }
                      title={pdf.displayFileName}
                    />
                  );
                })}
              </div>
            </div>
          )}

          <div className="image-container">
            {punchImages.length > 0 &&
              punchImages.map((d) => {
                return (
                  <div
                    className="image-wrapper"
                    key={d.imageUrl}
                    style={{
                      backgroundImage: `url("${d.imageUrl}")`,
                    }}
                  >
                    <button className="fullscreen-toggle">
                      <ArrowExpandIcon
                        onClick={() => dispatch(showLightBox({ images: [{ baseURL: "", image: d.imageUrl }] }))}
                      />
                    </button>
                  </div>
                );
              })}
          </div>
        </div>
      </AnimateHeight>
    </div>
  );
}

function ActionEmblem({ action }) {
  switch (action) {
    case "CREATE":
      return (
        <div className="status-emblem create">
          <PlusIcon /> Created
        </div>
      );
    case "CLEAR":
      return (
        <div className="status-emblem clear">
          <AccountCheckIcon /> Cleared
        </div>
      );
    case "OPEN":
      return (
        <div className="status-emblem open">
          <AccountAlertIcon /> Opened
        </div>
      );
    case "CLOSE":
      return (
        <div className="status-emblem close">
          <CheckIcon /> Closed
        </div>
      );
    case "EDIT":
      return (
        <div className="status-emblem edit">
          <PencilIcon /> Edited
        </div>
      );
    default:
      return null;
  }
}

const container = ({
  isIpadMiniPortrait,
  isIpadMiniLandscape,
  isIpadAirLandscape,
  isIpadAirPortrait,
  isIpadProPortrait,
  isIpadProLandscape,
}) => css`
  .log-entry {
    border: 1px ${colors.midGrey} solid;
    border-radius: 3px;
    padding: 0.65rem 0.5rem;
    margin: 0 0 0.35rem;

    .details-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto 1fr;
      margin-top: 15px;

      .item {
        border: 1px solid #000;
        padding: 5px;
      }

      .item-title {
        font-weight: bold;
      }
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .user {
        flex-grow: 1;
      }
      .time {
        margin-right: 1rem;
        color: ${colors.darkGrey};
      }
    }

    .details-content {
      max-width: 100%;

      .comment-container {
        overflow-wrap: break-word;
      }

      p.comment {
        margin-top: 0.5rem;
      }

      .image-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 1200px;
        margin: 0 auto 2rem auto;
        padding: 0 0.5rem;

        .image-wrapper {
          flex: 1 1 46%;
          min-width: 300px;
          padding-bottom: 40%;
          border: 1px ${colors.midGrey} solid;
          border-radius: 5px;
          background-size: cover;
          background-position: center;
          margin: 0.5rem;
          position: relative;

          button.fullscreen-toggle {
            position: absolute;
            top: 1rem;
            right: 1rem;
            background-color: ${colors.white};
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 1.25rem;
            border: 1px ${colors.lightGrey} solid;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0.9;

            &:hover {
              cursor: pointer;
              opacity: 1;
            }

            svg {
              color: ${colors.darkGrey};
              width: 1.5rem;
              height: 1.5rem;
            }
          }
        }
      }
    }

    &.create,
    &.edit {
      border-left: ${colors.darkGrey} 3px solid;
      .status-emblem {
        color: ${colors.darkGrey};
      }
    }
    &.open {
      border-left: ${colors.red} 3px solid;
      .status-emblem {
        color: ${colors.red};
      }
    }
    &.clear {
      border-left: ${colors.vividBlue} 3px solid;
      .status-emblem {
        color: ${colors.vividBlue};
      }
    }
    &.close {
      border-left: ${colors.green} 3px solid;
      .status-emblem {
        color: ${colors.green};
      }
    }
    .status-emblem {
      font-weight: 700;
      display: flex;
      align-items: center;
      margin-right: 0.75rem;
      width: 100px;

      svg {
        margin-right: 0.35rem;
        margin-left: 0.25rem;
      }
    }
  }
`;

export default PunchActivityLog;
