import React from "react";
import { css } from "emotion";

import colors from "../../style/colors";
import common from "../../style/common";
import PropTypes from "prop-types";

import InlineSpinner from "./InlineSpinner";

/** Small components to display the unread/unhandled elements for the specific user since their last interaction,
 * this could for example be: display a different number for notfications over 50 */
const Notification = (props) => (
  <span className={`${style(props.primaryColor, props.notifications)} ${props.hide ? "hide" : ""}`} style={props.style}>
    {props.isLoading ? (
      <InlineSpinner size={10} style={{ marginTop: "10px" }} />
    ) : props.notifications > 99 ? (
      "99+"
    ) : (
      props.notifications
    )}
  </span>
);
const style = (primaryColor, notifications) => css`
  background-color: ${notifications > 0 ? colors.red : colors.white};
  border-radius: 30px;
  height: 21px;
  text-align: center;
  color: ${colors.white};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  font-size: 0.9rem;
  font-weight: 700;
  font-family: ${common.fontStack};
  transform: scale(1);
  opacity: 1;
  transition: transform 500ms ease, opacity 500ms ease;

  &.hide {
    transform: scale(0);
    opacity: 0;
    transition: transform 500ms ease, opacity 500ms ease;
  }
`;

Notification.propTypes = {
  /** override styles in your current implementation */
  style: PropTypes.object,
  /** Numerical value for the number of elements not seen/activated by the user */
  notifications: PropTypes.number,
  /** The applications primary color */
  primaryColor: PropTypes.string,
};
export default Notification;
