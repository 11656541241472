const getErrorMessage = (err) => {
  if (err.response) {
    if (err.response.data.message) return err.response.data.message;
    return err.response.data;
  }
  if (err.message) return err.message;
  return "Something went wrong";
};

export default getErrorMessage;
