import React from "react";
import { AccountCircleIcon } from "mdi-react";
import colors from "../style/colors";

export default (userObject, size = 36, styles = {}, callback) => {
  if (userObject.profilePicture && userObject.profilePicture.baseURL && userObject.profilePicture.image) {
    let imageUrl = `${userObject.profilePicture.baseURL}w_${size},h_${size},c_lfill,g_face/${userObject.profilePicture.image}`;
    return (
      <div
        style={{
          width: `${size}px`,
          height: `${size}px`,
          borderRadius: "50%",
          backgroundImage: `url('${imageUrl}')`,
          backgroundSize: "cover",
          display: "inline-block",
          verticalAlign: "middle",
          marginBottom: "2px",
          border: `1px ${colors.lightGrey} solid`,
          flexShrink: 0,
          ...styles,
        }}
        onClick={() => {
          if (callback) callback();
        }}
      />
    );
  } else {
    return <AccountCircleIcon style={{ width: size + "px", height: size + "px", fill: colors.darkGrey, ...styles }} />;
  }
};
