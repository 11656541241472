import React, { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "react-query";
import { notification } from "antd";
import { css } from "emotion";
import * as yup from "yup";

import Button from "../../../ui/Button";
import DropDown from "../../../ui/DropDown";
import FormItem from "../../../ui/FormItem";
import InlineSpinner from "../../../ui/InlineSpinner";
import ScrollView from "../../../ui/ScrollView";
import TextInput from "../../../ui/TextInput";

import colors from "../../../../style/colors";

import getErrorMessage from "../../../../utilities/get-error-message";
import req from "../../../../utilities/request-utility";

import { hideModalPage } from "../../../../actions/uiActions";

import useOverviewData from "./hooks/useOverviewData";

const ManagePunchListModal = ({ editMode = false, initialForm = null }) => {
  const { metaData } = useOverviewData({ query: { isDeleted: false } });

  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const [formLoading, setFormloading] = useState(false);
  const [form, setForm] = useState({
    name: initialForm?.name || "",
    projectType: initialForm?.projectType || "",
    idPrefix: initialForm?.idPrefix || "",
  });

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    projectType: yup.string().required("Project Type is required"),
    idPrefix: yup.string().required("ID Prefix is required"),
  });

  const handleChange = useCallback(
    (e) => {
      if (e.target.type === "checkbox") return setForm({ ...form, [e.target.name]: e.target.checked });

      if (e.target.name === "idPrefix" && e.target.value.length > 5)
        return notification.error({
          duration: 7,
          message: "FAILED",
          description: "ID Prefix can only accept up to 5 characters",
        });

      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    },
    [form]
  );

  const isFormValid = useCallback(async () => {
    try {
      await validationSchema.validate(form);

      return { status: true };
    } catch (error) {
      return {
        status: false,
        message: error.message,
      };
    }
  }, [form]);

  const savePunchList = useCallback(async () => {
    const isFormValidData = await isFormValid();

    if (!isFormValidData.status)
      return notification.error({ duration: 7, message: "FAILED", description: isFormValidData.message });

    setFormloading(true);

    const handleOk = async ({ data }) => {
      if (!editMode)
        await req().post(`semcompletion/v2/punches/punch-list/actions`, { action: "Close Punch", punchListId: data.id });

      queryClient.invalidateQueries(["punchList", { isDeleted: false }]);

      dispatch(hideModalPage());

      setFormloading(false);

      notification.success({
        duration: 7,
        message: "SUCCESS",
        description: `You have successfully ${editMode ? "updated" : "added"} a punch list`,
      });
    };

    const handleError = (err) => {
      const error = getErrorMessage(err);

      notification.error({ duration: 7, message: "FAILED", description: error });

      setFormloading(false);
    };

    if (editMode) req().put(`semcompletion/v2/punches/punch-list/${initialForm.id}`, form).then(handleOk).catch(handleError);
    else req().post(`semcompletion/v2/punches/punch-list`, form).then(handleOk).catch(handleError);
  }, [form, initialForm, editMode]);

  return (
    <ScrollView className={container()}>
      <div className="row">
        <div className="col-100">
          <FormItem label="Project Type" required={true}>
            <DropDown
              name="projectType"
              onChange={handleChange}
              options={
                metaData.data.projectTypes && metaData.data.projectTypes.length !== 0
                  ? metaData.data.projectTypes.map((d) => ({ label: `${d.id} - ${d.description}`, value: d.id }))
                  : []
              }
              value={form.projectType}
            />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <FormItem label="Name" required={true}>
            <TextInput name="name" onChange={handleChange} value={form.name} />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <FormItem label="ID Prefix" required={true}>
            <TextInput name="idPrefix" onChange={handleChange} value={form.idPrefix} />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <Button disabled={formLoading} onClick={savePunchList}>
            {formLoading && <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />}{" "}
            {editMode ? "Update" : "Create"} Punch List
          </Button>
        </div>
      </div>
    </ScrollView>
  );
};

const container = () => css`
  background-color: ${colors.white};
  padding: 1rem 0;

  label {
    font-weight: 700;
    margin-bottom: 0.35rem;
  }

  label.input-label {
    display: block;
  }

  label .required {
    color: red;
  }

  .ant-checkbox-wrapper {
    padding-top: 37px;
  }

  .row {
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto 1.25rem auto;

    .col-25 {
      flex-basis: 25%;
    }

    .col-50 {
      flex-basis: 49%;
    }

    .col-100 {
      flex-basis: 100%;
    }

    @media screen and (max-width: 650px) {
      flex-wrap: wrap;

      .col-50 {
        flex-basis: 100%;

        &:nth-child(odd) {
          margin-bottom: 1.25rem;
        }
      }
    }
  }
`;

export default memo(ManagePunchListModal);
