import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { useSelector, useDispatch } from "react-redux";
import { notification } from "antd";

// Redux actions
import InlineSpinner from "../ui/InlineSpinner";
import colors from "../../style/colors";
import req from "../../utilities/request-utility";
import { showDialog } from "../../actions/uiActions";
import { AlertDecagramIcon } from "mdi-react";
import { SC_SET_SELECTED_PROJECT, SC_SET_SELECTED_PROJECT_AREA } from "../../actions/actionTypes";
import getLinkPathFromPageObject from "../../utilities/get-link-path-from-page-object";
import pageNavigator from "../../utilities/page-navigator";
import getAppName from "../../utilities/get-app-name";

const uiStates = {
  loading: "loading",
  hidden: "hidden",
};

function SemcompletionInitializer(props) {
  const [uiState, setUiState] = useState(uiStates.loading);
  const pages = useSelector((s) => s.pages.pages);
  const dispatch = useDispatch();

  function getProjectChooserPage() {
    return pages.filter((p) => p.module === "SemcompletionProjectChooser")[0];
  }

  useEffect(() => {
    (async () => {
      if (getAppName() === "semcompletion" || getAppName() === "semcompletion-rig") {
        try {
          const { data: project } = await req()(`semcompletion/projects/selected`);

          if (Object.keys(project).length === 0) {
            setUiState(uiStates.hidden);
            dispatch({
              type: SC_SET_SELECTED_PROJECT,
              payload: { id: "", number: "", description: "", projectType: "" },
            });
            dispatch(
              showDialog({
                title: "No project is selected",
                content: "You need to select a project before using semcompletion.",
                primaryAction: () =>
                  pageNavigator(getLinkPathFromPageObject(getProjectChooserPage(), "absolute"), "forward"),
                primaryActionTitle: "Choose a project now",
                styleType: "neutral",
                disableSecondaryAction: true,
              })
            );
          } else {
            setUiState(uiStates.hidden);
            dispatch({
              type: SC_SET_SELECTED_PROJECT,
              payload: project,
            });
          }

          const { data: projectArea } = await req()("semcompletion/projects/selected/project-area");

          if (projectArea) dispatch({ type: SC_SET_SELECTED_PROJECT_AREA, payload: projectArea });
        } catch (err) {
          setUiState(uiStates.hidden);
          notification.error({ duration: 7, message: "FAILED", description: "Make sure the correct project is selected" });
        }
      } else {
        setUiState(uiStates.hidden);
        return;
      }
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={componentStyles()}>
      {uiState === uiStates.loading && (
        <>
          <p>Syncing...</p>
          <InlineSpinner size="18" />
        </>
      )}
    </div>
  );
}

const componentStyles = () => css`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem;
  display: flex;
  align-items: center;

  div.spinner {
    margin-bottom: 0px;
    margin-left: 0.35rem;
    margin-right: 0.15rem;
  }

  p {
    font-size: 0.8rem;
    color: ${colors.darkGrey};
  }
`;

export default SemcompletionInitializer;
