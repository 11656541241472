import { useEffect, useState } from "react";
import req from "../../../../utilities/request-utility";

function usePunchSingleData(punchId) {
  const [punch, setPunch] = useState({
    data: {},
    loading: true,
    error: false,
  });

  useEffect(() => {
    getPunch();
    // eslint-disable-next-line
  }, []);

  function getPunch() {
    setPunch({ ...punch, loading: true });

    req()(`semcompletion/v2/punches/${punchId}`)
      .then(({ data }) =>
        setPunch({
          ...punch,
          loading: false,
          error: false,
          data: data,
        })
      )
      .catch((err) =>
        setPunch({
          ...punch,
          loading: false,
          error: true,
        })
      );
  }

  return { punch, getPunch };
}

export default usePunchSingleData;
