import React from "react";
import { css } from "emotion";
import Page from "./Page";
import TopBar from "./TopBar";
import ActionWrapper from "./ActionWrapper";
import { ArrowLeftIcon } from "mdi-react";
import pageNavigator from "../../utilities/page-navigator";

const SkeletonPage = (props) => (
  <Page className={componentStyle(props)}>
    <TopBar
      actionLeft={
        <ActionWrapper onClick={() => pageNavigator("FRONTPAGE", "backward")}>
          <ArrowLeftIcon />
        </ActionWrapper>
      }
      title={<span className="fake-title"></span>}
    />
  </Page>
);

const componentStyle = (props) => css`
  .fake-title {
    width: 140px;
    height: 9px;
    background-color: #fff;
    border-radius: 2px;
    opacity: 0.2;
    animation: blink 1.5s infinite;
    margin: 17px auto 0 auto;
  }

  @keyframes blink {
    0%,
    60% {
      opacity: 0.2;
    }

    70% {
      opacity: 0.4;
    }

    100% {
      opacity: 0.2;
    }
  }
`;

export default SkeletonPage;
