import React from "react";
import { css } from "emotion";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Input } from "antd";
import colors from "../../style/colors";

/** This component is quite similar to Textareainput, but its different in that way, that its only a  one line input */
const TextInput = (props) => {
  const { defaultValue, disabled = false, regExFormat = null, showCount = false, suffix, value, ...restProps } = props;

  let otherProps = { value: value || "" };
  if (typeof value === "undefined") otherProps = { defaultValue: defaultValue || "" };

  let Component = Input;
  if (props.type === "password") Component = Input.Password;
  if (props.type === "textarea") Component = Input.TextArea;

  return (
    <Component
      // className={`${style(props)} ${props.className || ""}`}
      disabled={disabled}
      maxLength={props.maxLength}
      minLength={props.minLength}
      name={props.name}
      placeholder={props.placeholder}
      onChange={(e) => {
        if (
          (regExFormat && e.target.value !== "" && e.target.value !== "+") ||
          (regExFormat && e.target.value !== "" && e.target.name === "pin")
        ) {
          const result = new RegExp(regExFormat, "gi").test(e.target.value);
          if (!result) return;
        }

        props.onChange(e);
      }}
      ref={props.innerRef}
      showCount={showCount}
      size="large"
      style={props.style}
      suffix={suffix || null}
      type={props.type || "text"}
      {...otherProps}
    />
  );

  return <input max={props.max} min={props.min} />;
};

const style = (props) => css`
  background-color: ${props.backgroundColor || colors.white};
  border: 1px solid ${props.backgroundColor || colors.midGrey};
  padding: 0.5rem;
  display: block;
  width: 100%;
  border-radius: 3px;
  font-size: 1rem;
  color: ${colors.black};
  resize: vertical;
  font-family: "Open Sans", sans-serif;
  -webkit-appearance: none;

  &:focus {
    outline: 0;
    border: 1px solid ${props.primaryColor};
  }
`;

const mapStateToProps = (state) => ({
  primaryColor: state.appConfig.primaryColor,
});

TextInput.propTypes = {
  /** Sets an HTML attribute */
  name: PropTypes.string,
  /** Visible text when you haven't anything typed in */
  placeholder: PropTypes.string,
  /** Function that executes everytime you type something in this component */
  onChange: PropTypes.func,
  /** sets a className for easy styling */
  className: PropTypes.string,
  /** Used for overriding styles */
  style: PropTypes.object,
};

export default connect(mapStateToProps)(TextInput);
