import {
  OPEN_SIDE_MENU,
  CLOSE_SIDE_MENU
} from "../actions/actionTypes"

const initialState = {
  active: false
}

export default function (state = initialState, action) {
  switch (action.type) {

    case OPEN_SIDE_MENU:
      return {
        ...state,
        active: true
      }

    case CLOSE_SIDE_MENU:
      return {
        ...state,
        active: false
      }

    default:
      return state
  }
}