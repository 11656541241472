import React from "react";
export default (searchstring, searchterm) => {
  /**
   * Utility function for highlighting matching words in a string
   * ---------------------
   * Arguments
   * @searchstring [string] Searchstring in which to find and highlight matches
   * @searchterm   [string] The term to highlight
   */

  searchstring = String(searchstring);
  searchterm = String(searchterm);

  // Get matchStartIndex
  let matchStart = searchstring.toLowerCase().trim().indexOf(searchterm.toLowerCase());

  // No match found in string (early return)
  if (matchStart === -1) return searchstring;

  // Get match end index
  let matchEnd = matchStart + searchterm.length;

  // Split string to array
  searchstring = searchstring.split("");

  // Get string beofore, at and after match
  let strBeforeMatch = searchstring.slice(0, matchStart).join("");
  let strMatch = searchstring.slice(matchStart, matchEnd).join("");
  let strAfterMatch = searchstring.slice(matchEnd).join("");

  // Return jsx-element (no XSS-vulnabilities since html in the strings would never be parsed)
  return (
    <span>
      {strBeforeMatch || ""}
      <span className="match" style={{ fontWeight: 700, color: "#4AA0FF" }}>
        {strMatch || ""}
      </span>
      {strAfterMatch || ""}
    </span>
  );
};
