import React, { useMemo, useState } from "react";
import { notification } from "antd";
import { css } from "emotion";
import { FloppyIcon } from "mdi-react";
import Spreadsheet from "react-spreadsheet";
import * as EmailValidator from "email-validator";
import { useQuery } from "react-query";
import { keyBy } from "lodash";

// styling
import colors from "../../../../style/colors";

// utlities
import req from "../../../../utilities/request-utility";
import getErrorMessage from "../../../../utilities/get-error-message";

// actions
import { hideModalPage } from "../../../../actions/uiActions";

// ui-Components
import Button from "../../../ui/Button";
import Page from "../../../ui/Page";
import { useDispatch } from "react-redux";
import ScrollView from "../../../ui/ScrollView";
import InlineSpinner from "../../../ui/InlineSpinner";

let timer = null;

function Modal({ refreshData }) {
  const dispatch = useDispatch();

  const dataDefault = Array.from({ length: 20 }, () => Array.from({ length: 6 }, () => ({ className: "", value: "" })));
  const [data, setData] = useState(dataDefault);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: { data: allUsers = [] } = {}, isLoading: usersIsLoading } = useQuery(["UserEmails"], () =>
    req()(`/users?all=true&attr[]=phone`)
  );
  const userPhones = allUsers.map((d) => d.phone);

  const { data: { data: allProjects = [] } = {}, isLoading: projectsIsLoading } = useQuery("Projects", () =>
    req()(`/semcompletion/projects?all=true`)
  );
  const allProjectsObj = keyBy(allProjects, "number");

  const checkPhoneIfExist = (uploadedUserPhoneNumberList = {}, uploadedUserPhoneNumber) => {
    // to check if a number already registered
    const isNumberUsedInExistingUsers = userPhones.includes(uploadedUserPhoneNumber);

    // to check if a number has duplicates from uploaded data
    const isNumberUsedInUploadedUsers = uploadedUserPhoneNumberList[uploadedUserPhoneNumber] > 1;

    return isNumberUsedInExistingUsers || isNumberUsedInUploadedUsers;
  };

  const save = async () => {
    try {
      setIsSubmitting(true);

      const errors = data.find((d) => {
        let [Name, Email, PhoneNumber, Pin, Company, Projects] = d;
        Name = Name.className ? Name.className.trim() : "";
        Email = Email.className ? Email.className.trim() : "";
        PhoneNumber = PhoneNumber.className ? PhoneNumber.className.trim() : "";
        Pin = Pin.className ? Pin.className.trim() : "";
        Company = Company.className ? Company.className.trim() : "";
        Projects = Projects.className ? Projects.className.trim() : "";

        if (Name !== "") return true;
        if (Email !== "") return true;
        if (PhoneNumber !== "") return true;
        if (Pin !== "") return true;
        if (Company !== "") return true;
        if (Projects !== "") return true;
        return false;
      });
      if (errors) {
        setIsSubmitting(false);
        return notification.error({ duration: 7, message: "FAILED", description: "Fix all issues before saving" });
      }

      const newFormData = data
        .map((d) => {
          let [Name, Email, PhoneNumber, Pin, Company, Projects] = d;
          Name = Name.value ? Name.value.trim() : "";
          Email = Email.value ? Email.value.trim() : "";
          PhoneNumber = PhoneNumber.value ? PhoneNumber.value.trim() : "";
          Pin = Pin.value ? Pin.value.trim() : "";
          Company = Company.value ? Company.value.trim() : "";
          Projects = Projects.value ? Projects.value.trim() : "";

          if (Name === "" && Email === "" && PhoneNumber === "" && Pin === "" && Company === "" && Projects === "")
            return null;

          const projectIds = Projects.split(",")
            .map((d) => d.trim())
            .filter((d) => d !== "");
          const projects = projectIds.map((d2) => ({ active: 1, id: allProjectsObj[d2].id }));

          return {
            name: Name,
            email: Email,
            phoneNumber: PhoneNumber,
            "4DigitPin": Pin,
            company: Company,
            projects,
          };
        })
        .filter((d) => d);

      await req().post(`/users/bulkCreate/`, { data: newFormData });

      dispatch(hideModalPage());
      if (refreshData) refreshData();
      setIsSubmitting(false);
      notification.success({
        duration: 7,
        message: "SUCCESS",
        description: "You have successfully added users. Email will be sent to the users shortly.",
      });
    } catch (error) {
      notification.error({ duration: 7, message: "FAILED", description: getErrorMessage(error) });
      setIsSubmitting(false);
    }
  };

  const onChange = (users) => {
    const phoneNumberCounts = users
      .map((d) => {
        let [Name, Email, PhoneNumber, Pin, Company, Projects] = d;
        Name = Name.value ? Name.value.trim() : "";
        Email = Email.value ? Email.value.trim() : "";
        PhoneNumber = PhoneNumber.value ? PhoneNumber.value.trim() : "";
        Pin = Pin.value ? Pin.value.trim() : "";
        Company = Company.value ? Company.value.trim() : "";
        Projects = Projects.value ? Projects.value.trim() : "";

        if (Name === "" && Email === "" && PhoneNumber === "" && Pin === "" && Company === "" && Projects === "")
          return null;

        return { PhoneNumber };
      })
      .filter((d) => d)
      .reduce((data, d) => {
        if (data[d.PhoneNumber]) data[d.PhoneNumber]++;
        else data[d.PhoneNumber] = 1;
        return data;
      }, {});

    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      const newData = users.map((d) => {
        const [NameTmp, EmailTmp, PhoneNumberTmp, PinTmp, CompanyTmp, ProjectsTmp] = d;
        const Name = NameTmp.value ? NameTmp.value.trim() : "";
        const Email = EmailTmp.value ? EmailTmp.value.trim() : "";
        const PhoneNumber = PhoneNumberTmp.value ? PhoneNumberTmp.value.trim() : "";
        const Pin = PinTmp.value ? PinTmp.value.trim() : "";
        const Company = CompanyTmp.value ? CompanyTmp.value.trim() : "";
        const Projects = ProjectsTmp.value ? ProjectsTmp.value.trim() : "";

        if (Name === "" && Email === "" && PhoneNumber === "" && Pin === "" && Company === "")
          return d.map((d2) => ({ ...d2, className: "" }));

        let errorName = [],
          errorEmail = [],
          errorPhoneNumber = [],
          errorPin = [],
          errorCompany = [],
          errorProjects = [];

        const containsSuffix = PhoneNumber.charAt(0) === "+";

        // NAME VALIDATION
        if (Name === "") errorName.push({ message: `Name is required` });

        // EMAIL VALIDATION
        if (Email !== "") if (!EmailValidator.validate(Email)) errorEmail.push({ message: `Email Address is invalid` });

        // PHONE VALIDATION
        if (PhoneNumber === "") errorPhoneNumber.push({ message: `Phone Number is required` });

        if (/[^\d]/.test(PhoneNumber.substring(containsSuffix)))
          errorPhoneNumber.push({
            message: `Invalid Phone - Enter digits only, '+' suffix is allowed`,
          });

        if (checkPhoneIfExist(phoneNumberCounts, PhoneNumber))
          errorPhoneNumber.push({
            message: `Invalid Phone Number - ${PhoneNumber} is already in use`,
          });

        // PIN VALIDATION
        if ((Pin !== "" && Pin.length !== 4) || /[^\d]/.test(Pin))
          errorPin.push({
            message: `Invalid PIN - Enter exact 4 digit only, leave empty to auto generate`,
          });

        // PROJECTS VALIDATION
        if (Projects !== "") {
          const projectIds = Projects.split(",")
            .map((d) => d.trim())
            .filter((d) => d !== "");
          projectIds.map((d) => {
            if (!allProjectsObj[d]) errorProjects.push({ message: `Invalid Project Number - ${d} is not found` });
          });
        }

        return [
          { className: errorName.length !== 0 ? "errorCell" : "", value: NameTmp.value ? NameTmp.value : "" },
          { className: errorEmail.length !== 0 ? "errorCell" : "", value: EmailTmp.value ? EmailTmp.value : "" },
          {
            className: errorPhoneNumber.length !== 0 ? "errorCell" : "",
            value: PhoneNumberTmp.value ? PhoneNumberTmp.value : "",
          },
          { className: errorPin.length !== 0 ? "errorCell" : "", value: PinTmp.value ? PinTmp.value : "" },
          { className: errorCompany.length !== 0 ? "errorCell" : "", value: CompanyTmp.value ? CompanyTmp.value : "" },
          { className: errorProjects.length !== 0 ? "errorCell" : "", value: ProjectsTmp.value ? ProjectsTmp.value : "" },
        ];
      });

      setData(newData);
    }, 500);
  };

  const withUsers = useMemo(() => {
    const newUsers = data
      .map((d) => {
        let [Name, Email, PhoneNumber, Pin, Company] = d;
        Name = Name.value ? Name.value.trim() : "";
        Email = Email.value ? Email.value.trim() : "";
        PhoneNumber = PhoneNumber.value ? PhoneNumber.value.trim() : "";
        Pin = Pin.value ? Pin.value.trim() : "";
        Company = Company.value ? Company.value.trim() : "";

        if (Name === "" && Email === "" && PhoneNumber === "" && Pin === "" && Company === "") return false;
        return true;
      })
      .filter((d) => d);
    return newUsers.length !== 0 ? true : false;
  }, [JSON.stringify(data)]);

  const columnLabels = ["Name *", "Email", "Phone Number *", "4 Digit Pin *", "Company", "Projects"];

  return (
    <Page className={container()}>
      <div className="header">
        <div className="row">
          <div className="actionButtons">
            <Button
              disabled={usersIsLoading || projectsIsLoading || isSubmitting || !withUsers}
              active={isSubmitting}
              style={{ flexBasis: "40%", flexShrink: 0, width: "100%" }}
              t
              onClick={save}
            >
              Save <FloppyIcon style={{ margin: "-0.6rem 0 -0.35rem 0" }} />
            </Button>
          </div>
        </div>
      </div>

      <ScrollView>
        {(usersIsLoading || projectsIsLoading) && <InlineSpinner />}
        {!usersIsLoading && !projectsIsLoading && (
          <Spreadsheet columnLabels={columnLabels} data={data} onChange={onChange} />
        )}
      </ScrollView>
    </Page>
  );
}

const container = () => css`
  .header {
    background-color: ${colors.white};
    padding: 1rem;
    margin: 3rem 0rem 1rem 0rem;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.85rem;

      .actionButtons {
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .errorCell {
    background: #f6bfbf;
    color: #000;
  }

  .icon-right {
    color: red !important;
  }

  .upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: 2px dashed #ccc;
    padding: 20px;
    box-sizing: border-box;
  }

  label {
    font-size: 18px;
    margin-bottom: 10px;
  }

  input[type="file"] {
    display: none;
  }

  button svg {
    display: inline-block;
    margin-bottom: -0.35rem;
  }
`;

export default Modal;
