export default {
  trueBlack: "#000000",
  black: "#242424",
  white: "#ffffff",
  ultraLightGrey: "#F5F5F5",
  lightGrey: "#E8EBED",
  midLightGrey: "#E2E6E8",
  midGrey: "#d1d1d1",
  midDarkGrey: "#737578",
  darkGrey: "#6E6E6E",
  blackOverlay: "rgba(0,0,0,0.6)",
  // Used for "action-semantics" (red -> danger/warning/error etc)
  green: "#0E9E72",
  greenLight: "#E9F5EB",
  greenBright: "#5bc775",
  vividBlue: "#2D92E8",
  blue: "#386FA6",
  blueLight: "#EFF9FF",
  orange: "#DD7F36",
  red: "#CF5757",
  redLight: "#FFEFEF",
  yellow: "#ffe065",
  yellowLight: "#ffface",
  yellowDark: "#e19312",
};
