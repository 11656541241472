import React from "react";
import { css } from "emotion";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Divider } from "antd";

import pkg from "../../../package.json";

// Redux actions
import { setSelectedFolder, setUrlSuffix } from "../../actions/libraryActions";
import { showDialog } from "../../actions/uiActions";

// Styling
import colors from "../../style/colors";

// Utilities
import clearLocalStorage from "../../utilities/clear-local-storage";
import getAppName from "../../utilities/get-app-name";

// Components
import Button from "../ui/Button";
import { AlertDecagramIcon } from "mdi-react";
import breakpoints from "../../config/breakpoints";

const UserSettings = (props) => {
  let { lang, setSelectedFolder, setUrlSuffix, showDialog } = props;

  return (
    <>
      <div style={{ maxWidth: `${breakpoints.lg}px`, margin: "0 auto" }}>
        <div className={contentContainer()}>
          {/* Hardcoded setting */}
          {/* <h2 style={{ margin: "2rem 0 1rem" }}>{lang.extra}</h2> */}
          <p style={{ marginBottom: "1rem" }}>{lang.logOutWarning}</p>
          <Button
            styleType={"warning"}
            onClick={() =>
              showDialog({
                icon: <AlertDecagramIcon />,
                styleType: "warning",
                title: lang.logOutWarningTitle,
                content: lang.logOutWarningContent,
                secondaryActionTitle: lang.cancel,
                primaryActionTitle: lang.logOutWarningAction,
                primaryAction: () =>
                  clearLocalStorage((err) => {
                    window.location = window.location.origin + "/" + getAppName() + "/";
                  }),
              })
            }
          >
            Log out
          </Button>
        </div>

        <Divider />

        <p className="meta" style={{ padding: "1rem", textAlign: "center" }}>
          App version {pkg.version}
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <Button
              styleType={"primary"}
              onClick={() => {
                caches.keys().then((cacheNames) => {
                  cacheNames.forEach((cacheName) => {
                    caches.delete(cacheName);
                  });
                });

                setSelectedFolder(null);
                setUrlSuffix("");

                alert("The cache has been cleared. The page will now reload.");

                // window.location.reload(true);
                window.location = window.location.href + "?eraseCache=true";
              }}
            >
              Get Latest Update
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

const dropdownWrapperStyle = () => css`
  position: relative;
  background: ${colors.white};
  border-top: 1px ${colors.midGrey} solid;
  border-bottom: 1px ${colors.midGrey} solid;

  @media screen and (min-width: ${breakpoints.lg}px) {
    border: 1px ${colors.midGrey} solid;
  }

  p {
    padding: 1rem 1rem 1rem 1rem;
    margin-bottom: -2.9rem;
  }

  select {
    padding: 2rem 1rem 1.25rem 1rem;
    appearance: none;
    font-size: 0.8125rem;
    color: ${colors.darkGrey};
    border: 0;
    border-radius: 0;
    background-color: transparent;
    width: 100%;
    outline: 0;
  }

  svg {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;

const contentContainer = () => css`
  margin: 1rem 0;
  padding: 0 1rem;

  p {
    white-space: initial;
  }
`;

const mapStateToProps = (state) => ({
  lang: state.language.language,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedFolder: bindActionCreators(setSelectedFolder, dispatch),
  setUrlSuffix: bindActionCreators(setUrlSuffix, dispatch),
  showDialog: bindActionCreators(showDialog, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
