import React from "react";
import { css } from "emotion";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import colors from "../../style/colors";
import common from "../../style/common";

/** Component to handle multiline textinput
 * 
 * ```
 *  <TextareaInput
          name="comment"
          value={this.state.comment}
          onChange={this.handleChange}
          placeholder="Indtast din kommentar her"
          style={{ marginBottom: baseDistance * 2 + "rem" }}
        />
 * ```
 */
const TextareaInput = (props) => (
  <textarea
    className={`${style(props)} ${props.className || ""}`}
    id={props.id}
    placeholder={props.placeholder}
    onChange={props.onChange}
    name={props.name}
    value={props.value}
    defaultValue={props.initText}
    style={props.style}
    disabled={props.disabled}
    data-test-id={props["data-test-id"]}
  />
);

const style = (props) => css`
  border: 1px solid ${props.backgroundColor || colors.midGrey};
  display: block;
  width: 100%;
  border-radius: 3px;
  font-size: 1rem;
  color: ${colors.black};
  resize: vertical;
  min-height: ${props.minHeight || "120px"};
  font-family: ${common.fontStack};
  padding: 0.5rem;
  box-sizing: border-box;
  -webkit-appearance: none;

  &:focus {
    outline: 0;
    border: 1px solid ${props.primaryColor};
  }
`;

const mapStateToProps = (state) => ({
  primaryColor: state.appConfig.primaryColor,
});

TextareaInput.propTypes = {
  /** Sets an HTML attribute */
  name: PropTypes.string,
  /** Visible text when you haven't anything typed in */
  placeholder: PropTypes.string,
  /** Function that executes everytime you type something in this component */
  onChange: PropTypes.func,
  /** sets a className for easy styling */
  className: PropTypes.string,
  /** Used for overriding styles */
  style: PropTypes.object,
};

export default connect(mapStateToProps)(TextareaInput);
