import { WELDLOGS } from "../actions/actionTypes";

const INITIAL_STATE = {
  data: [],
  end: false,
  error: null,
  form: null,
  formLoading: false,
  limit: 10,
  loading: false,
  offset: 0,
};

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case WELDLOGS.SETFORM:
      return { ...state, form: payload };
    case WELDLOGS.SETFORMPROP:
      return { ...state, form: { ...state.form, [payload.key]: payload.value } };

    case WELDLOGS.FETCH.REQUEST:
      return { ...state, ...payload, loading: true };
    case WELDLOGS.FETCH.SUCCESS:
      let weldLogs = [...state.data, ...payload.data];

      if (payload.id) return { ...state, form: payload.data, error: null, loading: false };
      return { ...state, data: weldLogs, end: payload.end, error: null, loading: false };
    case WELDLOGS.FETCH.FAILURE:
      return { ...state, error: payload, loading: false };

    case WELDLOGS.EDIT.REQUEST:
      return { ...state, formLoading: true, error: null };
    case WELDLOGS.EDIT.SUCCESS:
      return {
        ...state,
        data: payload.id ? [...state.data] : [payload.data, ...state.data],
        error: null,
        formLoading: false,
        form: payload.data,
      };
    case WELDLOGS.EDIT.FAILURE:
      return { ...state, error: payload, formLoading: false };

    case WELDLOGS.SAVE.REQUEST:
      return { ...state, formLoading: true, error: null };
    case WELDLOGS.SAVE.SUCCESS:
      return {
        ...state,
        data: state.data.map((d) => (d.id.toUpperCase() === payload.id.toUpperCase() ? payload : d)),
        error: null,
        form: null,
        formLoading: false,
      };
    case WELDLOGS.SAVE.FAILURE:
      return { ...state, action: null, error: payload, formLoading: false };

    case WELDLOGS.RESET:
      return { ...state, data: [], end: false, form: null };

    default:
      return state;
  }
};
