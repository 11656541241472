import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { isEqual, keyBy, uniq } from "lodash";
import { Col, notification, Row } from "antd";
import * as ExcelJS from "exceljs";
import * as FileSaver from "file-saver";

// styling
import { css } from "emotion";
import colors from "../../../../style/colors";

// ACTIONS
import { getPages } from "../../../../actions/pagesActions";

// ui-Components
import InlineSpinner from "../../../ui/InlineSpinner";
import Page from "../../../ui/Page";
import {
  AlertCircleOutlineIcon,
  ArrowBackIcon,
  CellphoneIcon,
  CircleIcon,
  DomainIcon,
  DotsVerticalIcon,
  EmailIcon,
  UserMultipleIcon,
} from "mdi-react";
import Button from "../../../ui/Button";
import DropDown from "../../../ui/DropDown";
import usePageAndBackButtonURL from "../../../../hooks/usePageAndBackButtonURL";
import TopBar from "../../../ui/TopBar";
import pageNavigator from "../../../../utilities/page-navigator";
import StatusBox from "../../../ui/StatusBox";
import ListItem from "../../../ui/ListItem";
import { hideModalPage, hideDialog, showDialog, showModalPage } from "../../../../actions/uiActions";
import ScrollView from "../../../ui/ScrollView";
import AccessCheck from "../../../ui/AccessCheck";
import SearchInput from "../../../ui/SearchInput";

import ModalCustom from "./Modal";
import PermissionsModal from "./PermissionsModal";
import BulkUploadModal from "./BulkUploadModal";

import req from "../../../../utilities/request-utility";

function UsersOverview(props) {
  const [exporting, setExporting] = useState(false);

  const dispatch = useDispatch();

  const [filters, setFilters] = useState({
    keywords: "",
    accountStatus: "",
    accountType: "",
    company: "",
    project: "",
  });

  const { backButtonURL } = usePageAndBackButtonURL(props);

  const { allPages, loading: allPagesLoading } = useSelector(({ pages }) => ({
    allPages: pages.allPages,
    loading: pages.loading,
  }));
  const { selectedProject } = useSelector(
    ({ semcompletion }) => ({ selectedProject: semcompletion.selectedProject }),
    isEqual
  );

  const { data: { data: usersProjects = [] } = {} } = useQuery(["UsersProjects"], () => req()(`/users/projects`), {
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000, //1 hour
  });

  const {
    isFetching: usersFetching = false,
    error: usersError = null,
    data: { data: allUsers = [] } = {},
    refetch: refetchUsers,
  } = useQuery(["Users"], () => req()(`/users?all=true`), {
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000, //1 hour
  });

  const companyList = uniq(allUsers.map((user) => user.company).filter((d) => d))
    .sort((a, b) => {
      const lowerA = a.toLowerCase();
      const lowerB = b.toLowerCase();
      if (lowerA < lowerB) return -1;
      if (lowerA > lowerB) return 1;
      return 0;
    })
    .map((d) => ({ label: d, value: d }));

  const usersRefetch = () => {
    refetchUsers();
    dispatch(getPages());
  };

  const handleChange = (e) => {
    if (e.persist) e.persist();
    setFilters((d) => ({ ...d, [e.target.name]: e.target.value ? e.target.value : "" }));
  };
  const handleClear = (id) => setFilters((d) => ({ ...d, [id]: "" }));

  const withFilters = useMemo(() => Object.values(filters).some((value) => value !== ""), [filters]);
  const usersData = useMemo(() => {
    if (!withFilters) return allUsers.filter((d) => d.active);

    let filteredUsers = [...allUsers];
    if (filters.keywords)
      filteredUsers = filteredUsers.filter(
        (d) =>
          d.name.toLowerCase().includes(filters.keywords.toLowerCase()) ||
          d.email.toLowerCase().includes(filters.keywords.toLowerCase()) ||
          d.phone.toLowerCase().includes(filters.keywords.toLowerCase())
      );
    if (filters.accountStatus)
      filteredUsers =
        filters.accountStatus === "all"
          ? filteredUsers
          : filteredUsers.filter((d) => (filters.accountStatus === "inactive" && !d.active ? true : false));
    if (filters.accountType)
      filteredUsers =
        filters.accountType === "admin"
          ? filteredUsers.filter((d) => (d.isAdmin ? true : false))
          : filteredUsers.filter((d) => (!d.isAdmin ? true : false));
    if (filters.company)
      filteredUsers = filteredUsers.filter((d) => {
        if (filters.company === "N/A" && !d.company) return true;
        if (d.company === filters.company) return true;
        return false;
      });
    if (filters.project) filteredUsers = filteredUsers.filter((d) => d.projects.includes(filters.project));

    return filteredUsers;
  }, [allUsers, filters]);

  const getPagesIdAndStatus = (pages) => {
    let pageIds = [];
    pages.map((page) => {
      pageIds.push({ id: page.id, active: page.active });

      if (page.pages.length > 0)
        page.pages = page.pages.map((childPage) => {
          pageIds.push({ id: childPage.id, active: childPage.active });
          return childPage;
        });

      return page;
    });

    return pageIds;
  };

  const exportUsers = async () => {
    if (allPagesLoading)
      return notification.info({
        duration: 7,
        message: "INFO",
        description: "Pages permissions are still not loaded. Please wait a moment and try again.",
      });

    setExporting(true);

    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("data", { views: [{ state: "frozen", ySplit: 1 }] });

    // PAGES
    let columnsPages = [];
    allPages.forEach((page) => {
      if (page.module === "SemcompletionProjectChooser") return;

      columnsPages.push({ header: page.title, key: page.title });

      if (page.pages.length > 0)
        page.pages.forEach((childPage) => columnsPages.push({ header: childPage.title, key: childPage.title }));
    });

    // PUNCH LIST
    const { data: allPunchList } = await req()(`semcompletion/v2/punches/punch-list`);
    const { data: allPunchListActions } = await req()(`semcompletion/v2/punches/punch-list/actions`);

    let columnsPunchList = [];
    allPunchList.map((d) => {
      const punchListActions = allPunchListActions.filter((p) => p.punchListId === d.id);
      columnsPunchList.push({ header: `${d.name} (Active)`, key: `${d.name} (Active)` });
      punchListActions.map((p) =>
        columnsPunchList.push({ header: `${d.name} (${p.action})`, key: `${d.name} (${p.action})` })
      );
    });

    sheet.columns = [
      { header: "Name", key: "name" },
      { header: "Email", key: "email" },
      { header: "Phone Number", key: "phoneNumber", width: 30, style: { numFmt: "@" } },
      { header: "Company", key: "company" },
      { header: "Active", key: "active" },
      ...columnsPages,
      ...columnsPunchList,
      { header: "Projects", key: "projects" },
    ];

    sheet.properties.defaultColWidth = 30;

    const { data: userPagesAll } = await req()(`/pages?user=all`);
    const { data: userPunchListAll } = await req()(`semcompletion/v2/punches/punch-list?user=all`);
    const { data: userPunchListActionsAll } = await req()(`semcompletion/v2/punches/punch-list/actions?user=all`);
    const { data: userProjectsAll } = await req()(`/users/projects?all=true`);

    usersData.map((d) => {
      // PAGES
      const userPages = userPagesAll.filter((p) => p.userId === d.id);
      const assignedPages = getPagesIdAndStatus(userPages),
        assignedPagesObj = keyBy(assignedPages, "id");

      // PUNCH LIST
      let dataPunchList = {};
      allPunchList.map((punchList) => {
        const punchListActions = allPunchListActions.filter((p) => p.punchListId === punchList.id);

        const assignedPunchList = userPunchListAll.filter((p) => p.userId === d.id && p.id === punchList.id);
        dataPunchList[`${punchList.name} (Active)`] = assignedPunchList.length > 0 ? "TRUE" : "FALSE";

        punchListActions.map((p) => {
          const assignedPunchListActions = userPunchListActionsAll.filter(
            (up) => up.userId === d.id && up.punchListActionId === p.id
          );
          dataPunchList[`${punchList.name} (${p.action})`] =
            assignedPunchListActions.length > 0 ? (assignedPunchListActions[0].active ? "TRUE" : "FALSE") : "FALSE";
        });
      });

      // PROJECTS
      const assignedProjects = userProjectsAll.filter((p) => p.UserId === d.id).map((d) => d.Description);

      let dataPages = {};
      allPages.forEach((page) => {
        if (page.module === "SemcompletionProjectChooser") return;

        dataPages[page.title] = assignedPagesObj[page.id] ? (assignedPagesObj[page.id].active ? "TRUE" : "FALSE") : "FALSE";

        if (page.pages) {
          if (page.pages.length > 0)
            page.pages.forEach(
              (childPage) =>
                (dataPages[childPage.title] = assignedPagesObj[childPage.id]
                  ? assignedPagesObj[childPage.id].active
                    ? "TRUE"
                    : "FALSE"
                  : "FALSE")
            );
        }
      });

      sheet.addRow({
        name: d.name,
        email: d.email,
        phoneNumber: d.phone,
        company: d.company,
        active: d.active ? "Yes" : "No",
        ...dataPages,
        ...dataPunchList,
        projects: assignedProjects.join(","),
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blobData = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(blobData, "semcompletion-users.xlsx");

    setExporting(false);
  };

  const onEdit = (d) => {
    let actions = [
      {
        title: "Edit User Information",
        action: () => {
          dispatch(hideDialog());
          dispatch(
            showModalPage({
              closeCallback: () =>
                dispatch(
                  showDialog({
                    title: "Hey there!",
                    content: "Your changes will not be saved. Are you sure you want to close the form?",
                    primaryActionTitle: "Yes, close the form",
                    primaryAction: () => dispatch(hideModalPage()),
                    secondaryActionTitle: "No, don't close the form",
                  })
                ),
              content: <ModalCustom form={d} refreshData={usersRefetch} />,
              title: `Edit User Information (${selectedProject.number} - ${selectedProject.description})`,
            })
          );
        },
      },
    ];

    if (d.active)
      actions.push({
        title: "Edit Permissions",
        action: async () => {
          dispatch(hideDialog());
          dispatch(
            showModalPage({
              closeCallback: () =>
                dispatch(
                  showDialog({
                    title: "Hey there!",
                    content: "Your changes will not be saved. Are you sure you want to close the form?",
                    primaryActionTitle: "Yes, close the form",
                    primaryAction: () => dispatch(hideModalPage()),
                    secondaryActionTitle: "No, don't close the form",
                  })
                ),
              content: <PermissionsModal form={d} refreshData={usersRefetch} />,
              title: `Edit Permissions (${selectedProject.number} - ${selectedProject.description})`,
            })
          );
        },
      });

    if (d.email && d.active)
      actions.push({
        title: "Send PIN to User Email",
        action: async () => {
          dispatch(hideDialog());
          await req().post(`/users/${d.id}/notify`, { action: "pin", type: "email" });
          notification.success({
            duration: 7,
            message: "SUCCESS",
            description: "The PIN has been sent to the user email address",
          });
        },
      });

    let disableSendPin = false,
      remainingSeconds = 0;

    const lastSendPin = localStorage.getItem(`send-request-${d.id}`);
    if (lastSendPin) {
      const now = new Date();
      const diff = now.getTime() - lastSendPin;
      const secondsLapsed = Math.abs(diff) / 1000;
      remainingSeconds = 300 - secondsLapsed;
      if (remainingSeconds > 0) disableSendPin = true;
    }

    if (d.phone && d.active)
      actions.push({
        disabled: disableSendPin,
        title: disableSendPin
          ? `Send PIN to User Phone (Please try again after ${remainingSeconds.toFixed(0)} Seconds)`
          : "Send PIN to User Phone",
        action: async () => {
          dispatch(hideDialog());
          await req().post(`/users/${d.id}/notify`, { action: "pin", type: "sms" });

          const nowNotify = new Date();
          localStorage.setItem(`send-request-${d.id}`, nowNotify.getTime());

          notification.success({
            duration: 7,
            message: "SUCCESS",
            description: "The PIN has been sent to the user phone number",
          });
        },
      });

    const dialog = {
      title: "Hey there!",
      content: "What do you want to do?",
      secondaryActionTitle: "Cancel",
      actions,
    };

    dispatch(showDialog(dialog));
  };

  const projectOptions = useMemo(
    () => usersProjects.map((d) => ({ label: d.Description, value: d.Id })),
    [JSON.stringify(usersProjects)]
  );

  return (
    <Page className={container()}>
      <TopBar
        title={`Users (${selectedProject.number} - ${selectedProject.description})`}
        actionLeft={<ArrowBackIcon onClick={() => pageNavigator(backButtonURL, "backward")} />}
      />
      <AccessCheck pageId={props.match.params.pageId}>
        <div className="header">
          <Row gutter={[10, 10]}>
            <Col className="countContainer" xxl={9} xl={9} lg={9} md={9} sm={24} xs={24}>
              <span className="count">
                {usersData.length} {withFilters ? "Results Found" : "Total Users"}
              </span>
            </Col>

            <Col xxl={5} xl={5} lg={5} md={5} sm={24} xs={24}>
              <Button
                active={exporting}
                disabled={usersFetching || exporting}
                onClick={exportUsers}
                style={{ flexBasis: "30%", flexShrink: 0, width: "100%" }}
              >
                Export Users
              </Button>
            </Col>
            <Col xxl={5} xl={5} lg={5} md={5} sm={24} xs={24}>
              <Button
                disabled={usersFetching || exporting}
                onClick={() =>
                  dispatch(
                    showModalPage({
                      closeCallback: () =>
                        dispatch(
                          showDialog({
                            title: "Hey there!",
                            content: "Your changes will not be saved. Are you sure you want to close the form?",
                            primaryActionTitle: "Yes, close the form",
                            primaryAction: () => dispatch(hideModalPage()),
                            secondaryActionTitle: "No, don't close the form",
                          })
                        ),
                      content: <ModalCustom refreshData={usersRefetch} />,
                      title: `Add User (${selectedProject.number} - ${selectedProject.description})`,
                    })
                  )
                }
                style={{ flexBasis: "30%", flexShrink: 0, width: "100%" }}
              >
                Add User
              </Button>
            </Col>
            <Col xxl={5} xl={5} lg={5} md={5} sm={24} xs={24}>
              <Button
                disabled={usersFetching || exporting}
                onClick={() =>
                  dispatch(
                    showModalPage({
                      title: "Add Multiple Users",
                      closeCallback: () => {
                        const dialog = {
                          title: "Hey there!",
                          content: "Are you sure you want to close the page?",
                          primaryActionTitle: "Yes, close the page",
                          primaryAction: () => {
                            dispatch(hideModalPage());
                          },
                          secondaryActionTitle: "No, don't close the page",
                        };

                        dispatch(showDialog(dialog));
                      },
                      content: (
                        <BulkUploadModal
                          usersPhoneList={usersData.map((u) => ({ id: u.id, phoneNumber: u.phone }))}
                          refreshData={usersRefetch}
                        />
                      ),
                    })
                  )
                }
                style={{ flexBasis: "30%", flexShrink: 0, width: "100%" }}
              >
                Add Multiple Users
              </Button>
            </Col>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <SearchInput
                name="keywords"
                onChange={handleChange}
                onClearSearch={() => handleClear("keywords")}
                placeholder="Search for Name, Mobile or Email"
                style={{ width: "100%" }}
                value={filters.keywords}
              />
            </Col>
            <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
              <DropDown
                allowClear={true}
                className={filters.accountType ? "withValue" : ""}
                name="accountType"
                onChange={handleChange}
                options={[
                  { label: "Admin", value: "admin" },
                  { label: "Normal User", value: "normalUser" },
                ]}
                placeholder="Account Type"
                value={filters.accountType}
              />
            </Col>
            <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
              <DropDown
                allowClear={true}
                className={filters.accountStatus ? "withValue" : ""}
                name="accountStatus"
                onChange={handleChange}
                options={[
                  { label: "All", value: "all" },
                  { label: "Inactive", value: "inactive" },
                ]}
                placeholder="Account Status"
                value={filters.accountStatus}
              />
            </Col>
            <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
              <DropDown
                allowClear={true}
                className={filters.company ? "withValue" : ""}
                name="company"
                onChange={handleChange}
                options={[{ label: "N/A", value: "N/A" }, ...companyList]}
                placeholder="Company"
                value={filters.company}
              />
            </Col>
            <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
              <DropDown
                allowClear={true}
                className={filters.project ? "withValue" : ""}
                name="project"
                onChange={handleChange}
                options={projectOptions}
                placeholder="Project"
                value={filters.project}
              />
            </Col>
            <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
              <Button
                disabled={withFilters ? false : true}
                onClick={() =>
                  setFilters({ ...filters, keywords: "", accountStatus: "", accountType: "", company: "", project: "" })
                }
              >
                Clear Filters
              </Button>
            </Col>
          </Row>
        </div>
        <ScrollView>
          <div style={{ padding: "1rem 0" }}>
            {usersFetching && !usersError && <InlineSpinner />}
            {!usersFetching && usersError && <StatusBox />}
            {!usersFetching && !usersError && usersData.length === 0 && (
              <StatusBox title="No users found" icon={<AlertCircleOutlineIcon />} content="  " />
            )}

            {!usersFetching &&
              !usersError &&
              usersData.length !== 0 &&
              usersData.map((d, i) => (
                <ListItem
                  iconRight={<DotsVerticalIcon />}
                  onClick={() => onEdit(d)}
                  style={d.active ? { marginBottom: 3 } : { marginBottom: 3, opacity: 0.5 }}
                  title={
                    <span className="boldTitle">
                      <CircleIcon className="circle" color={d.active ? "green" : "red"} size={12} />
                      {d.name}
                    </span>
                  }
                  subContent={
                    <ul>
                      <li>
                        <EmailIcon size={10} />
                        {d.email ? d.email : "N/A"}
                      </li>
                      <li>
                        <CellphoneIcon size={10} />
                        {d.phone}
                      </li>
                      <li>
                        <DomainIcon size={10} />
                        {d.company ? d.company : "N/A"}
                      </li>
                      <li>
                        <UserMultipleIcon size={10} />
                        {d.isAdmin ? "Admin" : "Normal User"}
                      </li>
                    </ul>
                  }
                  key={d.id}
                  index={i + 1}
                  length={usersData.length}
                />
              ))}
          </div>
        </ScrollView>
      </AccessCheck>
    </Page>
  );
}

const container = () => css`
  .header {
    background-color: ${colors.white};
    padding: 1rem;

    .punch-count-container {
      flex-basis: 50%;
      padding: 0 0.5rem;
      display: flex;
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.85rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      .dropdown {
        flex-grow: 1;
        margin: 0 0.35rem;
        flex-basis: 25%;

        &:first-of-type {
          margin-left: 0;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  button {
    height: 100%;
  }

  button svg {
    display: inline-block;
    margin-bottom: -0.35rem;
  }

  .circle {
    margin-right: 10px;
  }

  .countContainer {
    display: flex;
    align-items: center;

    .count {
      color: #103447;
      font-size: 20px;
      font-weight: bold;
    }
  }
`;

export default UsersOverview;
