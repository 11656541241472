import { useSelector } from "react-redux";
import { useQuery } from "react-query";

import req from "../../../../utilities/request-utility";

const useOverviewData = () => {
  const selectedProject = useSelector((s) => s.semcompletion.selectedProject);

  const {
    isLoading: wireDiagramIsLoading,
    error: wireDiagramError,
    data: { data: wireDiagramDataData = {} } = {},
  } = useQuery(`${selectedProject ? selectedProject.id : "project"}-wireDiagram`, () =>
    req()(`semcompletion/wireTerminations`)
  );

  const wireDiagramData = { data: wireDiagramDataData, loading: wireDiagramIsLoading, error: wireDiagramError };

  return { wireDiagramData };
};

export default useOverviewData;
