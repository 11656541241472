import getAppName from "./get-app-name";

import { initialState as libraryReducerInitialState } from "../reducers/libraryReducer";

export default () => {
  try {
    let serializedState = localStorage.getItem(`${getAppName()}-state`);
    if (!serializedState) return undefined;

    serializedState = JSON.parse(serializedState);
    serializedState = {
      ...serializedState,
      library: { ...libraryReducerInitialState, ...serializedState.library },
    };

    return serializedState;
  } catch (err) {
    return undefined;
  }
};
