import { LIBRARY } from "../actions/actionTypes";

export const initialState = {
  folders: [],
  libraryFileQuery: { active: true },
  libraryFolderQuery: { active: true },
  libraryURLQuery: { active: true },
  manageLibraryFileQuery: { active: true },
  manageLibraryFolderQuery: { active: true },
  manageLibraryURLQuery: { active: true },
  selectedFolder: "",
  selectedManageLibraryPath: "",
  selectedLibraryPath: "",
  urlSuffix: "",
};

export default function (state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case LIBRARY.SETFOLDERS:
      return { ...state, folders: payload };
    case LIBRARY.SETSELECTEDFOLDER:
      return { ...state, selectedFolder: payload };
    case LIBRARY.SETURLSUFFIX:
      return { ...state, urlSuffix: payload };
    case LIBRARY.SETSELECTEDMANAGELIBRARYPATH:
      return { ...state, selectedManageLibraryPath: payload };
    case LIBRARY.SETSELECTEDLIBRARYPATH:
      return { ...state, selectedLibraryPath: payload };
    case LIBRARY.SETLIBRARYFOLDERQUERY:
      return { ...state, libraryFolderQuery: payload };
    case LIBRARY.SETMANAGELIBRARYFILEQUERY:
      return { ...state, manageLibraryFileQuery: payload };
    case LIBRARY.SETMANAGELIBRARYFOLDERQUERY:
      return { ...state, manageLibraryFolderQuery: payload };
    case LIBRARY.SETMANAGELIBRARYURLQUERY:
      return { ...state, manageLibraryURLQuery: payload };
    default:
      return state;
  }
}
