import { combineReducers } from "redux";

// Reducers
import authReducer from "./authReducer";
import appConfigReducer from "./appConfigReducer";
import languageReducer from "./languageReducer";
import pagesReducer from "./pagesReducer";
import paper from "./paperReducer";
import sideMenuReducer from "./sideMenuReducer";
import weldLogReducer from "./weldLogReducer";
import redmarkLogReducer from "./redmarkLogReducer";
import uiReducer from "./uiReducer";
import wireDagramReducer from "./wireDagramReducer";
import testSheetReducer from "./testSheetReducer";
import mcTaskReducer from "./MCTaskReducer";
import mcEquipmentTaskReducer from "./MCEquipmentTaskReducer";
import libraryReducer from "./libraryReducer";
import pdfReducer from "./pdfReducer";

// Semco
import semcompletionReducer from "./semcompletionReducer";

export default combineReducers({
  appConfig: appConfigReducer,
  auth: authReducer,
  language: languageReducer,
  pages: pagesReducer,
  paper,
  sideMenu: sideMenuReducer,
  ui: uiReducer,
  weldLog: weldLogReducer,
  redmarkLog: redmarkLogReducer,
  semcompletion: semcompletionReducer,
  wireDiagram: wireDagramReducer,
  testSheet: testSheetReducer,
  mcTask: mcTaskReducer,
  mcEquipmentTask: mcEquipmentTaskReducer,
  library: libraryReducer,
  pdf: pdfReducer,
});
