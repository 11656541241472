import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import params from "jquery-param";

import req from "../../../../../utilities/request-utility";

const useOverviewData = ({ query }) => {
  const selectedProject = useSelector((s) => s.semcompletion.selectedProject);

  const {
    isLoading: metaDataIsLoading,
    error: metaDataError,
    data: { data: metaDataData = {} } = {},
  } = useQuery(`${selectedProject ? selectedProject.id : "project"}-equipment-metaData`, () =>
    req()(`semcompletion/equipments/meta-data`)
  );

  const metaData = { data: metaDataData, loading: metaDataIsLoading, error: metaDataError };

  const {
    data: { data: equipmentsPageData = [] } = {},
    error: equipmentsPageError = null,
    isFetching: equipmentsPageFetching = false,
    refetch: equipmentsPageRefetch,
  } = useQuery(
    ["equipmentsPage", query],
    (param) => {
      return req()(`semcompletion/equipments?${params(query)}`);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const equipmentsData = {
    data: equipmentsPageData,
    error: equipmentsPageError,
    loading: equipmentsPageFetching,
    refetch: equipmentsPageRefetch,
  };

  return { equipmentsData, metaData };
};

export default useOverviewData;
