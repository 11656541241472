import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { css } from "emotion";
import { ArrowBackIcon, InformationOutlineIcon, PlusIcon } from "mdi-react";
import { isEqual } from "lodash";
import queryString from "query-string";

import AccessCheck from "../../../ui/AccessCheck";
import Button from "../../../ui/Button";
import InlineSpinner from "../../../ui/InlineSpinner";
import Page from "../../../ui/Page";
import ScrollView from "../../../ui/ScrollView";
import StatusBox from "../../../ui/StatusBox";
import Toggle from "../../../ui/Toggle";
import TopBar from "../../../ui/TopBar";

import colors from "../../../../style/colors";

import usePageAndBackButtonURL from "../../../../hooks/usePageAndBackButtonURL";
import useOverviewData from "./hooks/useOverviewData";

import getPageFromId from "../../../../utilities/get-page-from-id";
import linkPathCreator from "../../../../utilities/get-link-path-from-page-object";
import pageNavigator from "../../../../utilities/page-navigator";

import breakpoints from "../../../../config/breakpoints";

import { hideModalPage, showDialog, showModalPage } from "../../../../actions/uiActions";
import {
  setSelectedFolder,
  setManageLibraryFileQuery,
  setManageLibraryFolderQuery,
  setManageLibraryURLQuery,
  setUrlSuffix,
} from "../../../../actions/libraryActions";

import Modal from "./Modal";
import LibraryRenderer from "../../LibraryRenderer";
import UploadForm from "./UploadForm";

const ManageLibraryOverview = ({ match }) => {
  const [showArchive, setShowArchive] = useState(false);

  const dispatch = useDispatch();

  const { pages, selectedFolder, selectedProject, urlSuffix } = useSelector(
    ({ library, pages, semcompletion }) => ({
      pages: pages.pages,
      selectedFolder: library.selectedFolder,
      selectedProject: semcompletion.selectedProject,
      urlSuffix: library.urlSuffix,
    }),
    isEqual
  );

  const { backButtonURL } = usePageAndBackButtonURL({ match });

  const query = queryString.parse(window.location.search);

  const page = getPageFromId(pages, match.params.pageId);

  const { folderFiles, folderOptions, folderFileOptions, folderUrlOptions, folders, folderUrls } = useOverviewData({
    isAdmin: true,
  });

  const isPortrait = useMediaQuery({ orientation: "portrait" });

  const {
    data: foldersData,
    error: foldersError,
    fetching: foldersFetching,
    loading: foldersLoading,
    refetch: folderRefetch,
  } = folders;

  const { data: folderOptionsData, refetch: folderOptionsRefetch } = folderOptions;

  const { data: folderFileOptionsData, refetch: folderFileOptionsRefetch } = folderFileOptions;

  const { data: folderUrlOptionsData, refetch: folderUrlOptionsRefetch } = folderUrlOptions;

  const {
    data: folderFilesData,
    error: folderFilesError,
    fetching: folderFilesFetching,
    loading: folderFilesLoading,
    refetch: folderFilesRefetch,
  } = folderFiles;

  const {
    data: folderUrlsData,
    error: folderUrlsError,
    fetching: folderUrlsFetching,
    loading: folderUrlsLoading,
    refetch: folderUrlsRefetch,
  } = folderUrls;

  const handleBackButtonClick = useCallback(() => {
    const slicedUrlSuffix = urlSuffix.replace(/\/$/g, "").split("/").slice(0, -1);

    const filteredSlicedUrlSuffix = slicedUrlSuffix.filter((d) => d !== "");

    const dataId = filteredSlicedUrlSuffix[filteredSlicedUrlSuffix.length - 1];

    const newUrlSuffix = slicedUrlSuffix.join("/");

    dispatch(setUrlSuffix(newUrlSuffix));
    dispatch(
      setSelectedFolder(
        folderOptionsData.find(
          (d) =>
            d.id ===
            newUrlSuffix
              .split("/")
              .filter((d) => d !== "")
              .pop()
        )
      )
    );

    dispatch(setManageLibraryFileQuery({ active: true }));
    dispatch(setManageLibraryFolderQuery({ active: true, parentFolderId: dataId }));
    dispatch(setManageLibraryURLQuery({ active: true }));

    pageNavigator(
      urlSuffix === "" ? backButtonURL : linkPathCreator({ ...page, dataId, urlSuffix: newUrlSuffix, withAppName: true }),
      "backward"
    );
  }, [urlSuffix, backButtonURL, folderOptionsData]);

  useEffect(() => {
    if (!foldersLoading) folderRefetch();
    if (!folderFilesLoading) folderFilesRefetch();
    if (!folderUrlsLoading) folderUrlsRefetch();
  }, [showArchive]);

  useEffect(() => {
    const beforeUnloadHandler = async (e) => {
      const semcompletionLocalState = JSON.parse(localStorage.getItem("semcompletion-state"));

      localStorage.setItem(
        "semcompletion-state",
        JSON.stringify({
          ...semcompletionLocalState,
          library: {
            ...semcompletionLocalState.library,
            manageLibraryFileQuery: { ...semcompletionLocalState.library.manageLibraryFileQuery, active: true },
            manageLibraryFolderQuery: { ...semcompletionLocalState.library.manageLibraryFolderQuery, active: true },
            manageLibraryURLQuery: { ...semcompletionLocalState.library.manageLibraryURLQuery, active: true },
          },
        })
      );
    };

    window.addEventListener("beforeunload", beforeUnloadHandler);

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, []);

  return (
    <Page className={container({ isPortrait })}>
      <TopBar
        title={`Manage Library (${selectedProject.number} - ${selectedProject.description})`}
        actionLeft={<ArrowBackIcon onClick={handleBackButtonClick} />}
      />
      <AccessCheck pageId={match.params.pageId}>
        <div className="header">
          <div className="row">
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", width: "80%" }}>
              {!showArchive && (
                <Button
                  onClick={() =>
                    dispatch(
                      showModalPage({
                        closeCallback: () =>
                          dispatch(
                            showDialog({
                              content: "Your changes will not be saved. Are you sure you want to close the form?",
                              primaryAction: () => dispatch(hideModalPage()),
                              primaryActionTitle: "Yes, close the form",
                              secondaryActionTitle: "No, don't close the form",
                              title: "Hey there!",
                            })
                          ),
                        content: (
                          <Modal
                            folderOptions={folderOptionsData}
                            folderOptionsRefetch={folderOptionsRefetch}
                            folderRefetch={folderRefetch}
                          />
                        ),
                        title: `Add Folder (${selectedProject.number} - ${selectedProject.description})`,
                      })
                    )
                  }
                  style={{ width: "25%" }}
                >
                  Add Folder
                </Button>
              )}
              {selectedFolder && !showArchive && (
                <>
                  <Button
                    onClick={() =>
                      dispatch(
                        showModalPage({
                          closeCallback: () =>
                            dispatch(
                              showDialog({
                                content: "Your changes will not be saved. Are you sure you want to close the form?",
                                primaryAction: () => dispatch(hideModalPage()),
                                primaryActionTitle: "Yes, close the form",
                                secondaryActionTitle: "No, don't close the form",
                                title: "Hey there!",
                              })
                            ),
                          content: (
                            <UploadForm
                              action="Add Files"
                              folderFilesRefetch={folderFilesRefetch}
                              folderUrlsRefetch={folderUrlsRefetch}
                            />
                          ),
                          title: `Add Files (${selectedProject.number} - ${selectedProject.description})`,
                        })
                      )
                    }
                    style={{ width: "25%" }}
                  >
                    Add Files
                  </Button>
                  <Button
                    onClick={() =>
                      dispatch(
                        showModalPage({
                          closeCallback: () =>
                            dispatch(
                              showDialog({
                                content: "Your changes will not be saved. Are you sure you want to close the form?",
                                primaryAction: () => dispatch(hideModalPage()),
                                primaryActionTitle: "Yes, close the form",
                                secondaryActionTitle: "No, don't close the form",
                                title: "Hey there!",
                              })
                            ),
                          content: (
                            <UploadForm
                              action="Add URL"
                              folderFilesRefetch={folderFilesRefetch}
                              folderUrlsRefetch={folderUrlsRefetch}
                            />
                          ),
                          title: `Add URL (${selectedProject.number} - ${selectedProject.description})`,
                        })
                      )
                    }
                    style={{ width: "25%" }}
                  >
                    Add URL
                  </Button>
                </>
              )}
            </div>
            <div style={{ alignItems: "center", display: "flex" }}>
              <Toggle
                on={showArchive}
                onClick={() => {
                  setShowArchive(!showArchive);

                  dispatch(setManageLibraryFileQuery({ active: showArchive, parentFolderId: query.dataId }));
                  dispatch(setManageLibraryFolderQuery({ active: showArchive, parentFolderId: query.dataId }));
                  dispatch(setManageLibraryURLQuery({ active: showArchive, parentFolderId: query.dataId }));
                }}
                style={{ marginRight: 10 }}
              />
              <span>Show Archived Items</span>
            </div>
          </div>
        </div>
        <ScrollView>
          {(foldersLoading ||
            foldersFetching ||
            folderFilesLoading ||
            folderFilesFetching ||
            folderUrlsLoading ||
            folderUrlsFetching) &&
            !foldersError &&
            !folderFilesError &&
            !folderUrlsError && <InlineSpinner style={{ marginTop: "1rem" }} title="Loading Folders..." />}
          {!foldersLoading &&
            !folderFilesLoading &&
            !folderUrlsLoading &&
            (foldersError || folderFilesError || folderUrlsError) && <StatusBox />}
          {!foldersLoading &&
            !foldersFetching &&
            !folderFilesLoading &&
            !folderFilesFetching &&
            !folderUrlsLoading &&
            !folderUrlsFetching &&
            !foldersError &&
            !folderFilesError &&
            !folderUrlsError && (
              <div style={{ padding: "2rem 2rem" }}>
                {showArchive && (
                  <div className="remark">
                    <InformationOutlineIcon />
                    <p className="line-break">You are viewing archived records.</p>
                  </div>
                )}
                <LibraryRenderer
                  dataId={query.dataId ? query.dataId : ""}
                  folderFilesData={folderFilesData}
                  folderFilesRefetch={folderFilesRefetch}
                  folderOptions={folderOptionsData}
                  folderFileOptions={folderFileOptionsData}
                  folderUrlOptions={folderUrlOptionsData}
                  folderRefetch={folderRefetch}
                  foldersData={foldersData}
                  folderUrlsData={folderUrlsData}
                  folderUrlsRefetch={folderUrlsRefetch}
                  isAdmin={true}
                  pageId={match.params.pageId}
                  showArchive={showArchive}
                />
              </div>
            )}
        </ScrollView>
      </AccessCheck>
    </Page>
  );
};

const container = ({ isPortrait }) => css`
  .header {
    background-color: ${colors.white};
    padding: 1rem;

    .punch-count-container {
      flex-basis: 50%;
      padding: 0 0.5rem;
      display: flex;
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.85rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      .dropdown {
        flex-grow: 1;
        margin: 0 0.35rem;
        flex-basis: 25%;

        &:first-of-type {
          margin-left: 0;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  .remark {
    display: flex;
    padding: 0.5rem;
    background-color: ${colors.yellowLight};
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid ${colors.yellow};
    border-radius: 5px;
    width: ${isPortrait ? "auto" : `${breakpoints.lg}px`};

    svg {
      margin-right: 0.5rem;
    }
  }

  button svg {
    display: inline-block;
    margin-bottom: -0.35rem;
  }
`;

export default memo(ManageLibraryOverview);
