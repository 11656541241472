import React, { memo, useCallback, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { css } from "emotion";
import { ArrowBackIcon } from "mdi-react";

import useOverviewData from "./hooks/useOverviewData";

import Button from "../../ui/Button";
import InlineSpinner from "../../ui/InlineSpinner";
import Page from "../../ui/Page";
import ScrollView from "../../ui/ScrollView";
import TextInput from "../../ui/TextInput";
import TopBar from "../../ui/TopBar";

import pageNavigator from "../../../utilities/page-navigator";
import req from "../../../utilities/request-utility";

import usePageAndBackButtonURL from "../../../hooks/usePageAndBackButtonURL";

import colors from "../../../style/colors";

const WireDiagramOverview = (props) => {
  const [loading, setLoading] = useState(false);
  const [wireTerminations, setWireTerminations] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const { backButtonURL } = usePageAndBackButtonURL(props);

  const { wireDiagramData } = useOverviewData();

  const { data: wireDiagramPageData } = wireDiagramData;

  const createStartSvgTag = useCallback(
    (svgSize) => `<svg height='${svgSize.height}' width='${svgSize.width}' xmlns-xlink='http://www.w3.org/1999/xlink'>`,
    []
  );

  const createEndSvgTag = useCallback(() => {
    let svgMarkup = "Sorry, your browser does not support inline SVG.";
    svgMarkup = `${svgMarkup} </svg></svg>`;

    return svgMarkup;
  }, []);

  const svgTSpan = useCallback((text) => `<tspan>${text}</tspan>`, []);

  const svgText = useCallback(
    (x, y, style, id, text, canEdit = false) => {
      if (canEdit && isEditing) {
        return `<foreignObject height="200" width="100" x='${x}' y='${y}'><body xmlns="http://www.w3.org/1999/xhtml"><input id='${id}' type='text' value='${text}' style='width: 100%;' /></body></foreignObject`;
      }

      return `<text x='${x}' id='${id}'  y='${y}' alignment-baseline='middle' anchor='middle' ${style}>${text}</text>`;
    },
    [isEditing]
  );

  const svgCircle = useCallback(
    (cx, cy, r, stroke, strokeWidth, fill) =>
      `<circle cx='${cx}' cy='${cy}' r='${r}' stroke='${stroke}' stroke-width='${strokeWidth}' fill='${fill}' />`,
    []
  );

  const svgLine = useCallback(
    (x1, y1, x2, y2, style) => `<line x1='${x1}' y1='${y1}' x2='${x2}' y2='${y2}' style='${style}'/>`,
    []
  );

  const createSvgHeader = useCallback((headerStartY, wireData) => {
    const fromEquipDescPos = 0;
    const cableStartPos = 500;
    const toEquipDescPos = 1000;

    const headerTextStyle = "style='fill: #000000; stroke: none; font-size: 12px;'";

    let fromHeaderText = svgTSpan(`${wireData.header.fromEnclosure} ${wireData.header.fromEnclosureDescription}`);
    fromHeaderText = `${fromHeaderText} ${svgTSpan(
      `${wireData.header.fromDevice} ${wireData.header.fromDeviceDescription}`
    )}`;

    let toHeaderText = svgTSpan(`${wireData.header.toEnclosure} ${wireData.header.toEnclosureDescription}`);
    toHeaderText = `${toHeaderText} ${svgTSpan(`${wireData.header.toDevice} ${wireData.header.toDeviceDescription}`)}`;

    let svgMarkup = svgText(fromEquipDescPos, headerStartY, headerTextStyle, "HeaderFrom", fromHeaderText);
    svgMarkup = `${svgMarkup} ${svgText(
      cableStartPos,
      headerStartY,
      headerTextStyle,
      "CableTag",
      wireData.header.cableTag
    )}`;
    svgMarkup = `${svgMarkup} ${svgText(toEquipDescPos, headerStartY, headerTextStyle, "ToEquipDesc", toHeaderText)}`;

    return svgMarkup;
  }, []);

  const fromConnectorLine = useCallback(
    (posY, posX, wireLabel, fromConnectorName) => {
      const itemTextStyle = "style='fill: #000000; stroke: none; font-size: 12px;'";

      let svgFromConnectorTag = svgText(
        fromConnectorName === "NOT CONNEC" ? 5 : posX,
        posY,
        itemTextStyle,
        "FromConnector",
        fromConnectorName,
        true
      );
      svgFromConnectorTag = `${svgFromConnectorTag} ${svgCircle(posX + 40, posY, 10, "black", "1", "white")}`;
      svgFromConnectorTag = `${svgFromConnectorTag} ${svgText(
        isEditing ? 160 : posX + 80,
        isEditing ? 70 : posY - 5,
        itemTextStyle,
        "FromWireLabel",
        wireLabel,
        true
      )}`;
      svgFromConnectorTag = `${svgFromConnectorTag} ${svgLine(posX + 50, posY, posX + 180, posY, "stroke: black;")}`;

      return svgFromConnectorTag;
    },
    [isEditing]
  );

  const toConnectorLine = useCallback(
    (posY, posX, wireLabel, toConnectorName) => {
      const itemTextStyle = "style='fill: #000000; stroke: none; font-size: 12px;'";

      let svgToConnectorTag = svgLine(posX + 880, posY, posX + 1010, posY, "stroke: black;");
      svgToConnectorTag = `${svgToConnectorTag} ${svgText(
        posX + 1025,
        posY,
        itemTextStyle,
        "ToConnector",
        toConnectorName,
        true
      )}`;
      svgToConnectorTag = `${svgToConnectorTag} ${svgCircle(posX + 1010, posY, 10, "black", "1", "white")}`;
      svgToConnectorTag = `${svgToConnectorTag} ${svgText(posX + 970, posY - 5, itemTextStyle, "WireLabel", wireLabel)}`;

      return svgToConnectorTag;
    },
    [isEditing]
  );

  const separatorLine = useCallback((posY, posX) => {
    let svgSepTag = svgLine(posX + 180, posY, posX + 280, posY - 30, "stroke: black;");
    svgSepTag = `${svgSepTag} ${svgLine(posX + 280, posY - 30, posX + 748, posY - 30, "stroke: black;")}`;
    svgSepTag = `${svgSepTag} ${svgLine(posX + 880, posY, posX + 748, posY - 30, "stroke: black;")}`;

    return svgSepTag;
  }, []);

  const createSvgLine = useCallback(
    (posY, posX, wireItem) => {
      let svgLinesTag = fromConnectorLine(posY, posX, wireItem.wireLabel, wireItem.fromConnectorName);
      svgLinesTag = `${svgLinesTag} ${separatorLine(posY, posX)}`;
      svgLinesTag = `${svgLinesTag} ${toConnectorLine(posY, posX, wireItem.wireLabel, wireItem.toConnectorName)}`;

      return svgLinesTag;
    },
    [isEditing]
  );

  useEffect(() => {
    setLoading(true);

    if (wireDiagramPageData.length > 0) {
      (async () => {
        const svgToRender = [];

        for (const wd of wireDiagramPageData) {
          const { data } = await req()(
            `semcompletion/wireTerminations/${wd.cableSystemId}/8363F314-38FB-4AC8-8541-63E49B536EFB`
          );

          let svgWireData = { header: {}, wires: [] };

          data.map((d, i) => {
            if (i === 0)
              svgWireData.header = {
                fromDeviceDescription: d.fromDeviceDescription,
                fromDevice: d.fromDevice,
                fromEnclosure: d.fromEnclosure,
                fromEnclosureDescription: d.fromEnclosureDescription,
                cableTag: d.cableTag,
                toDevice: d.toDevice,
                toDeviceDescription: d.toDeviceDescription,
                toEnclosure: d.toEnclosure,
                toEnclosureDescription: d.toEnclosureDescription,
              };

            svgWireData.wires.push({
              fromConnectorName: d.fromConnectorName,
              toConnectorName: d.toConnectorName,
              wireLabel: d.wireLabel,
            });
          });

          const linesCount = svgWireData.wires.length + 1;

          const svgSize = { height: linesCount * 20 + 50, width: 1440 };
          const startPosition = {
            lineStartX: svgSize.width / 24,
            lineStartY: 45,
            headerStartY: svgSize.height > 300 ? 220 : svgSize.height <= 110 ? 10 : 50,
          };

          let posX = startPosition.lineStartX;
          let posY = startPosition.lineStartY;

          const lineSvg = "";
          const headerSvg = createStartSvgTag(svgSize);

          let htmlTag = createStartSvgTag(svgSize);
          htmlTag = `${htmlTag} ${createSvgHeader(startPosition.headerStartY, svgWireData)}`;

          svgWireData.wires.map((d) => {
            lineSvg = createSvgLine(posY, posX, d);
            posY += 20;
          });

          htmlTag = `${htmlTag} ${headerSvg}`;
          htmlTag = `${htmlTag} ${lineSvg}`;
          htmlTag = `${htmlTag} ${createEndSvgTag()}`;

          svgToRender.push(<SVG src={htmlTag} />);
        }

        setWireTerminations(svgToRender);
        setLoading(false);
      })();
    }
  }, [wireDiagramPageData, isEditing]);

  // useEffect(() => {
  //   if (isEditing) document.getElementById("FromConnectorContainer").contentEditable = "true";
  // }, [isEditing]);
  console.log("%c Line:233 🥑 isEditing", "color:#ed9ec7", isEditing);

  return (
    <Page className={container()}>
      <TopBar
        actionLeft={<ArrowBackIcon onClick={() => pageNavigator(backButtonURL, "backward")} />}
        title="Wire Diagrams"
      />
      <div className="header">
        <div className="row">
          <Button onClick={() => setIsEditing(!isEditing)} style={{ flexBasis: "30%", flexShrink: 0, width: "100%" }}>
            Edit
          </Button>
        </div>
      </div>
      <ScrollView className={container()}>
        {loading ? (
          <InlineSpinner style={{ marginTop: 20 }} />
        ) : (
          <div style={{ padding: "1rem 1rem" }}>
            {wireTerminations}
            {/* <svg height="150" width="1440">
              <foreignObject width="400" height="400">
                <body xmlns="http://www.w3.org/1999/xhtml">
                  <input type="text" id="FromConnectorContainer" />
                </body>
              </foreignObject>
            </svg> */}
          </div>
        )}
      </ScrollView>
    </Page>
  );
};

const container = () => css`
  .header {
    background-color: ${colors.white};
    padding: 1rem;

    .punch-count-container {
      flex-basis: 50%;
      padding: 0 0.5rem;
      display: flex;
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.85rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      .dropdown {
        flex-grow: 1;
        margin: 0 0.35rem;
        flex-basis: 25%;

        &:first-of-type {
          margin-left: 0;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  button svg {
    display: inline-block;
    margin-bottom: -0.35rem;
  }
`;

export default memo(WireDiagramOverview);
