import React, { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "react-query";
import { css } from "emotion";
import { Checkbox, notification } from "antd";
import * as yup from "yup";

import { hideModalPage } from "../../../../actions/uiActions";

import useOverviewData from "./hooks/useOverviewData";

import Button from "../../../ui/Button";
import DropDown from "../../../ui/DropDown";
import FormItem from "../../../ui/FormItem";
import InlineSpinner from "../../../ui/InlineSpinner";
import ScrollView from "../../../ui/ScrollView";
import TextInput from "../../../ui/TextInput";

import getErrorMessage from "../../../../utilities/get-error-message";
import req from "../../../../utilities/request-utility";

import colors from "../../../../style/colors";

const ManageEquipmentsModal = ({ editMode, getIsDataUpdated = null, initialForm, showDeletedRecords }) => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const [formLoading, setFormloading] = useState(false);
  const [form, setForm] = useState({
    system: initialForm?.systemId || "",
    projectArea: initialForm?.areaId || "",
    cableNo: initialForm?.cableNo || "",
    partNo: initialForm?.partNo || "",
    jobpackagePull: initialForm?.jobPackagePullId || "",
    cable: initialForm?.cableId || "",
    trayType: initialForm?.trayTypeId || "",
    length: initialForm?.length || "",
    actualLength: initialForm?.actualLength || "",
    pulledFrom: initialForm?.pulledFrom || false,
    pulledTo: initialForm?.pulledTo || false,
    isPulled: initialForm?.isPulled || false,
    pullOffshore: initialForm?.pullOffshore || "",
    jobpackageTerm: initialForm?.jobPackageTerminationId || "",
    jobpackageTerm2: initialForm?.jobPackageTerminationId2 || "",
    fromTagno: initialForm?.fromTagId || "",
    toTagno: initialForm?.toTagId || "",
    mountedFrom: initialForm?.mountedFrom || false,
    mountedTo: initialForm?.mountedTo || false,
    termFromOffshore: initialForm?.termFromOffshore || "",
    termToOffshore: initialForm?.termToOffshore || "",
    remark: initialForm?.remark || "",
    mccrOk: initialForm?.mccrOk || false,
    mcOffshore: initialForm?.mcOffshore || "",
    jobpackageMCCR: initialForm?.jobPackageMccrId || "",
    mcPackage: initialForm?.mcPackage || "",
    maintenancePlant: initialForm?.maintenancePlantId || "",
    mctNo: initialForm?.mctNo || "",
    cableTrayNo: initialForm?.cableTrayNo || "",
    redundantCableTag: initialForm?.redundantCableTag || "",
  });

  const validationSchema = yup.object().shape({
    system: yup.string().required("System is required"),
    projectArea: yup.string().required("Area is required"),
    cableNo: yup.string().required("Cable No is required"),
    pulledFrom: yup.boolean().required("Pulled From is required"),
    pulledTo: yup.boolean().required("Pulled To is required"),
    isPulled: yup.boolean().required("Is Pulled is required"),
    mountedFrom: yup.boolean().required("Mounted From is required"),
    mountedTo: yup.boolean().required("Mounted To is required"),
  });

  const { metaData } = useOverviewData({ query: { isDeleted: showDeletedRecords } });

  const handleChange = useCallback(
    (e) => {
      if (e.target.type === "checkbox") return setForm({ ...form, [e.target.name]: e.target.checked });

      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    },
    [form]
  );

  const isFormValid = useCallback(async () => {
    try {
      await validationSchema.validate(form);

      return { status: true };
    } catch (error) {
      return {
        status: false,
        message: error.message,
      };
    }
  }, [form, validationSchema]);

  const saveCable = useCallback(async () => {
    const isFormValidData = await isFormValid();

    if (!isFormValidData.status)
      return notification.error({ duration: 7, message: "FAILED", description: isFormValidData.message });

    setFormloading(true);

    const handleOk = () => {
      dispatch(hideModalPage());

      queryClient.refetchQueries(["cablesPage", { isDeleted: showDeletedRecords }]);

      if (getIsDataUpdated) getIsDataUpdated(true);

      setFormloading(false);

      notification.success({ duration: 7, message: "SUCCESS", description: "You have successfully updated a cable" });
    };

    const handleError = (err) => {
      const error = getErrorMessage(err);

      notification.error({ duration: 7, message: "FAILED", description: error });

      setFormloading(false);
    };

    if (editMode) req().put(`semcompletion/cables/${initialForm.id}`, form).then(handleOk).catch(handleError);
    else req().post(`semcompletion/cables`, form).then(handleOk).catch(handleError);
  }, [form, editMode, getIsDataUpdated]);

  return (
    <ScrollView className={container()}>
      <div className="row">
        <div className="col-50">
          <FormItem label="System" required={true}>
            <DropDown
              name="system"
              onChange={handleChange}
              options={
                metaData.data.systems && metaData.data.systems.length !== 0
                  ? metaData.data.systems.map((d) => ({ label: `${d.systemNumber} - ${d.name}`, value: d.id }))
                  : []
              }
              value={form.system}
            />
          </FormItem>
        </div>
        <div className="col-50">
          <FormItem label="Area" required={true}>
            <DropDown
              name="projectArea"
              onChange={handleChange}
              options={
                metaData.data.projectAreas && metaData.data.projectAreas.length !== 0
                  ? metaData.data.projectAreas.map((d) => ({ label: `${d.code} - ${d.name}`, value: d.id }))
                  : []
              }
              value={form.projectArea}
            />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-50">
          <FormItem label="JobPackage Pull">
            <DropDown
              name="jobpackagePull"
              onChange={handleChange}
              options={
                metaData.data.jobPackages && metaData.data.jobPackages.length !== 0
                  ? metaData.data.jobPackages.map((d) => ({ label: d.title, value: d.id }))
                  : []
              }
              value={form.jobpackagePull}
            />
          </FormItem>
        </div>
        <div className="col-50">
          <FormItem label="Cable">
            <DropDown
              name="cable"
              onChange={handleChange}
              options={
                metaData.data.projectManualCableTypes && metaData.data.projectManualCableTypes.length !== 0
                  ? metaData.data.projectManualCableTypes.map((d) => ({ label: `${d.code} - ${d.type}`, value: d.id }))
                  : []
              }
              value={form.cable}
            />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-50">
          <FormItem label="Tray">
            <DropDown
              name="trayType"
              onChange={handleChange}
              options={
                metaData.data.trayTypes && metaData.data.trayTypes.length !== 0
                  ? metaData.data.trayTypes.map((d) => ({ label: d.description, value: d.id }))
                  : []
              }
              value={form.trayType}
            />
          </FormItem>
        </div>
        <div className="col-50">
          <FormItem label="Jobpackage MCCR">
            <DropDown
              name="jobpackageMCCR"
              onChange={handleChange}
              options={
                metaData.data.jobPackages && metaData.data.jobPackages.length !== 0
                  ? metaData.data.jobPackages.map((d) => ({ label: d.title, value: d.id }))
                  : []
              }
              value={form.jobpackageMCCR}
            />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-50">
          <FormItem label="Jobpackage Term">
            <DropDown
              name="jobpackageTerm"
              onChange={handleChange}
              options={
                metaData.data.jobPackages && metaData.data.jobPackages.length !== 0
                  ? metaData.data.jobPackages.map((d) => ({ label: d.title, value: d.id }))
                  : []
              }
              value={form.jobpackageTerm}
            />
          </FormItem>
        </div>
        <div className="col-50">
          <FormItem label="Jobpackage Term 2">
            <DropDown
              name="jobpackageTerm2"
              onChange={handleChange}
              options={
                metaData.data.jobPackages && metaData.data.jobPackages.length !== 0
                  ? metaData.data.jobPackages.map((d) => ({ label: d.title, value: d.id }))
                  : []
              }
              value={form.jobpackageTerm2}
            />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-50">
          <FormItem label="From Tagno">
            <DropDown
              name="fromTagno"
              onChange={handleChange}
              options={
                metaData.data.projectEquipments && metaData.data.projectEquipments.length !== 0
                  ? metaData.data.projectEquipments.map((d) => ({ label: d.tagNo, value: d.id }))
                  : []
              }
              value={form.fromTagno}
            />
          </FormItem>
        </div>
        <div className="col-50">
          <FormItem label="To Tagno">
            <DropDown
              name="toTagno"
              onChange={handleChange}
              options={
                metaData.data.projectEquipments && metaData.data.projectEquipments.length !== 0
                  ? metaData.data.projectEquipments.map((d) => ({ label: d.tagNo, value: d.id }))
                  : []
              }
              value={form.toTagno}
            />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-50">
          <FormItem label="MC Offshore">
            <DropDown
              name="mcOffshore"
              onChange={handleChange}
              options={[
                { label: "Offshore scope", value: "Offshore scope" },
                { label: "Vendor", value: "Vendor" },
              ]}
              value={form.mcOffshore}
            />
          </FormItem>
        </div>
        <div className="col-50">
          <FormItem label="Maintenance Plant">
            <DropDown
              name="maintenancePlant"
              onChange={handleChange}
              options={
                metaData.data.maintenancePlants && metaData.data.maintenancePlants.length !== 0
                  ? metaData.data.maintenancePlants.map((d) => ({ label: `${d.code} - ${d.name}`, value: d.id }))
                  : []
              }
              value={form.maintenancePlant}
            />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-25">
          <Checkbox checked={form.pulledFrom} onChange={handleChange} name="pulledFrom">
            Pulled From
          </Checkbox>
        </div>
        <div className="col-25">
          <Checkbox checked={form.pulledTo} onChange={handleChange} name="pulledTo">
            Pulled To
          </Checkbox>
        </div>
        <div className="col-25">
          <Checkbox checked={form.isPulled} onChange={handleChange} name="isPulled">
            Is Pulled
          </Checkbox>
        </div>
        <div className="col-25">
          <Checkbox checked={form.pullOffshore} onChange={handleChange} name="pullOffshore">
            Pull Offshore
          </Checkbox>
        </div>
      </div>
      <div className="row">
        <div className="col-25">
          <Checkbox checked={form.mountedTo} onChange={handleChange} name="mountedTo">
            Mounted To
          </Checkbox>
        </div>
        <div className="col-25">
          <Checkbox checked={form.mountedFrom} onChange={handleChange} name="mountedFrom">
            Mounted From
          </Checkbox>
        </div>
        <div className="col-25">
          <Checkbox checked={form.termFromOffshore} onChange={handleChange} name="termFromOffshore">
            Term From Offshore
          </Checkbox>
        </div>
        <div className="col-25">
          <Checkbox checked={form.termToOffshore} onChange={handleChange} name="termToOffshore">
            Term To Offshore
          </Checkbox>
        </div>
        {/* <div className="col-25">
          <Checkbox checked={form.mccrOk} onChange={handleChange} name="mccrOk">
            MCCR Ok
          </Checkbox>
        </div> */}
      </div>
      <div className="row">
        <div className="col-100">
          <FormItem label="Cable No" required={true}>
            <TextInput name="cableNo" onChange={handleChange} value={form.cableNo} />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <FormItem label="Part No">
            <TextInput name="partNo" onChange={handleChange} value={form.partNo} />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <FormItem label="Length">
            <TextInput name="length" onChange={handleChange} type="number" value={form.length} />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <FormItem label="Actual Length">
            <TextInput name="actualLength" onChange={handleChange} type="number" value={form.actualLength} />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <FormItem label="Remark">
            <TextInput name="remark" onChange={handleChange} value={form.remark} />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <FormItem label="MC Package">
            <TextInput name="mcPackage" onChange={handleChange} value={form.mcPackage} />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <FormItem label="MCT No">
            <TextInput name="mctNo" onChange={handleChange} value={form.mctNo} />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <FormItem label="Cable Tray No">
            <TextInput name="cableTrayNo" onChange={handleChange} value={form.cableTrayNo} />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <FormItem label="Redundant Cable Tag">
            <TextInput name="redundantCableTag" onChange={handleChange} value={form.redundantCableTag} />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <Button disabled={formLoading} onClick={saveCable}>
            {formLoading && <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />}{" "}
            {editMode ? "Update" : "Create"} Cable
          </Button>
        </div>
      </div>
    </ScrollView>
  );
};

const container = () => css`
  background-color: ${colors.white};
  padding: 1rem 0;

  label {
    font-weight: 700;
    margin-bottom: 0.35rem;
  }

  label.input-label {
    display: block;
  }

  .row {
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto 1.25rem auto;

    .col-25 {
      flex-basis: 25%;
    }

    .col-50 {
      flex-basis: 49%;
    }

    .col-100 {
      flex-basis: 100%;
    }

    @media screen and (max-width: 650px) {
      flex-wrap: wrap;

      .col-50 {
        flex-basis: 100%;

        &:nth-child(odd) {
          margin-bottom: 1.25rem;
        }
      }
    }
  }
`;

export default memo(ManageEquipmentsModal);
