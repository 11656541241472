import React from "react";
import Page from "./Page";
import InlineSpinner from "./InlineSpinner";

function LoadingPage(props) {
  return (
    <Page style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <InlineSpinner style={{ marginTop: "2rem" }} />
    </Page>
  );
}

export default LoadingPage;
