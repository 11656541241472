import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, notification, Select } from "antd";
import { css } from "emotion";
import { InformationOutlineIcon } from "mdi-react";
import { keys, omit, size, uniq } from "lodash";
import { useQuery } from "react-query";
import moment from "moment";

// styling
import colors from "../../../../style/colors";

// utlities
import req from "../../../../utilities/request-utility";
import getErrorMessage from "../../../../utilities/get-error-message";

// actions
import { hideModalPage, showDialog } from "../../../../actions/uiActions";

// ui-Components
import TextInput from "../../../ui/TextInput";
import Button from "../../../ui/Button";
import ScrollView from "../../../ui/ScrollView";
import Toggle from "../../../ui/Toggle";

import FormItem from "../../../ui/FormItem";
import InlineSpinner from "../../../ui/InlineSpinner";

// COMPONENTS
import MenuAccess from "./MenuAccess";
import Projects from "./Projects";
import PunchList from "./PunchList";

function Modal({ form = null, refreshData }) {
  const dispatch = useDispatch();

  const { allPages } = useSelector(({ pages }) => ({ allPages: pages.allPages }));
  const { isFetching: usersFetching = false, data: { data: allUsers = [] } = {} } = useQuery(["Users"], () =>
    req()(`/users?all=true`)
  );
  const companyList = uniq(allUsers.map((user) => user.company).filter((d) => d)).map((d) => ({ label: d, value: d }));

  const withForm = form?.id ? true : false;

  const [formData, setFormData] = useState(
    form
      ? { ...form }
      : {
          active: 0,
          name: "",
          email: "",
          phone: "",
          pin: "",
          company: "",
          menuAccess: [],
          projects: [],
          punchList: [],
          punchListActions: [],
        }
  );
  const [formLoading, setFormloading] = useState(false);

  const isFormValid = (formData) => {
    if (!formData.name) return { status: false, message: "Make sure to fill out name and then try again" };
    if (!formData.phone) return { status: false, message: "Make sure to fill out phone and then try again" };
    if (formData.email !== "" && !formData.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g))
      return { status: false, message: "Invalid email format" };
    if (formData.email !== "" && formData.email.match(/\s/g))
      return { status: false, message: "Email cannot contain spaces" };
    if (formData.pin !== "" && formData.pin.match(/\s/g)) return { status: false, message: "PIN cannot contain spaces" };
    if (!withForm && !formData.pin) return { status: false, message: "Make sure to fill out PIN and then try again" };
    if (!withForm && formData.pin.length < 4)
      return { status: false, message: "Make sure to fill out PIN and then try again" };
    return { status: true };
  };

  const getFormChanges = () => {
    let changes = {};

    keys(formData)
      .filter((key) => !["active", "id", "applicationId"].includes(key))
      .map((key) => {
        const newForm = omit(form, ["active", "id", "applicationId"]);

        const newFormData = omit(formData, ["active", "id", "applicationId"]);

        if (typeof newForm[key] === "object") return;

        if (newForm[key] !== newFormData[key]) changes[key] = newFormData[key];
      });

    return size(changes) > 0 ? true : false;
  };

  const getActiveChange = () => form.active !== formData.active;

  const onMenuAccessUpdate = (pages) => {
    if (withForm) return;
    setFormData({ ...formData, menuAccess: pages });
  };

  const onPunchListUpdate = (punchList) => {
    if (withForm) return;
    setFormData({ ...formData, punchList });
  };

  const onPunchListActionsUpdate = (punchListActions) => {
    if (withForm) return;
    setFormData((prevFormData) => {
      return { ...prevFormData, punchListActions };
    });
  };

  const onProjectsUpdate = (projects) => {
    if (withForm) return;
    setFormData({ ...formData, projects });
  };

  const save = async (hasFormChanges = false, hasActiveChange = false) => {
    const isFormValidData = isFormValid(formData);
    if (!isFormValidData.status)
      return notification.error({ duration: 7, message: "FAILED", description: isFormValidData.message });

    try {
      setFormloading(true);

      if (withForm) {
        if (hasFormChanges && !hasActiveChange) await req().put(`/users/${formData.id}`, formData);

        if (!hasFormChanges && hasActiveChange) await req().delete(`/users/${form.id}`);

        if (hasFormChanges && hasActiveChange) {
          await req().put(`/users/${formData.id}`, formData);
          await req().delete(`/users/${form.id}`);
        }
      }

      if (!withForm) await req().post(`/users`, formData);

      const withEmail = formData.email ? true : false;
      const emailNotif = withEmail ? " An email will be sent to the user shortly." : "";

      dispatch(hideModalPage());
      if (refreshData) refreshData();
      setFormloading(false);

      notification.success({
        duration: 7,
        message: "SUCCESS",
        description: withForm
          ? "You have successfully updated a user." + emailNotif
          : "You have successfully added a user." + emailNotif,
      });
    } catch (error) {
      notification.error({ duration: 7, message: "FAILED", description: getErrorMessage(error) });
      setFormloading(false);
    }
  };

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const getPagesIdAndStatus = (pages) => {
    let pageIds = [];
    pages.map((page) => {
      pageIds.push({ id: page.id, active: page.active });

      if (page.pages.length > 0)
        page.pages = page.pages.map((childPage) => {
          pageIds.push({ id: childPage.id, active: childPage.active });
          return childPage;
        });

      return page;
    });

    return pageIds;
  };

  useEffect(() => {
    if (allPages.length !== 0) setFormData({ ...formData, menuAccess: getPagesIdAndStatus(allPages) });
  }, [allPages]);

  return (
    <ScrollView className={container()}>
      <div className="row">
        <div className="col-100">
          <Collapse accordion defaultActiveKey={["section-user-information"]}>
            <Collapse.Panel header="User Information" key="section-user-information">
              <div className="row">
                <div className="col-100">
                  <FormItem label="Name" required={true}>
                    <TextInput placeholder="Enter Name" name="name" onChange={onChange} value={formData["name"]} />
                  </FormItem>
                </div>
              </div>
              <div className="row">
                <div className="col-100">
                  <FormItem label="Email">
                    <TextInput
                      placeholder="Enter Email"
                      name="email"
                      onChange={onChange}
                      type="email"
                      value={formData["email"]}
                    />
                  </FormItem>
                </div>
              </div>
              <div className="row">
                <div className="col-50">
                  <FormItem label="Phone" required={true}>
                    <TextInput
                      placeholder="Enter Phone"
                      name="phone"
                      onChange={onChange}
                      regExFormat="^[+]?[0-9]+$"
                      value={formData["phone"]}
                    />
                  </FormItem>
                </div>
                <div className="col-50">
                  <FormItem label="4 Digit PIN" required={true}>
                    <TextInput
                      max="9999"
                      maxLength="4"
                      minLength="1"
                      placeholder="Enter 4 Digit PIN"
                      name="pin"
                      onChange={(e) => {
                        const { maxLength, name, value } = e.target;
                        if (value.length > maxLength)
                          return onChange({ target: { name, value: value.slice(0, maxLength) } });
                        return onChange(e);
                      }}
                      regExFormat="^[0-9]+$"
                      type="password"
                      value={formData["pin"]}
                    />
                  </FormItem>
                </div>
              </div>
              <div className="row">
                <div className="col-50">
                  <FormItem label="Company">
                    <Select
                      mode="tags"
                      style={{
                        width: "100%",
                      }}
                      loading={usersFetching}
                      placeholder="Company"
                      onChange={(e) => {
                        if (e.length === 0 || e.length === 1) {
                          onChange({ target: { name: "company", value: e.length !== 0 ? e[0] : "" } });
                        }
                      }}
                      options={companyList}
                      size="large"
                      value={formData["company"] ? [formData["company"]] : []}
                    />
                  </FormItem>
                </div>
                {withForm && (
                  <div className="col-50">
                    <FormItem label="Active">
                      <Toggle
                        on={formData["active"]}
                        onClick={() => onChange({ target: { name: "active", value: formData.active === 0 ? 1 : 0 } })}
                      />
                    </FormItem>
                  </div>
                )}
              </div>
            </Collapse.Panel>

            {!withForm && (
              <>
                <Collapse.Panel header="Menu Access" key="section-menu-access">
                  <MenuAccess onUpdate={onMenuAccessUpdate} userId={form?.id} />
                </Collapse.Panel>
                <Collapse.Panel header="Punch Lists" key="section-punch-list">
                  <PunchList
                    onUpdate={onPunchListUpdate}
                    onUpdatePunchListActions={onPunchListActionsUpdate}
                    userId={form?.id}
                  />
                </Collapse.Panel>
                <Collapse.Panel header="Projects" key="section-projects">
                  <Projects onUpdate={onProjectsUpdate} userId={form?.id} />
                </Collapse.Panel>
              </>
            )}
          </Collapse>
        </div>
      </div>

      <div className="row">
        <div className="col-100">
          <Button
            disabled={formLoading || !isFormValid(formData).status}
            onClick={() => {
              if (!withForm) return save();

              const hasFormChanges = getFormChanges();

              const hasActiveChange = getActiveChange();

              if (!hasFormChanges && !hasActiveChange)
                return notification.error({ duration: 7, message: "FAILED", description: "No changes detected" });

              if ((!hasFormChanges && hasActiveChange) || (hasFormChanges && hasActiveChange))
                return dispatch(
                  showDialog({
                    title: `You are about to ${form.active ? "deactivate" : "activate"} a user`,
                    content: "Do you wish to proceed?",
                    icon: <InformationOutlineIcon />,
                    styleType: "neutral",
                    primaryActionTitle: "Yes",
                    primaryAction: () => save(hasFormChanges, hasActiveChange),
                  })
                );

              if (hasFormChanges && !hasActiveChange) return save(hasFormChanges, hasActiveChange);
            }}
          >
            {formLoading && <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />}{" "}
            Save
          </Button>
        </div>
      </div>

      <div className="row">
        <div className="col-100" style={{ textAlign: "right" }}>
          {form?.createdBy && (
            <p>
              <b>User created by</b>: {form.createdBy}, Date: {moment(form.createdAt).format("YYYY-MM-DD HH:mm")}
            </p>
          )}
          {form?.modifiedBy && (
            <p>
              <b>User last modified by</b>: {form.modifiedBy}, Date: {moment(form.modifiedAt).format("YYYY-MM-DD HH:mm")}
            </p>
          )}
          {form?.lastActive && (
            <p>
              <b>User last active date</b>: {moment(form.lastActive).format("YYYY-MM-DD HH:mm")}
            </p>
          )}
        </div>
      </div>
    </ScrollView>
  );
}

const container = () => css`
  background-color: ${colors.white};
  padding: 1rem 0;

  label {
    font-weight: 700;
    margin-bottom: 0.35rem;
    display: block;
  }

  .row {
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto 1.25rem auto;

    .col-50 {
      flex-basis: 49%;
    }

    .col-100 {
      flex-basis: 100%;
    }

    @media screen and (max-width: 650px) {
      flex-wrap: wrap;

      .col-50 {
        flex-basis: 100%;

        &:nth-child(odd) {
          margin-bottom: 1.25rem;
        }
      }
    }
  }
`;

export default Modal;
