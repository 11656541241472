import { SC_SET_SELECTED_PROJECT, SC_SET_SELECTED_PROJECT_AREA } from "../actions/actionTypes";

const initialState = {
  selectedProject: {
    id: "",
    number: "",
    description: "",
    projectType: "",
  },
  selectedProjectArea: {
    id: "",
    code: "",
    name: "",
    projectId: "",
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SC_SET_SELECTED_PROJECT:
      return {
        ...state,
        selectedProject: {
          ...state.selectedProject,
          id: action.payload.id,
          number: action.payload.number,
          description: action.payload.description,
          projectType: action.payload.projectType,
        },
      };
    case SC_SET_SELECTED_PROJECT_AREA:
      return {
        ...state,
        selectedProjectArea: {
          ...state.selectedProjectArea,
          id: action.payload.id,
          code: action.payload.code,
          name: action.payload.name,
          projectId: action.payload.projectId,
        },
      };

    default:
      return state;
  }
}
