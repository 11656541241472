import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, notification } from "antd";
import { css } from "emotion";
import { keyBy } from "lodash";
import { useQuery } from "react-query";
import { CellphoneIcon, CircleIcon, DomainIcon, EmailIcon } from "mdi-react";

// styling
import colors from "../../../../style/colors";

// utlities
import req from "../../../../utilities/request-utility";
import getErrorMessage from "../../../../utilities/get-error-message";

// actions
import { hideModalPage } from "../../../../actions/uiActions";

// ui-Components
import Button from "../../../ui/Button";
import ScrollView from "../../../ui/ScrollView";
import InlineSpinner from "../../../ui/InlineSpinner";
import ListItem from "../../../ui/ListItem";

// COMPONENTS
import MenuAccess from "./MenuAccess";
import PunchList from "./PunchList";
import Projects from "./Projects";

function PermissionsModal({ form = null, refreshData }) {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({ menuAccess: [], projects: [], punchList: [], punchListActions: [] });
  const [formLoading, setFormloading] = useState(false);

  const { allPages, loading: pagesLoading } = useSelector(({ pages }) => ({
    allPages: pages.allPages,
    loading: pages.loading,
  }));

  const {
    data: { data: userPages = [] } = {},
    refetch: refetchPages,
    isLoading: userPagesLoading,
  } = useQuery(["UserPages", form?.id], () => req()(`/pages?user=${form?.id}`));

  const onMenuAccessUpdate = (pages) => setFormData((d) => ({ ...d, menuAccess: pages }));
  const onPunchListUpdate = (punchList) => setFormData((d) => ({ ...d, punchList }));
  const onPunchListActionsUpdate = (punchListActions) => setFormData((d) => ({ ...d, punchListActions }));
  const onProjectsUpdate = (projects) => setFormData((d) => ({ ...d, projects }));

  const save = async () => {
    try {
      setFormloading(true);

      await req().put(`/users/${form.id}/permissions`, formData);

      dispatch(hideModalPage());
      refetchPages();
      if (refreshData) refreshData();
      setFormloading(false);

      notification.success({
        duration: 7,
        message: "SUCCESS",
        description: "You have successfully updated user permissions.",
      });
    } catch (error) {
      notification.error({ duration: 7, message: "FAILED", description: getErrorMessage(error) });
      setFormloading(false);
    }
  };

  const getPagesIdAndStatus = (pages) => {
    let pageIds = [];
    pages.map((page) => {
      pageIds.push({ id: page.id, active: page.active });

      if (page.pages.length > 0)
        page.pages = page.pages.map((childPage) => {
          pageIds.push({ id: childPage.id, active: childPage.active });
          return childPage;
        });

      return page;
    });

    return pageIds;
  };

  // get assigned pages to user and adjust pages
  useEffect(() => {
    if (allPages.length > 0 && userPages.length > 0) {
      const assignedPages = getPagesIdAndStatus(userPages),
        assignedPagesObj = keyBy(assignedPages, "id");

      const newPages = allPages.map((page) => {
        page.active = assignedPagesObj[page.id] ? 1 : 0;

        if (page.pages.length > 0) {
          page.pages = page.pages.map((childPage) => {
            return { ...childPage, active: assignedPagesObj[childPage.id] ? assignedPagesObj[childPage.id].active : 0 };
          });
        }

        return page;
      });

      setFormData((d) => ({ ...d, menuAccess: newPages }));
    }
  }, [form.id, allPages, userPages]);

  return (
    <ScrollView className={container()}>
      <div className="row">
        <div className="col-100">
          <ListItem
            singleView={true}
            style={form.active ? { marginBottom: 3 } : { marginBottom: 3, opacity: 0.5 }}
            title={
              <span className="boldTitle">
                <CircleIcon className="circle" color={form.active ? "green" : "red"} size={12} />
                {form.name}
              </span>
            }
            subContent={
              <ul>
                <li>
                  <EmailIcon size={10} />
                  {form.email ? form.email : "N/A"}
                </li>
                <li>
                  <CellphoneIcon size={10} />
                  {form.phone}
                </li>
                <li>
                  <DomainIcon size={10} />
                  {form.company ? form.company : "N/A"}
                </li>
              </ul>
            }
            key={form.id}
            index={form.id}
            length={1}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <Collapse accordion defaultActiveKey={["section-menu-access"]}>
            <Collapse.Panel header="Menu Access" key="section-menu-access">
              {pagesLoading || userPagesLoading ? (
                <InlineSpinner />
              ) : (
                <MenuAccess onUpdate={onMenuAccessUpdate} userId={form?.id} />
              )}
            </Collapse.Panel>
            <Collapse.Panel header="Punch Lists" key="section-punch-list">
              <PunchList
                onUpdate={onPunchListUpdate}
                onUpdatePunchListActions={onPunchListActionsUpdate}
                userId={form?.id}
              />
            </Collapse.Panel>
            <Collapse.Panel header="Projects" key="section-projects">
              <Projects onUpdate={onProjectsUpdate} userId={form?.id} />
            </Collapse.Panel>
          </Collapse>
        </div>
      </div>

      {!pagesLoading && !userPagesLoading && (
        <div className="row">
          <div className="col-100">
            <Button disabled={formLoading} onClick={save}>
              {formLoading && (
                <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />
              )}{" "}
              Save
            </Button>
          </div>
        </div>
      )}
    </ScrollView>
  );
}

const container = () => css`
  background-color: ${colors.white};
  padding: 1rem 0;

  label {
    font-weight: 700;
    margin-bottom: 0.35rem;
    display: block;
  }

  .row {
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto 1.25rem auto;

    .col-50 {
      flex-basis: 49%;
    }

    .col-100 {
      flex-basis: 100%;
    }

    @media screen and (max-width: 650px) {
      flex-wrap: wrap;

      .col-50 {
        flex-basis: 100%;

        &:nth-child(odd) {
          margin-bottom: 1.25rem;
        }
      }
    }
  }

  .circle {
    margin-right: 9px;
  }
`;

export default PermissionsModal;
