import React from "react";
import { connect } from "react-redux";
import { css } from "emotion";
import { SearchIcon, CloseIcon } from "mdi-react";
import colors from "../../style/colors";
import PropTypes from "prop-types";
import InlineSpinner from "./InlineSpinner";

const SearchInput = (props) => {
  const [focused, setFocused] = React.useState(false);

  return (
    <div style={props.style} className={`search-input ${style(props.primaryColor)} ${props.className || ""}`}>
      {props.searching && <InlineSpinner style={{ marginBottom: "-7px", marginLeft: "0.75rem" }} size="20" />}
      <input
        name={props.name}
        disabled={props.disabled}
        style={{ paddingLeft: props.searching ? "40px" : "0.75rem" }}
        type={props.type || "text"}
        onChange={props.onChange}
        onKeyDown={
          props.onEnter
            ? (e) => {
                if (e.key === "Enter") props.onEnter(e);
              }
            : null
        }
        onBlur={() => setFocused(false)}
        onFocus={() => setFocused(true)}
        value={props.value}
        placeholder={props.placeholder || "Type to search..."}
        readOnly={props.readOnly}
      />
      {props.value !== "" && <CloseIcon onClick={props.onClearSearch} className="rightClose" />}
      {props.value === "" && <SearchIcon className="rightIcon" />}
      {(props.value !== "" || focused) && (
        <button
          disabled={props.value === "" ? true : false}
          className={`rightButton ${props.value !== "" ? "blue" : ""}`}
          onClick={props.onSearch}
        >
          <SearchIcon /> Search
        </button>
      )}
    </div>
  );
};

const height = 40;

const style = (primaryColor) => css`
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${colors.midLightGrey};
  border-radius: 3px;
  font-size: 1rem;
  color: ${colors.black};
  height: ${height}px;

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    font-size: 1rem;
    background-color: transparent;
    border: 1px transparent solid;
    border-radius: 3px;
    padding: 0 2rem 0 0.75rem;

    &:focus {
      outline: 0;
      border: 1px ${primaryColor} solid;
    }
  }

  .rightClose {
    position: absolute;
    right: 7rem;
    cursor: pointer;
  }

  .rightIcon {
    position: absolute;
    right: 0.5rem;
  }

  .rightButton {
    position: absolute;
    right: 0.5rem;
    background-color: gray;
    color: white;
    display: flex;
    padding: 1px 10px 1px 8px;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    cursor: not-allowed;
    align-items: center;
  }

  .rightButton.blue {
    background-color: rgb(16, 52, 71);
    cursor: pointer;
  }

  .rightButton svg {
    fill: white;
    height: 20px;
    width: 20px;
  }

  svg {
    fill: ${colors.darkGrey};
  }
`;
SearchInput.propTypes = {
  /** Function which is executed to clear input */
  onClearSearch: PropTypes.func,
  /** Text value in input part of component, this is being used when searching */
  value: PropTypes.string,
  /** Boolean value used to decide if a search has been initiated. usecase could be that you dont want a user to type something while a search is ongoing */
  searching: PropTypes.bool,
  /** Function to be executed when something new has been typed */
  onChange: PropTypes.func,
  /** Placeholder text displayed when nothing has been typed */
  placeholder: PropTypes.string,
  /** Used to set className for styling */
  className: PropTypes.string,
};
const mapStateToProps = (state) => ({
  primaryColor: state.appConfig.primaryColor,
});

export default connect(mapStateToProps)(SearchInput);
