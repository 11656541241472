import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "emotion";
import { ArrowBackIcon } from "mdi-react";
import { isEqual } from "lodash";
import queryString from "query-string";

import AccessCheck from "../../ui/AccessCheck";
import InlineSpinner from "../../ui/InlineSpinner";
import Page from "../../ui/Page";
import ScrollView from "../../ui/ScrollView";
import StatusBox from "../../ui/StatusBox";
import TopBar from "../../ui/TopBar";

import LibraryRenderer from "../LibraryRenderer";

import getPageFromId from "../../../utilities/get-page-from-id";
import linkPathCreator from "../../../utilities/get-link-path-from-page-object";
import pageNavigator from "../../../utilities/page-navigator";

import usePageAndBackButtonURL from "../../../hooks/usePageAndBackButtonURL";
import useOverviewData from "../admin/manageLibrary/hooks/useOverviewData";

import colors from "../../../style/colors";

import { setLibraryFolderQuery, setSelectedFolder, setUrlSuffix } from "../../../actions/libraryActions";

const LibraryOverview = ({ match }) => {
  const { libraryFolderQuery, pages, selectedProject, urlSuffix } = useSelector(
    ({ library, pages, semcompletion }) => ({
      libraryFolderQuery: library.libraryFolderQuery,
      pages: pages.pages,
      selectedProject: semcompletion.selectedProject,
      urlSuffix: library.urlSuffix,
    }),
    isEqual
  );

  const dispatch = useDispatch();

  const { backButtonURL } = usePageAndBackButtonURL({ match });

  const query = queryString.parse(window.location.search);

  const page = getPageFromId(pages, match.params.pageId);

  const { folderFiles, folderOptions, folders, folderUrls } = useOverviewData({ isAdmin: false });

  const {
    data: foldersData,
    error: foldersError,
    fetching: folderFetching,
    loading: foldersLoading,
    refetch: foldersRefetch,
  } = folders;

  const { data: folderOptionsData, refetch: folderOptionsRefetch } = folderOptions;

  const {
    data: folderFilesData,
    error: folderFilesError,
    fetching: folderFilesFetching,
    loading: folderFilesLoading,
  } = folderFiles;

  const {
    data: folderUrlsData,
    error: folderUrlsError,
    fetching: folderUrlsFetching,
    loading: folderUrlsLoading,
  } = folderUrls;

  const handleBackButtonClick = useCallback(() => {
    const slicedUrlSuffix = urlSuffix.replace(/\/$/g, "").split("/").slice(0, -1);

    const filteredSlicedUrlSuffix = slicedUrlSuffix.filter((d) => d !== "");

    const dataId = filteredSlicedUrlSuffix[filteredSlicedUrlSuffix.length - 1];

    const newUrlSuffix = slicedUrlSuffix.join("/");

    dispatch(setUrlSuffix(newUrlSuffix));
    dispatch(
      setSelectedFolder(
        folderOptionsData.find(
          (d) =>
            d.id ===
            newUrlSuffix
              .split("/")
              .filter((d) => d !== "")
              .pop()
        )
      )
    );
    dispatch(setLibraryFolderQuery({ ...libraryFolderQuery, parentFolderId: dataId }));

    pageNavigator(
      urlSuffix === "" ? backButtonURL : linkPathCreator({ ...page, dataId, urlSuffix: newUrlSuffix, withAppName: true }),
      "backward"
    );
  }, [urlSuffix, backButtonURL, JSON.stringify(folderOptionsData)]);

  return (
    <Page className={container()}>
      <TopBar
        actionLeft={<ArrowBackIcon onClick={handleBackButtonClick} />}
        title={`Library (${selectedProject.number} - ${selectedProject.description})`}
      />
      <AccessCheck pageId={match.params.pageId}>
        <ScrollView>
          {(foldersLoading ||
            folderFetching ||
            folderFilesLoading ||
            folderFilesFetching ||
            folderUrlsLoading ||
            folderUrlsFetching) &&
            !foldersError &&
            !folderFilesError &&
            !folderUrlsError && <InlineSpinner style={{ marginTop: "1rem" }} title="Loading Folders..." />}
          {!foldersLoading &&
            !folderFilesLoading &&
            !folderUrlsLoading &&
            (foldersError || folderFilesError || folderUrlsError) && <StatusBox />}
          {!foldersLoading &&
            !folderFetching &&
            !folderFilesLoading &&
            !folderFilesFetching &&
            !folderUrlsLoading &&
            !folderUrlsFetching &&
            !foldersError &&
            !folderFilesError &&
            !folderUrlsError && (
              <div style={{ padding: "1rem 0" }}>
                <LibraryRenderer
                  dataId={query.dataId ? query.dataId : ""}
                  folderFilesData={folderFilesData}
                  folderOptionsRefetch={folderOptionsRefetch}
                  folderRefetch={foldersRefetch}
                  foldersData={foldersData}
                  folderUrlsData={folderUrlsData}
                  isAdmin={false}
                  pageId={match.params.pageId}
                />
              </div>
            )}
        </ScrollView>
      </AccessCheck>
    </Page>
  );
};

const container = () => css`
  .header {
    background-color: ${colors.white};
    padding: 1rem;

    .punch-count-container {
      flex-basis: 50%;
      padding: 0 0.5rem;
      display: flex;
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.85rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      .dropdown {
        flex-grow: 1;
        margin: 0 0.35rem;
        flex-basis: 25%;

        &:first-of-type {
          margin-left: 0;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  button svg {
    display: inline-block;
    margin-bottom: -0.35rem;
  }
`;

export default memo(LibraryOverview);
