export default (url = "") => {
  const [basePath] = url.split("?");

  const basePathArray = basePath.split("/");
  const fileName = basePathArray.pop();

  const extension = fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) || fileName;

  let baseFileName = fileName.split(".");
  baseFileName.pop();
  baseFileName = baseFileName.join(".");

  const fileNameSplitByUnderscore = baseFileName.split("__");
  const timeStamp = fileNameSplitByUnderscore.pop();
  const isTimeStamp = !isNaN(parseInt(timeStamp));

  return {
    displayFileName: isTimeStamp ? `${decodeURIComponent(fileNameSplitByUnderscore.join("__"))}.${extension}` : fileName,
    fileName,
    extension,
  };
};
