// Libs
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { hideDialog } from "../../actions/uiActions";
import { css } from "emotion";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import { durations } from "../../config/animations";
import breakpoints from "../../config/breakpoints";

// Utilities
import styleTypeNormalizer from "../../utilities/style-type-normalizer";

// Styles
import colors from "../../style/colors";

// Icons
import { CloseIcon } from "mdi-react";
import Overlay from "./Overlay";

/**
 * Component implemented through redux. Import showDialog/hideDialog from ./actions to use this.
 *
 * Is used as a confirmation checkpoint so a flow that uses it would be:
 * ```
 *  1. User clicks delete button
 *  2. Dialog component shows, asking the user if they are sure they want to delete the element
 *  3. User clicks PrimaryAction to confirm or secondary action to abort the current action.
 * ```
 * Dialog is different from contextmenu in that Dialog only allows 2 options, PrimaryAction or SecondaryAction
 * ```
 * this.props.showDialog({
                              title: "Obs!",
                              content: "Do you want to  mark task as done? ",
                              icon: <WarningDecagramIcon />,

                              styleType: "neutral",
                              primaryActionTitle: "Yes,  mark task as done, and close page",
                              secondaryActionTitle: "No, don't mark as done",
                              primaryAction: () => {
                                //All questions have to be answered, before TestSheet can be submitted
                                this.props.updateModalPage({
                                  actionRight: (
                                    <InlineSpinner color={colors.white} size="20" style={{ marginTop: "0.30rem" }} />
                                  )
                                });
                                this.props.hideDialog();
                                this.putTestSheet(task.id);

                                // this.props.hideModalPage();
                              },
                              secondaryAction: () => {
                                //What you want the dialog main action to be
                             
                                this.props.hideDialog();
                              }
                            });
 * ```
 * The example above is an example you can copy paste into your code, and use. with the required import, and dispatchToProps.
 * ```
 *  Title: headline of the dialog
 *  Content: textblock used to desribe the action about to be taken
 *  Icon: svg icon for headline
 *  styletype: OK/Error/Neutral - Sets the color scheme
 *  PrimaryActionTitle: name on primaryAction
 *  PrimaryAction: function to be executed when primaryAction is clicked
 *  SecondaryActionTitle/SecondaryAction - Same as above, just for SecondaryAction
 * ```
 */
const Dialog = (props) => {
  let {
    active,
    actions = [], //title, action
    styleType,
    icon,
    title,
    content,
    primaryActionTitle,
    secondaryActionTitle,
    tertiaryActionTitle,
    primaryAction,
    secondaryAction,
    tertiaryAction,
    disableSecondaryAction,
    disableTertiaryAction = true,
  } = props.dialog;
  let { lang } = props;

  return (
    <div className={componentStyles(props)}>
      <Overlay active={active} onClick={props.hideDialog} />

      <CSSTransition in={active} timeout={300} mountOnEnter={true} unmountOnExit={true} classNames="dialog">
        <div
          className={`dialog ${styleTypeNormalizer(styleType)}`}
          data-test-id="dialog"
          // Prevents the outer hideDialog to fire when clicking inside the dialog
          onClick={(e) => e.stopPropagation()}
        >
          {/* Header */}
          <h2 className="header">
            <span>
              {icon}
              {title}
            </span>
          </h2>

          {/* Content */}
          <p className="content">{content}</p>

          {/* Actions */}
          {actions.length !== 0 &&
            actions.map((d) => {
              const { disabled = false } = d;

              return (
                <div
                  className={`action ${disabled ? "disabled" : "primary"}`}
                  data-test-id="dialog__primary"
                  key={d.title}
                  onClick={disabled ? null : d.action}
                >
                  {d.title}
                </div>
              );
            })}

          {primaryActionTitle && (
            <div
              className="action primary"
              data-test-id="dialog__primary"
              onClick={() => {
                primaryAction();
                props.hideDialog();
              }}
            >
              {primaryActionTitle}
            </div>
          )}

          {!disableSecondaryAction && secondaryActionTitle && (
            <div
              className="action secondary"
              data-test-id="dialog__secondary"
              onClick={secondaryAction ? secondaryAction : props.hideDialog}
            >
              {secondaryActionTitle || lang.cancel}
            </div>
          )}

          {!disableTertiaryAction && (
            <div
              className="action tertiary"
              data-test-id="dialog__tertiary"
              onClick={tertiaryAction ? tertiaryAction : props.hideDialog}
            >
              {tertiaryActionTitle || lang.cancel}
            </div>
          )}
        </div>
      </CSSTransition>
    </div>
  );
};

const componentStyles = (props) => css`
  .dialog {
    padding: 0.5rem;
    position: fixed;
    bottom: 0px; /* Component enters with a spring animation, and needs extra padding in bottom */
    left: 0;
    width: 100%;
    background-color: ${colors.white};
    will-change: auto;

    @media screen and (min-width: ${breakpoints.md}px) {
      width: ${breakpoints.md}px;
      padding: 0.75rem;
      bottom: 1rem;
      border-radius: 6px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
    }

    &.dialog-enter {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
    &.dialog-enter-active {
      transform: translate3d(0, 0%, 0);
      opacity: 1;
      transition: transform 190ms ease, opacity ${durations.normal}ms;
    }
    &.dialog-exit {
      opacity: 1;
    }
    &.dialog-exit-active {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
      transition: transform ${durations.normal}ms ease, opacity ${durations.normal}ms;
    }

    .header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0.5rem;
      font-weight: 700;

      svg {
        width: 1.25rem;
        height: 1.25rem;
        margin-bottom: -3px;
        margin-right: 0.25rem;
      }

      svg.close-icon {
        color: ${colors.darkGrey};
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0rem;
      }
    }

    .content {
      margin: 0.5rem 0 1.5rem 0;
    }

    .action {
      margin-top: 0.5rem;
      font-weight: 700;
      padding: 0.75rem;
      text-align: center;
      border-radius: 2px;
      color: ${colors.white};

      &.secondary {
        border: 1px solid ${colors.midGrey};
        color: ${colors.darkGrey};
      }

      &.tertiary {
        border: 1px solid ${colors.midGrey};
        color: ${colors.darkGrey};
      }

      &:hover {
        cursor: pointer;
      }

      &.disabled {
        background-color: ${colors.midGrey};
        cursor: not-allowed;
      }
    }

    /* Style types */
    &.ok {
      .header {
        color: ${colors.green};
        font-weight: 700;
      }
      .action.primary {
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        background-color: ${colors.green};
      }
    }

    &.neutral {
      .header {
        color: ${props.primaryColor};
      }
      .action.primary {
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        background-color: ${props.primaryColor};
      }
    }

    &.error {
      .header {
        color: ${colors.red};
        font-weight: 700;
      }
      .action.primary {
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        background-color: ${colors.red};
      }
    }
  }
`;

const mapStateToProps = (state) => ({
  dialog: state.ui.dialog,
  primaryColor: state.appConfig.primaryColor,
  lang: state.language.language,
});
const mapDispatchToProps = (dispatch) => ({
  hideDialog: bindActionCreators(hideDialog, dispatch),
});

Dialog.propTypes = {
  /** object that holds most logic for component, look at example , on how to pass it*/
  dialog: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dialog);
