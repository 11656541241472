import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import params from "jquery-param";

import req from "../../../../../utilities/request-utility";

const useOverviewData = ({ query }) => {
  const selectedProject = useSelector((s) => s.semcompletion.selectedProject);

  const {
    isLoading: metaDataIsLoading,
    error: metaDataError,
    data: { data: metaDataData = {} } = {},
  } = useQuery(
    `${selectedProject ? selectedProject.id : "project"}-punchList-metaData`,
    () => req()(`semcompletion/v2/punches/punch-list/meta-data`),
    { refetchOnWindowFocus: false }
  );

  const metaData = { data: metaDataData, loading: metaDataIsLoading, error: metaDataError };

  const {
    data: { data: punchListPageData = [] } = {},
    isFetching: punchListPageFetching = false,
    error: punchListPageError = null,
  } = useQuery(["punchList", query], () => req()(`semcompletion/v2/punches/punch-list?${params(query)}`), {
    refetchOnWindowFocus: false,
  });

  const punchListData = {
    data: punchListPageData,
    error: punchListPageError,
    loading: punchListPageFetching,
  };

  return { metaData, punchListData };
};

export default useOverviewData;
