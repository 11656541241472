import {
  GET_LANGUAGE,
  GET_LANGUAGE_SUCCESS,
  GET_LANGUAGE_FAILURE,
} from "../actions/actionTypes"

const initialState = {
  language: {},
  loading: true,
  error: false
};

export default function (state = initialState, action) {
  switch (action.type) {

    case GET_LANGUAGE:
      return {
        ...state,
        loading: true,
        error: false
      };

    case GET_LANGUAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        language: action.payload
      };

    case GET_LANGUAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };


    default:
      return state
  }
}