import { useSelector } from "react-redux";
import getPageFromId from "../utilities/get-page-from-id";
import getBackButtonUrl from "../utilities/get-back-button-url";

function usePageAndBackButtonURL(props) {
  const pages = useSelector((s) => s.pages.pages);
  const page = getPageFromId(pages, props.match.params.pageId);
  const backButtonURL = getBackButtonUrl(page, pages);

  return { page, backButtonURL };
}

export default usePageAndBackButtonURL;
