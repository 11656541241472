import React, { memo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import tinycolor from "tinycolor2";
import { css } from "emotion";
import { has, isEqual } from "lodash";

// Actions
import {
  setLibraryFileQuery,
  setLibraryFolderQuery,
  setLibraryURLQuery,
  setManageLibraryFileQuery,
  setManageLibraryFolderQuery,
  setManageLibraryURLQuery,
  setSelectedFolder,
  setUrlSuffix,
} from "../actions/libraryActions";
import { openSideMenu } from "../actions/sideMenuActions";
import { showModalPage } from "../actions/uiActions";
import { getPages } from "../actions/pagesActions";
import StatusBox from "./ui/StatusBox";

// Hooks
import useOverviewData from "./semcompletion/punch/hooks/useOverviewData";

// Style
import colors from "../style/colors";

// Utilities
import iconConverter from "../utilities/icon-converter";
import linkPathCreator from "../utilities/get-link-path-from-page-object";
import pageNavigator from "../utilities/page-navigator";
import clearLocalStorage from "../utilities/clear-local-storage";
import getAppName from "../utilities/get-app-name";

// Components
import Page from "../components/ui/Page";
import TopBar from "../components/ui/TopBar";
import SearchInput from "../components/ui/SearchInput";
import Notification from "./ui/Notification";

// Icons
import { MenuIcon, SearchIcon, CheckboxBlankCircleIcon } from "mdi-react";

const frontPageIcon =
  process.env.REACT_APP_CUSTOM_NODE_ENV === "development"
    ? `${process.env.PUBLIC_URL}/img/dev-front-icon.png`
    : process.env.REACT_APP_CUSTOM_NODE_ENV === "staging"
    ? `${process.env.PUBLIC_URL}/img/test-front-icon.png`
    : process.env.REACT_APP_CUSTOM_NODE_ENV === "production"
    ? `${process.env.PUBLIC_URL}/img/prod-front-icon.png`
    : "";

const FrontPage = () => {
  const [clickCount, setClickCount] = useState(0);

  const dispatch = useDispatch();

  const { metaData } = useOverviewData();

  const pages = useSelector(({ pages }) => pages, isEqual);
  const project = useSelector(({ semcompletion }) => semcompletion.selectedProject, isEqual);
  const projectArea = useSelector(({ semcompletion }) => semcompletion.selectedProjectArea, isEqual);
  const lang = useSelector(({ language }) => language.language, isEqual);
  const userName = useSelector(({ auth }) => auth.user.name, isEqual);
  const appConfig = useSelector(({ appConfig }) => appConfig, isEqual);

  // This is set because of notifications - when a user clicks on a module, the pages need to update..
  useEffect(() => {
    dispatch(getPages());
    dispatch(setSelectedFolder(null));
    dispatch(setUrlSuffix(""));
    dispatch(setLibraryFileQuery({ active: true }));
    dispatch(setLibraryFolderQuery({ active: true }));
    dispatch(setLibraryURLQuery({ active: true }));
    dispatch(setManageLibraryFileQuery({ active: true }));
    dispatch(setManageLibraryFolderQuery({ active: true }));
    dispatch(setManageLibraryURLQuery({ active: true }));

    const savedInstallationSearch = localStorage.getItem("installationSearch");
    if (savedInstallationSearch) localStorage.removeItem("installationSearch");

    const savedPullingSearch = localStorage.getItem("pullingSearch");
    if (savedPullingSearch) localStorage.removeItem("pullingSearch");

    const savedTerminationSearch = localStorage.getItem("terminationSearch");
    if (savedTerminationSearch) localStorage.removeItem("terminationSearch");

    const savedMCSearch = localStorage.getItem("mechanicalcompletionSearch");
    if (savedMCSearch) localStorage.removeItem("mechanicalcompletionSearch");

    const savedMCEquipmentSearch = localStorage.getItem("mechanicalcompletionequipmentSearch");
    if (savedMCEquipmentSearch) localStorage.removeItem("mechanicalcompletionequipmentSearch");

    const savedSearchModuleFilters = localStorage.getItem("search-module-filters");
    if (savedSearchModuleFilters) localStorage.removeItem("search-module-filters");

    const savedSearchModuleSearchterms = localStorage.getItem("search-module-searchterms");
    if (savedSearchModuleSearchterms) localStorage.removeItem("search-module-searchterms");

    const savedSearchModuleCablePage = localStorage.getItem("search-module-cable-page");
    if (savedSearchModuleCablePage) localStorage.removeItem("search-module-cable-page");

    const savedSearchModuleTagsPage = localStorage.getItem("search-module-tags-page");
    if (savedSearchModuleTagsPage) localStorage.removeItem("search-module-tags-page");

    const savedSearchModuleActiveTab = localStorage.getItem("search-module-active-tab");
    if (savedSearchModuleActiveTab) localStorage.removeItem("search-module-active-tab");

    const jobpackages = ["installation", "pulling", "termination", "mechanicalcompletion", "mechanicalcompletionequipment"];
    const tabs = ["Overview", "Favorites"];
    const subTabs = ["Outstanding", "Completed", "All"];

    jobpackages.map((jp) => {
      tabs.map((t) => {
        subTabs.map((s) => {
          const savedPageNumber = localStorage.getItem(`${t}${jp}${s}PageNumber`);
          if (savedPageNumber) localStorage.removeItem(`${t}${jp}${s}PageNumber`);

          const savedPageSize = localStorage.getItem(`${t}${jp}${s}PageSize`);
          if (savedPageSize) localStorage.removeItem(`${t}${jp}${s}PageSize`);
        });
      });
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Toggles dialog if name is pressed 15 times
    if (clickCount === 15) {
      if (window.confirm("You are about to reset the app. This will log you out. Are you sure you want to continue?")) {
        clearLocalStorage((err) => {
          window.location = window.location.origin + "/" + getAppName();
        });
      } else {
        setClickCount(0);
      }
    }

    // eslint-disable-next-line
  }, [clickCount]);

  return (
    <Page backgroundColor={colors.white}>
      {/* Topbar */}
      <TopBar
        actionLeft={<MenuIcon onClick={() => dispatch(openSideMenu())} />}
        title={appConfig && <img src={frontPageIcon} alt={`Logo for ${appConfig.title}`} />}
        atFrontPage={true}
        actionsRight={[
          <SearchIcon
            onClick={() => {
              dispatch(
                showModalPage({
                  title: "Search",
                  content: () => <SearchInput searching={false} primaryColor={appConfig.primaryColor} />,
                })
              );
            }}
          />,
        ]}
      />

      {/* Header */}
      <div className={header()} onClick={() => setClickCount(clickCount + 1)}>
        <p className="text-center">
          {lang.hello}, {userName}
          {!!project.number && (
            <>
              <br />
              <span>
                {project.number} - {project.description}
              </span>
            </>
          )}
          {projectArea && (
            <>
              <br />
              <span>
                {projectArea.code} - {projectArea.name}
              </span>
            </>
          )}
        </p>
      </div>

      {/* Loading -> "Skeleton" icons with blinking animations */}
      {pages.pages.filter((p) => p.module !== "SemcompletionProjectChooser").length === 0 &&
        pages.loading &&
        !pages.error && (
          <div className={`${iconsContainer(appConfig)} frontpage-navigation`}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((num, index) => (
              <div className={icons()} key={index}>
                <CheckboxBlankCircleIcon className="placeholder-icon" style={{ animationDelay: `${index * 30}ms` }} />
                <div className="fake-title" style={{ animationDelay: `${index * 30}ms` }}></div>
              </div>
            ))}
          </div>
        )}

      {/* App misconfigured */}
      {pages.pages.filter((p) => p.module !== "SemcompletionProjectChooser").length === 0 &&
        !pages.loading &&
        !pages.error && (
          <div className={`${iconsContainer(appConfig)} frontpage-navigation`}>
            <StatusBox
              style={{ marginTop: "2rem", color: colors.white }}
              action={{
                callback: () => {
                  getPages();
                  window.location.reload();
                },
                title: "Try again",
              }}
              title="It looks like this app doesn't have any pages"
              content={
                <span>
                  We didn't find any pages for this app. Try again or contact us at{" "}
                  <a
                    style={{ color: colors.white, textDecoration: "underline" }}
                    href="mailto:semcompletion@semcomaritime.com"
                  >
                    semcompletion@semcomaritime.com
                  </a>
                </span>
              }
            />
          </div>
        )}

      {/* Error getting pages */}
      {pages.pages.filter((p) => p.module !== "SemcompletionProjectChooser").length === 0 &&
        !pages.loading &&
        pages.error && (
          <StatusBox
            style={{ marginTop: "2rem" }}
            action={{
              callback: () => {
                getPages();
                window.location.reload();
              },
              title: "Try again",
            }}
            title="There was an error loading the pages"
            content={
              <span>
                We didn't find any pages for this app. Try again or contact us at{" "}
                <a
                  style={{ color: colors.white, textDecoration: "underline" }}
                  href="mailto:semcompletion@semcomaritime.com"
                >
                  semcompletion@semcomaritime.com
                </a>
              </span>
            }
          />
        )}

      {/* Pages present */}
      {pages.pages.length > 0 && !pages.error && (
        <div className={`${iconsContainer(appConfig)} frontpage-navigation`}>
          {pages.pages
            .filter((el) => el.showAtFrontPage)
            .map((page, index) => (
              <div
                data-test-id={`frontpage-icon__${page.module}`}
                onClick={() => {
                  const module = page.module.slice(13).toLowerCase();
                  localStorage.removeItem(`${module}-activeTab`);
                  localStorage.removeItem(`${module}-activeTab2`);

                  pageNavigator(linkPathCreator(page, "absolute"), "forward");
                }}
                className={icons()}
                key={index}
              >
                {iconConverter(page.icon)}
                {page.title}
                {page.module === "SemcompletionPunch" &&
                has(metaData.data, "punchNotifications") &&
                metaData.data.punchNotifications !== null &&
                metaData.data.punchNotifications.notifications > 0 ? (
                  <Notification
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(9px, -50px)",
                    }}
                    notifications={metaData.data.punchNotifications.notifications}
                  />
                ) : (
                  page.notifications !== null &&
                  page.notifications > 0 && (
                    <Notification
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(9px, -50px)",
                      }}
                      notifications={page.notifications}
                    />
                  )
                )}
              </div>
            ))}
        </div>
      )}
    </Page>
  );
};

const header = () => css`
  flex: 0 0 70px;
  display: flex;
  z-index: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.15);
`;

const iconsContainer = (appConfig) => css`
  flex: 0 1 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-image: url(${getBackgroundImageURL(appConfig)});
  background-size: cover;
  background-position: center;
  background-color: ${appConfig.primaryColor};
`;

const icons = () => css`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33.333%;
  height: 25%;
  padding: 0.5rem;
  vertical-align: top;
  font-size: 0.8rem;
  color: ${colors.white};
  text-align: center;
  text-decoration: none;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid ${tinycolor(colors.white).setAlpha(0.2).toRgbString()};

  /* First column */
  &:nth-child(3n + 1) {
    border-right: 1px solid ${tinycolor(colors.white).setAlpha(0.2).toRgbString()};
  }
  /* Second column */
  &:nth-child(3n + 2) {
    border-right: 1px solid ${tinycolor(colors.white).setAlpha(0.2).toRgbString()};
  }

  svg {
    margin-bottom: 0.5rem;
    width: 2rem;
    height: 2rem;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  }

  svg.placeholder-icon {
    width: 2.25rem;
    height: 2.25rem;
    animation: blink 1.5s ease infinite;
    opacity: 0.3;
  }

  .fake-title {
    width: 65px;
    height: 7px;
    border-radius: 5px;
    margin-top: 7px;
    background-color: rgba(255, 255, 255, 0.6);
    animation: blink 1.5s ease infinite;
    opacity: 0.3;
  }

  @keyframes blink {
    0% {
      opacity: 0.3;
    }
    35% {
      opacity: 0.3;
    }
    55% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.3;
    }
  }
`;

const getBackgroundImageURL = (appConfig) =>
  `${appConfig.frontPageImage.baseURL}b_black,o_50,h_800,q_auto,f_auto/${appConfig.frontPageImage.image}`;

export default memo(FrontPage);
