import React from "react";
import {
  AccountCardDetailsIcon,
  AlertCircleOutlineIcon,
  CakeVariantIcon,
  CalendarRangeIcon,
  ChartLineIcon,
  ClipboardCheckOutlineIcon,
  CommentMultipleOutlineIcon,
  CommentProcessingIcon,
  CommentQuestionIcon,
  EmoticonHappyIcon,
  HeartIcon,
  HotelIcon,
  HumanMaleIcon,
  LightbulbOnOutlineIcon,
  NewspaperIcon,
  PencilIcon,
  PhoneIcon,
  RoutesIcon,
  SchoolIcon,
  SilverwareVariantIcon,
  SoccerIcon,
  TrophyVariantIcon,
  PineTreeIcon,
  ChartBarIcon,
  BookOpenVariantIcon,
  GlassCocktailIcon,
  BellOutlineIcon,
  CheckIcon,
  ClipboardAccountIcon,
  AccountGroupIcon,
  OfficeBuildingIcon,
  GavelIcon,
  DiamondIcon,
  ShoppingBasketIcon,
  TrainIcon,
  EyeOutlineIcon,
  InformationIcon,
  FeatherIcon,
  WrenchIcon,
  CursorPointerIcon,
  FileIcon,
  TruckIcon,
  HeadphonesIcon,
  LoginIcon,
  PodcastIcon,
  CommentOutlineIcon,
  CalendarCheckIcon,
  LifePreserverIcon,
  FolderIcon,
  LockIcon,
  SettingsIcon,
  StarIcon,
  StarOutlineIcon,
  BarcodeIcon,
  ArchiveIcon
} from "mdi-react";

export default function(faIconName, styles = null) {
  faIconName = faIconName.trim().toLowerCase();

  switch (faIconName) {
    case "fa-exclamation":
      return <AlertCircleOutlineIcon style={styles}/>;
    case "fa-archive":
      return <ArchiveIcon style={styles}/>;
    case "fa-map-signs":
      return <RoutesIcon style={styles}/>;
    case "fa-male":
      return <HumanMaleIcon style={styles}/>;
    case "fa-newspaper-o":
      return <NewspaperIcon style={styles}/>;
    case "fa-trophy":
      return <TrophyVariantIcon style={styles}/>;
    case "fa-comments-o":
    case "fa fa-comments-o":
      return <CommentMultipleOutlineIcon style={styles}/>;
    case "fa-comment-o":
      return <CommentOutlineIcon style={styles}/>;
    case "fa-smile-o":
      return <EmoticonHappyIcon style={styles}/>;
    case "fa-chart":
      return <ChartLineIcon style={styles}/>;
    case "fa-lightbulb-o":
      return <LightbulbOnOutlineIcon style={styles}/>;
    case "fa-question":
    case "fa fa-question":
      return <CommentQuestionIcon style={styles}/>;
    case "fa-book":
      return <BookOpenVariantIcon style={styles}/>;
    case "fa-phone":
    case "fa fa-phone":
      return <PhoneIcon style={styles}/>;
    case "fa-cutlery":
      return <SilverwareVariantIcon style={styles}/>;
    case "fa-graduation-cap":
    case "fa-mortar-borad":
    case "fa fa-mortar-board":
      return <SchoolIcon style={styles}/>;
    case "fa-pencil":
      return <PencilIcon style={styles}/>;
    case "fa-heart":
    case "fa fa-heart":
      return <HeartIcon style={styles}/>;
    case "fa-id-card-o":
    case "fa-address-card-o":
    case "fa-address-card":
      return <AccountCardDetailsIcon style={styles}/>;
    case "fa-bed":
      return <HotelIcon style={styles}/>;
    case "fa-commenting-o":
    case "fa fa-commenting":
      return <CommentProcessingIcon style={styles}/>;
    case "fa-birthday-cake":
    case "fa fa-birthday-cake":
      return <CakeVariantIcon style={styles}/>;
    case "fa-calendar":
    case "fa fa-calendar":
      return <CalendarRangeIcon style={styles}/>;
    case "fa-tasks":
      return <ClipboardCheckOutlineIcon style={styles}/>;
    case "fa-futbol-o":
    case "fa-soccer-ball-o":
      return <SoccerIcon style={styles}/>;
    case "fa-tree":
      return <PineTreeIcon style={styles}/>;
    case "fa-bar-chart":
      return <ChartBarIcon style={styles}/>;
    case "fa-glass":
    case "fa fa-glass":
      return <GlassCocktailIcon style={styles}/>;
    case "fa-bell-o":
    case "fa fa-bell-o":
      return <BellOutlineIcon style={styles}/>;
    case "fa-check":
      return <CheckIcon style={styles}/>;
    case "fa-id-badge":
      return <ClipboardAccountIcon style={styles}/>;
    case "fa-users":
      return <AccountGroupIcon style={styles}/>;
    case "fa-building-o":
      return <OfficeBuildingIcon style={styles}/>;
    case "fa-gavel":
      return <GavelIcon style={styles}/>;
    case "fa-diamond":
      return <DiamondIcon style={styles}/>;
    case "fa-shopping-basket":
      return <ShoppingBasketIcon style={styles}/>;
    case "fa-train":
      return <TrainIcon style={styles}/>;
    case "fa-eye":
      return <EyeOutlineIcon style={styles}/>;
    case "fa-info-circle":
      return <InformationIcon style={styles}/>;
    case "fa-envira":
      return <FeatherIcon style={styles}/>;
    case "fa-wrench":
      return <WrenchIcon style={styles}/>;
    case "fa-hand-pointer-o":
    case "fa-hand-pointer":
      return <CursorPointerIcon style={styles}/>;
    case "fa-file":
    case "fa fa-file":
      return <FileIcon style={styles}/>;
    case "fa-truck":
      return <TruckIcon style={styles}/>;
    case "fa-headphones":
      return <HeadphonesIcon style={styles}/>;
    case "fa-sign-in":
      return <LoginIcon style={styles}/>;
    case "fa-podcast":
      return <PodcastIcon style={styles}/>;
    // Der findes ingen Handshake-icon i Material Design derfor har jeg brugt CalendarCheckIcon.
    case "fa-handshake-o":
      return <CalendarCheckIcon style={styles}/>;
    case "fa-life-ring":
      return <LifePreserverIcon style={styles}/>;
    case "fa-folder":
      return <FolderIcon style={styles}/>;
    case "fa-lock":
      return <LockIcon style={styles}/>;
    case "fa-gear":
      return <SettingsIcon style={styles}/>;
    case "fa-star-o":
      return <StarOutlineIcon style={styles}/>;
    case "fa-star":
      return <StarIcon style={styles}/>;
    case "fa-line-chart":
      return <ChartLineIcon style={styles}/>;
    case "fa-barcode":
      return <BarcodeIcon style={styles}/>;
    default:
      return null;
  }
}
