import { TESTSHEET } from "../actions/actionTypes";

const initialState = {
  savedAsDraft: [],
  testSheetTitle: "",
};

export default function (state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case TESTSHEET.SETISSAVEDASDRAFT:
      return { ...state, savedAsDraft: [...payload] };
    case TESTSHEET.ADDISSAVEDASDRAFT:
      return { ...state, savedAsDraft: [...state.savedAsDraft, payload] };
    case TESTSHEET.REMOVEISSAVEDASDRAFT:
      return { ...state, savedAsDraft: state.savedAsDraft.filter((item) => item !== payload) };
    case TESTSHEET.SETTESTSHEETTITLE:
      return { ...state, testSheetTitle: payload };
    default:
      return state;
  }
}
