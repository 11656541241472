import getLinkPathFromPageObject from "./get-link-path-from-page-object";
import getAppName from "./get-app-name";
import queryString from "query-string";
import getPageFromId from "./get-page-from-id";

export default function (page = {}, pages) {
  /**
   * Checks if page has a parentId. If it has, it will find it's parent-page and
   * returns the url to it.
   * If the page doesn't have a parentId it will navigate to the frontpage
   * ---------------------------------
   * @argument {object} page Default to an empty object
   * @argument {array of objects} pages No default value
   *
   */

  let { caller } = queryString.parse(window.location.search);

  if (caller) {
    return caller;
  }

  if (page.parentId) {
    let { parentId } = page;

    let parentPage = getPageFromId(pages, parentId);

    return getLinkPathFromPageObject(parentPage, "absolute");
  } else {
    return `/${getAppName()}/`;
  }
}
