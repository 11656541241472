import getAppName from "./get-app-name";

export default callback => {
  try {
    localStorage.removeItem(`${getAppName()}-state`);
    callback(null, "ok");
  } catch (err) {
    callback(err, "error");
  }
};
