import { DOCUMENTS, SET_TOOL } from "../actions/actionTypes";

const INITIAL_STATE = {
  action: null,
  activeTool: null,
  editing: false,
  error: null,
  formLoading: false,
  imageHeight: "",
  imageURL: "",
  imageWidth: "",
  loading: true,
  pdfFilename: "",
  svg: null,
  svgJSON: null,
  type: null,
  view: null,
};

export default function (state = INITIAL_STATE, action) {
  const { payload, type } = action;

  switch (type) {
    case DOCUMENTS.RESET:
      return { ...state, ...INITIAL_STATE };
    case DOCUMENTS.SETVIEW:
      return { ...state, view: payload };

    case DOCUMENTS.FETCH.REQUEST:
      return {
        ...state,
        imageHeight: "",
        imageURL: "",
        imageWidth: "",
        loading: true,
        error: null,
        svg: null,
        svgJSON: null,
      };
    case DOCUMENTS.FETCH.SUCCESS:
      return { ...state, ...payload, error: null, loading: false };
    case DOCUMENTS.FETCH.FAILURE:
      return { ...state, error: payload, loading: false };

    case DOCUMENTS.CANCEL.REQUEST:
      return { ...state, action: "cancel", formLoading: true, error: null };
    case DOCUMENTS.CANCEL.SUCCESS:
      return { ...state, ...payload, action: null, editing: false, error: null, formLoading: false, type: null };
    case DOCUMENTS.CANCEL.FAILURE:
      return { ...state, action: null, error: payload, formLoading: false };

    case DOCUMENTS.EDIT.REQUEST:
      return { ...state, action: "edit", formLoading: true, error: null, type: payload };
    case DOCUMENTS.EDIT.SUCCESS:
      return { ...state, action: null, editing: true, error: null, formLoading: false };
    case DOCUMENTS.EDIT.FAILURE:
      return { ...state, action: null, error: payload, formLoading: false };

    case DOCUMENTS.SAVE.REQUEST:
      return { ...state, action: "save", formLoading: true, error: null };
    case DOCUMENTS.SAVE.SUCCESS:
      return { ...state, action: null, editing: false, error: null, formLoading: false, type: null };
    case DOCUMENTS.SAVE.FAILURE:
      return { ...state, action: null, error: payload, formLoading: false };

    case SET_TOOL:
      return { ...state, activeTool: payload };

    default:
      return state;
  }
}
