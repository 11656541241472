import React from "react";
import { css } from "emotion";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import tinycolor from "tinycolor2";
import { ArrowBackIcon } from "mdi-react";

// Styles
import colors from "../../style/colors";
import common from "../../style/common";

/** Bar element which always is placed at the top of an page. it holds 2 actions, and the page title
 *
 * ```
 * <TopBar title={page.title} actionLeft={<ArrowBackIcon onClick={() => pageNavigator("/FrontPage", "backward")} />} />
 *
 * ```
 */
const TopBar = (props) => {
  return (
    <div ref={props.scrollTopRef} className={style(props) + " top-bar"} style={props.style}>
      <div className="action-left" data-test-id="top-bar__action-left">
        {props.actionLeft || ""}
      </div>
      {props.title && (
        <div className="title">
          <p>{props.title}</p>
        </div>
      )}
      {/* Is positioned after topbar in markup to not have to specify a z-index for children */}
      {/* <div className="action-left" data-test-id="top-bar__action-left">
        {props.actionLeft || ""}
      </div> */}
      <div className="action-right">{props.actionRight || <ArrowBackIcon className="action-right-placeholder" />}</div>
    </div>
  );
};

const topBarBackgroundColor =
  process.env.REACT_APP_CUSTOM_NODE_ENV === "development"
    ? "#4AA0FF"
    : process.env.REACT_APP_CUSTOM_NODE_ENV === "staging"
    ? "#CB9E48"
    : process.env.REACT_APP_CUSTOM_NODE_ENV === "production"
    ? "#103447"
    : "#fff";

const style = ({ atFrontPage, primaryColor, titleMarginLeft }) => css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: ${common.topBarHeight}px;
  background-color: ${atFrontPage ? colors.white : topBarBackgroundColor};
  color: ${atFrontPage ? colors.black : colors.white};
  box-shadow: ${atFrontPage ? "none" : common.boxShadowSubtle};
  z-index: 1;
  flex-shrink: 0;

  svg {
    fill: ${atFrontPage ? colors.black : colors.white};
    /* background-color: ${atFrontPage ? colors.white : primaryColor}; */
    padding: ${common.topBarHeight * 0.256}px;
    height: ${common.topBarHeight}px;
    width: ${common.topBarHeight}px;

    &:active {
      fill: ${tinycolor(atFrontPage ? colors.black : colors.white)
        .setAlpha(0.8)
        .toString()};
    }
  }

  .ant-select-arrow,
  .ant-select-clear {
    svg {
      fill: ${tinycolor(colors.black).setAlpha(0.8).toString()};
      width: 40px;
    }
  }

  .action-right-placeholder {
    fill: ${atFrontPage ? colors.white : topBarBackgroundColor};

    &:active {
      fill: ${tinycolor(atFrontPage ? colors.white : topBarBackgroundColor)
        .setAlpha(0.8)
        .toString()};
    }
  }

  .action-right {
    width: auto;
    height: ${common.topBarHeight}px;
    display: inline-flex;
  }

  .title {
    font-weight: 700;
    text-align: center;
    line-height: ${common.topBarHeight}px;
    padding: 0 20px;
    margin-left: ${titleMarginLeft ? titleMarginLeft : 0}px;
    width: 75%;
    display: flex;
    align-items: center;

    p {
      overflow: hidden;
      white-space: break-spaces;
      width: 100%;
      word-wrap: break-word;
      line-height: normal;
    }

    img {
      max-height: 35px;
      margin-top: 0.5rem;
    }
  }
`;

// .action-left,
//   .action-right {
//     position: absolute;
//     top: 0;
//     margin-right: 20px;
//   }

//   .action-right {
//     right: 0;
//     height: ${common.topBarHeight}px;
//     overflow: hidden;
//   }
//   .action-left {
//     left: 0;
//     height: ${common.topBarHeight}px;
//     overflow: hidden;
//   }

const mapStateToProps = (state) => ({
  primaryColor: state.appConfig.primaryColor,
});

TopBar.propTypes = {
  /** Bool value that helps with styling element differently if its at the frontpage */
  atFrontPage: PropTypes.bool,
  /** Override styles with this */
  style: PropTypes.object,
  /** Click event to be executed when left Element is clicked */
  actionLeft: PropTypes.object,
  /** Click event to be executed when right Element is clicked */
  actionRight: PropTypes.object,
};

export default connect(mapStateToProps)(TopBar);
