import React from "react";
import { Collapse, Typography, Switch } from "antd";

const ListItemCollapsible = ({
  data = {},
  loading = false,
  onChangeStatus = () => {},
  onChangePunchListActionStatus = () => {},
}) => {
  const { id, actions = [], active = false, name = "" } = data;

  return (
    <Collapse collapsible={"icon"} expandIconPosition={"end"}>
      <Collapse.Panel
        showArrow={false}
        header={name}
        key={id}
        extra={
          <div style={{ display: "block", textAlign: "right" }}>
            <div style={{ marginBottom: 3 }}>
              <Typography.Text style={{ marginRight: "10px" }}>Active</Typography.Text>
              <Switch
                checkedChildren="On"
                unCheckedChildren="Off"
                checked={active}
                onChange={(e) => {
                  let modifiedPages = [{ id, active: e ? 1 : 0 }];
                  onChangeStatus(modifiedPages);
                }}
                loading={loading}
                style={{ backgroundColor: active ? "#103447" : "#abaeac" }}
              />
            </div>
            {actions.map((d, i) => (
              <div key={i} style={{ marginBottom: 3 }}>
                <Typography.Text style={{ marginRight: "10px" }}>{d.action}</Typography.Text>
                <Switch
                  disabled={!active}
                  checkedChildren="On"
                  unCheckedChildren="Off"
                  checked={d.active}
                  onChange={(e) => {
                    let modifiedPages = [{ punchListId: id, id: d.id ? d.id : null, active: e ? true : false }];
                    onChangePunchListActionStatus(modifiedPages);
                  }}
                  loading={loading}
                  style={{ backgroundColor: d.active ? "#103447" : "#abaeac" }}
                />
              </div>
            ))}
          </div>
        }
      ></Collapse.Panel>
    </Collapse>
  );
};

export default ListItemCollapsible;
