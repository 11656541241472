import React, { useState } from "react";
import { css } from "emotion";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { notification } from "antd";

import { hideDialog, hideModalPage, showDialog } from "../../actions/uiActions";

// Icons
import { LockQuestionIcon, AlertDecagramIcon, ErrorOutlineIcon } from "mdi-react";

// Components
import TextInput from "../ui/TextInput";
import Button from "../ui/Button";
import ScrollView from "../ui/ScrollView";
import { renewPassword, renewPasswordHandled } from "../../actions/authActions";
import colors from "../../style/colors";

const ForgotPassword = (props) => {
  const [phoneNumber, setPhoneNumber] = useState(props.prefillValue || "");
  const appConfig = useSelector((state) => state.appConfig);

  const renewPasswordError = useSelector((state) => state.auth.renewPasswordError);

  // If something goes wrong when reset password
  if (renewPasswordError) {
    props.renewPasswordHandled();
    notification.error({ duration: 7, message: "FAILED", description: "Could not reset your password" });
  }

  function handlePasswordReset() {
    let dialog = {
      title: "Warning",
      content:
        "You are about to request a new password. Doing so will deactivate your old password and you will no longer be able to access the app with it",
      icon: <AlertDecagramIcon />,
      styleType: "warning",
      primaryActionTitle: "Okay, request a new password",
      secondaryActionTitle: "Cancel, keep my old password",
      primaryAction: () => {
        props.renewPassword(phoneNumber);
      },
    };
    props.showDialog(dialog);
  }

  return (
    <>
      <ScrollView className={componentStyle(props)} style={{ backgroundColor: "#ffffff" }}>
        <div className="forgot-password-container">
          <div className="icon-container">
            <LockQuestionIcon className="lock-icon" size={50} color={appConfig.primaryColor} />
          </div>
          <h3 style={{ marginBottom: "1rem" }}>Forgot your password?</h3>
          <p style={{ marginBottom: "3rem" }}>
            Use the form below to reset your password. You will receive a new one to your phone
          </p>
          <TextInput
            style={{ marginBottom: "1rem" }}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder={`Enter your phone number here`}
            value={phoneNumber}
          />
          <Button disabled={phoneNumber === ""} onClick={handlePasswordReset} title="Request a new password" />
        </div>
      </ScrollView>
    </>
  );
};

const componentStyle = (props) => css`
  .forgot-password-container {
    padding: 0.7rem;
    width: 100%;
    height: 100%;
    max-width: 500px;
    margin: 0 auto;
    color: ${colors.black};
    text-align: center;

    .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 78px;
      height: 78px;
      border: 1px ${colors.lightGrey} solid;
      border-radius: 50%;
      margin: 2rem auto;

      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  renewPasswordHandled: bindActionCreators(renewPasswordHandled, dispatch),
  renewPassword: bindActionCreators(renewPassword, dispatch),
  showDialog: bindActionCreators(showDialog, dispatch),
  hideDialog: bindActionCreators(hideDialog, dispatch),
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
});

export default connect(null, mapDispatchToProps)(ForgotPassword);
