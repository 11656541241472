import { OPEN_SIDE_MENU, CLOSE_SIDE_MENU } from "./actionTypes";
import { disableBodyScroll } from "body-scroll-lock";

export function openSideMenu() {
  setTimeout(() => disableBodyScroll(document.querySelector(".side-menu.scroll-hider")), 300);
  return {
    type: OPEN_SIDE_MENU
  };
}

export function closeSideMenu() {
  return {
    type: CLOSE_SIDE_MENU
  };
}
