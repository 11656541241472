import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import loadStateFromLocalStorage from "./utilities/load-state-from-local-storage";
import saveStateToLocalStorage from "./utilities/save-state-to-local-storage";
import thunk from "redux-thunk";
import rootReducer from "./reducers"; // reducers/index.js

// Load initial state
const initialState = loadStateFromLocalStorage();

// Register middleware
const middleware = [thunk];

// Create store
const store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

// Save state to ls after 2s of redux-inactivity
let timeout;
store.subscribe(() => {
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    saveStateToLocalStorage({ ...store.getState() });
  }, 500);
});

export default store;
