import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";

// UTILITIES
import req from "../../../../utilities/request-utility";

// ui-Components
import MenuAccessList from "./MenuAccessList";
import { keyBy } from "lodash";

const MenuAccess = ({ onUpdate, userId = null }) => {
  const [pages, setPages] = useState([]);

  const { allPages } = useSelector(({ pages }) => ({ allPages: pages.allPages, loading: pages.loading }));

  const { data: { data: userPages = [] } = {} } = useQuery(["UserPages", userId], () => req()(`/pages?user=${userId}`));

  const getPagesIdAndStatus = (pages) => {
    let pageIds = [];
    pages.map((page) => {
      pageIds.push({ id: page.id, active: page.active });

      if (page.pages.length > 0)
        page.pages = page.pages.map((childPage) => {
          pageIds.push({ id: childPage.id, active: childPage.active });
          return childPage;
        });

      return page;
    });

    return pageIds;
  };

  const onChangeStatus = (modifiedPages) => {
    const newPages = pages.map((page) => {
      // child pages
      if (page.pages.length > 0) {
        page.pages = page.pages.map((childPage) => {
          const modifiedChildPage = modifiedPages.find((p) => p.id === childPage.id);
          if (modifiedChildPage) return { ...childPage, active: modifiedChildPage.active };
          return childPage;
        });
      }

      const modifiedPage = modifiedPages.find((p) => p.id === page.id);
      if (modifiedPage) return { ...page, active: modifiedPage.active };

      return page;
    });

    setPages(newPages);
  };

  // get assigned pages to user and adjust pages
  useEffect(() => {
    if (allPages.length > 0 && userPages.length > 0) {
      const assignedPages = getPagesIdAndStatus(userPages),
        assignedPagesObj = keyBy(assignedPages, "id");

      const newPages = allPages.map((page) => {
        page.active = assignedPagesObj[page.id] ? 1 : 0;

        if (page.pages.length > 0) {
          page.pages = page.pages.map((childPage) => {
            return { ...childPage, active: assignedPagesObj[childPage.id] ? assignedPagesObj[childPage.id].active : 0 };
          });
        }

        return page;
      });

      setPages(newPages);
    }
  }, [userId, allPages, userPages]);

  useEffect(() => {
    // always call onUpdate if there's any changes in the pages
    if (onUpdate) onUpdate(getPagesIdAndStatus(pages));
  }, [pages]);

  return (
    <div>
      {pages.map((d) => {
        return <MenuAccessList key={d.id} loading={false} onChangeStatus={onChangeStatus} page={d} />;
      })}
    </div>
  );
};

export default MenuAccess;
