import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { css } from "emotion";
import {
  ArrowBackIcon,
  CheckCircleIcon,
  CloseCircleIcon,
  DeleteIcon,
  EditIcon,
  InformationOutlineIcon,
  PlusIcon,
  RecycleIcon,
} from "mdi-react";
import { Col, notification, Row, Typography } from "antd";
import { isEqual, keyBy } from "lodash";

import Modal from "./Modal";
import useOverviewData from "./hooks/useOverviewData";

import { showModalPage } from "../../../../actions/uiActions";

import Button from "../../../ui/Button";
import InlineSpinner from "../../../ui/InlineSpinner";
import Page from "../../../ui/Page";
import ScrollView from "../../../ui/ScrollView";
import StatusBox from "../../../ui/StatusBox";
import Toggle from "../../../ui/Toggle";
import TopBar from "../../../ui/TopBar";
import AccessCheck from "../../../ui/AccessCheck";

import getErrorMessage from "../../../../utilities/get-error-message";
import pageNavigator from "../../../../utilities/page-navigator";
import req from "../../../../utilities/request-utility";

import usePageAndBackButtonURL from "../../../../hooks/usePageAndBackButtonURL";

import colors from "../../../../style/colors";

const ManageEquipmentsOverview = (props) => {
  const [data, setData] = useState([]);
  const [showDeletedRecords, setShowDeletedRecords] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isDataUpdated, setIsDataUpdated] = useState(false);

  const { selectedProject } = useSelector(
    ({ semcompletion }) => ({ selectedProject: semcompletion.selectedProject }),
    isEqual
  );

  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const { backButtonURL } = usePageAndBackButtonURL(props);

  const { equipmentsData, metaData } = useOverviewData({ query: { isDeleted: showDeletedRecords } });

  const { data: equipmentsPageData, error: equipmentsPageError, loading: equipmentsPageFetching } = equipmentsData;

  const { data: equipmentsMetaData } = metaData;

  const customerSystemsObj = useMemo(() => keyBy(equipmentsMetaData?.systems, "id"), [equipmentsMetaData?.systems]);
  const jobpackageObj = useMemo(() => keyBy(equipmentsMetaData?.jobPackages, "id"), [equipmentsMetaData?.jobPackages]);
  const manufacturesObj = useMemo(() => keyBy(equipmentsMetaData?.manufactures, "id"), [equipmentsMetaData?.manufactures]);
  const drawingsObj = useMemo(() => keyBy(equipmentsMetaData?.drawings, "id"), [equipmentsMetaData?.drawings]);

  const handleDelete = useCallback(
    (record, isDeleted) => {
      const handleOk = () => {
        queryClient.refetchQueries(["equipmentsPage", { isDeleted: showDeletedRecords }]);
        notification.success({ duration: 7, message: "SUCCESS", description: "You have successfully updated an equipment" });
        setDeleteLoading(false);
      };

      const handleError = (err) => {
        const error = getErrorMessage(err);
        notification.error({ duration: 7, message: "FAILED", description: error });
        setDeleteLoading(false);
      };

      setDeleteLoading(true);

      const newData = {
        system: record?.customerSystemId,
        projectArea: record?.areaId,
        jobpackageInstallation: record?.jobPackageId,
        manufactures: record?.manufactureId,
        jobpackageMCCR: record?.jobpackageMccr,
        drawing: record?.drawingId,
        datasheet: record?.datasheetId,
        installed: record?.installed,
        isDeleted,
        tagSignMounted: record?.tagSignMounted,
        installOffshore: record?.installOffshore,
        eex: record?.eex,
        mccrOk: record?.mccrOk,
        tagNo: record?.tagNo,
        refNo: record?.refNo,
        location: record?.location,
        systemId: record?.systemId,
        note: record?.note,
        mcPackage: record?.mcPackage,
      };

      req().put(`semcompletion/equipments/${record.id}`, newData).then(handleOk).catch(handleError);
    },
    [showDeletedRecords, deleteLoading]
  );

  const updateExpanded = useCallback(
    (id) => {
      const newData = data.map((d) => {
        if (d.id === id) d.expanded = !d.expanded;
        return d;
      });

      setData(newData);
    },
    [data]
  );

  const getIsDataUpdated = useCallback((isUpdated) => setIsDataUpdated(isUpdated), []);

  useEffect(() => {
    queryClient.refetchQueries(["equipmentsPage", { isDeleted: showDeletedRecords }]);
  }, [showDeletedRecords]);

  useEffect(() => {
    (() => {
      if (equipmentsPageData.length === 0) return setData([]);

      if (equipmentsPageData.length > 0) {
        const newData = equipmentsPageData.map((d) => {
          d.expanded = false;
          return d;
        });

        setData(newData);
      }
    })();
  }, [equipmentsPageData.length]);

  useEffect(() => {
    if (isDataUpdated) {
      setTimeout(() => {
        setData([...equipmentsPageData]);
        setIsDataUpdated(false);
      }, 1500);
    }
  }, [JSON.stringify(equipmentsPageData), isDataUpdated]);

  return (
    <Page className={container()}>
      <TopBar
        title={`Manage Equipments (${selectedProject.number} - ${selectedProject.description})`}
        actionLeft={<ArrowBackIcon onClick={() => pageNavigator(backButtonURL, "backward")} />}
      />

      <AccessCheck pageId={props.match.params.pageId}>
        <div className="header">
          <div className="row">
            <Button
              onClick={() =>
                dispatch(
                  showModalPage({
                    content: <Modal editMode={false} initialForm={null} showDeletedRecords={showDeletedRecords} />,
                    title: `Add Equipment (${selectedProject.number} - ${selectedProject.description})`,
                  })
                )
              }
              style={{ flexBasis: "30%", flexShrink: 0, width: "100%" }}
            >
              Add Equipment
            </Button>
            <div style={{ alignItems: "center", display: "flex" }}>
              <Toggle
                on={showDeletedRecords}
                onClick={() => setShowDeletedRecords(!showDeletedRecords)}
                style={{ marginRight: 10 }}
              />
              <span>Show deleted records</span>
            </div>
          </div>
        </div>
        {showDeletedRecords ? (
          <div className="remark">
            <InformationOutlineIcon />
            <p className="line-break">You are viewing deleted records.</p>
          </div>
        ) : (
          ""
        )}
        <ScrollView>
          {equipmentsPageFetching && !equipmentsPageError && <InlineSpinner />}
          {!equipmentsPageFetching && equipmentsPageError && <StatusBox />}
          {!equipmentsPageFetching && !equipmentsPageError && (
            <div style={{ padding: "1rem 0px" }}>
              {data.map((d, i) => {
                return (
                  <div
                    className="list-item"
                    key={d.id}
                    style={{
                      margin: d.expanded
                        ? i + 1 === data.length
                          ? `1rem 0 5rem`
                          : `1rem 0`
                        : i + 1 === data.length
                        ? `0 0 50px 0`
                        : `0 0 -1px 0`,
                    }}
                  >
                    <div className="list-item header" style={{ borderBottomWidth: d.expanded ? "0px" : "1px" }}>
                      <div className="title-container">
                        <h2>{d.tagNo}</h2>
                      </div>
                      <div>
                        {!showDeletedRecords ? (
                          <>
                            <EditIcon
                              onClick={() => {
                                dispatch(
                                  showModalPage({
                                    content: (
                                      <Modal
                                        editMode={true}
                                        getIsDataUpdated={getIsDataUpdated}
                                        initialForm={d}
                                        showDeletedRecords={showDeletedRecords}
                                      />
                                    ),
                                    title: `Edit Equipment (${selectedProject.number} - ${selectedProject.description})`,
                                  })
                                );
                              }}
                            />
                            {deleteLoading ? (
                              <InlineSpinner size={18} />
                            ) : (
                              <DeleteIcon onClick={() => handleDelete(d, true)} />
                            )}
                          </>
                        ) : (
                          <>
                            {deleteLoading ? (
                              <InlineSpinner size={18} />
                            ) : (
                              <RecycleIcon onClick={() => handleDelete(d, false)} />
                            )}
                          </>
                        )}
                        <PlusIcon
                          onClick={(e) => updateExpanded(d.id)}
                          className={d.expanded ? "rotate" : "rotate-neutral"}
                        />
                      </div>
                    </div>
                    <div className={`task-expanded ${d.expanded ? "show" : "hide"}`}>
                      <Row className="row">
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Tag No.: </b>
                            </Typography.Text>
                            <Typography.Text>{d.tagNo}</Typography.Text>
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Note: </b>
                            </Typography.Text>
                            <Typography.Text>{d.note}</Typography.Text>
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Customer System: </b>
                            </Typography.Text>
                            {customerSystemsObj[d.customerSystemId] && (
                              <Typography.Text ellipsis>{`${customerSystemsObj[d.customerSystemId].systemNumber} - ${
                                customerSystemsObj[d.customerSystemId].name
                              }`}</Typography.Text>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className="row">
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Ref No.: </b>
                            </Typography.Text>
                            <Typography.Text>{d.refNo}</Typography.Text>
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>MC Package: </b>
                            </Typography.Text>
                            <Typography.Text>{d.mcPackage}</Typography.Text>
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Location: </b>
                            </Typography.Text>
                            <Typography.Text>{d.location}</Typography.Text>
                          </div>
                        </Col>
                      </Row>
                      <Row className="row">
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>System Id: </b>
                            </Typography.Text>
                            <Typography.Text>{d.systemId}</Typography.Text>
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Jobpackage Inst.: </b>
                            </Typography.Text>
                            {jobpackageObj[d.jobPackageId] && (
                              <Typography.Text ellipsis>{jobpackageObj[d.jobPackageId].title}</Typography.Text>
                            )}
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Jobpackage MCCR: </b>
                            </Typography.Text>
                            {jobpackageObj[d.jobpackageMccr] && (
                              <Typography.Text ellipsis>{jobpackageObj[d.jobpackageMccr].title}</Typography.Text>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className="row">
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Manufactures: </b>
                            </Typography.Text>
                            {manufacturesObj[d.manufactureId] && (
                              <Typography.Text ellipsis>{manufacturesObj[d.manufactureId].name}</Typography.Text>
                            )}
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Drawing: </b>
                            </Typography.Text>
                            {drawingsObj[d.drawingId] && (
                              <Typography.Text ellipsis>{`${drawingsObj[d.drawingId].revisionNo} - ${
                                drawingsObj[d.drawingId].description
                              }`}</Typography.Text>
                            )}
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Datasheet: </b>
                            </Typography.Text>
                            {drawingsObj[d.datasheetId] && (
                              <Typography.Text ellipsis>{`${drawingsObj[d.datasheetId].revisionNo} - ${
                                drawingsObj[d.datasheetId].description
                              }`}</Typography.Text>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className="row">
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Installed: </b>
                            </Typography.Text>
                            <Typography.Text>
                              {d.installed ? (
                                <CheckCircleIcon color={colors.green} />
                              ) : (
                                <CloseCircleIcon color={colors.red} />
                              )}
                            </Typography.Text>
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Tag Sign Mounted: </b>
                            </Typography.Text>
                            <Typography.Text>
                              {d.tagSignMounted ? (
                                <CheckCircleIcon color={colors.green} />
                              ) : (
                                <CloseCircleIcon color={colors.red} />
                              )}
                            </Typography.Text>
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>Install Offshore: </b>
                            </Typography.Text>
                            <Typography.Text>
                              {d.installOffshore ? (
                                <CheckCircleIcon color={colors.green} />
                              ) : (
                                <CloseCircleIcon color={colors.red} />
                              )}
                            </Typography.Text>
                          </div>
                        </Col>
                      </Row>
                      <Row className="row">
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>EEX: </b>
                            </Typography.Text>
                            <Typography.Text>
                              {d.eex ? <CheckCircleIcon color={colors.green} /> : <CloseCircleIcon color={colors.red} />}
                            </Typography.Text>
                          </div>
                        </Col>
                        <Col className="col">
                          <div className="col container">
                            <Typography.Text className="title">
                              <b>MCCR Ok: </b>
                            </Typography.Text>
                            <Typography.Text>
                              {d.mccrOk ? <CheckCircleIcon color={colors.green} /> : <CloseCircleIcon color={colors.red} />}
                            </Typography.Text>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </ScrollView>
      </AccessCheck>
    </Page>
  );
};

const container = () => css`
  .header {
    background-color: ${colors.white};
    padding: 1rem;

    .punch-count-container {
      flex-basis: 50%;
      padding: 0 0.5rem;
      display: flex;
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.85rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      .dropdown {
        flex-grow: 1;
        margin: 0 0.35rem;
        flex-basis: 25%;

        &:first-of-type {
          margin-left: 0;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  .row {
    padding-top: 0.5rem;
  }

  .col {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    padding: 0 0.75rem 0.25rem 0.75rem;
    width: 33%;

    .container {
      display: flex;
      justify-content: flex-start;
      width: 100%;

      .title {
        margin-right: 3px;
      }
    }
  }

  .title-container {
    padding-left: 9px;
  }

  .list-item {
    background-color: ${colors.white};
    border-top: 1px #d1d1d1 solid;
    border-bottom: 1px #d1d1d1 solid;
    transition: margin 240ms ease;

    &.header {
      display: flex;
      padding: 0.75rem;
      background-color: ${colors.white};
      overflow: hidden;
      transition: background-color 160ms ease;
      transition-delay: 240ms;

      &:active {
        background-color: ${colors.ultraLightGrey};
        transition: background-color 80ms ease;
      }

      svg {
        height: 1.75rem;
        width: 1.75rem;
        margin-right: 0.5rem;
      }

      div:first-child {
        display: flex;
      }

      div:last-child {
        color: ${colors.darkGrey};
        margin-left: auto;
        display: flex;
        align-items: center;

        svg:last-child {
          margin-right: 0;
        }
      }
    }

    &.small-element-container {
      display: flex;
      padding-top: 3px;

      /* Makes text crop if overflowing (with ellipsis: ...) */
      .crop-text {
        border-width: 0px;
        text-overflow: ellipsis;
      }

      .column {
        border-width: 0px;
        display: flex;
        flex-direction: column;

        div {
          align-items: center;
          display: flex;
          padding: 0 0.75rem 0.25rem 0.75rem;

          .element-title {
            font-size: 1rem;
            font-weight: 700;
          }
        }
      }
    }
  }

  .hide {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 240ms ease, opacity 240ms ease, overflow 240ms ease;
  }

  .show {
    max-height: 1000px;
    opacity: 1;
    transition: max-height 240ms ease, opacity 240ms ease;
  }

  .rotate {
    transform: rotate(-45deg);
    transition: transform 240ms ease;
  }
  .rotate-neutral {
    transform: rotate(0deg);
    transition: transform 240ms ease;
  }

  .remark {
    display: flex;
    padding: 0.5rem;
    background-color: ${colors.yellowLight};
    margin: 0.75rem;
    border: 2px solid ${colors.yellow};
    border-radius: 5px;

    svg {
      margin-right: 0.5rem;
    }
  }

  button svg {
    display: inline-block;
    margin-bottom: -0.35rem;
  }

  h2 {
    display: flex;
    align-items: center;
    color: ${colors.black};
    font-size: 1.125rem;
    font-weight: 700;
  }
`;

export default memo(ManageEquipmentsOverview);
