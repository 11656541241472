export default styleType => {
  switch (styleType) {
    case "none":
    case "default":
    case "neutral":
    case "standard":
      return "neutral";

    case "ok":
    case "success":
    case "succes":
    case "positive":
      return "ok";

    case "error":
    case "warning":
    case "failed":
    case "fail":
      return "error";

    default:
      return "neutral";
  }
};
