import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "emotion";
import { Typography, notification } from "antd";
import { isEqual } from "lodash";

import colors from "../../../../style/colors";

import Button from "../../../ui/Button";
import DropDown from "../../../ui/DropDown";
import FormItem from "../../../ui/FormItem";
import InlineSpinner from "../../../ui/InlineSpinner";
import ScrollView from "../../../ui/ScrollView";
import TextInput from "../../../ui/TextInput";

import req from "../../../../utilities/request-utility";
import getErrorMessage from "../../../../utilities/get-error-message";

import { hideModalPage } from "../../../../actions/uiActions";

const ManageLibraryModal = ({
  folderOptions = [],
  folderOptionsRefetch = () => {},
  folderRefetch = () => {},
  form = null,
}) => {
  const [formData, setFormData] = useState(form ? form : { parentFolder: "", name: "" });
  const [formLoading, setFormloading] = useState(false);
  const [options, setOptions] = useState(folderOptions);

  const dispatch = useDispatch();

  const { selectedFolder } = useSelector(({ library }) => ({ selectedFolder: library.selectedFolder }), isEqual);

  const withForm = useMemo(() => (form ? true : false), [JSON.stringify(form)]);

  const isFormValid = useCallback(
    (formData) => {
      if (!formData.name) return { status: false, message: "Make sure to fill out name and then try again" };
      return { status: true };
    },
    [JSON.stringify(formData)]
  );

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const submit = useCallback(async () => {
    try {
      const isFormValidData = isFormValid(formData);
      if (!isFormValidData.status)
        return notification.error({ duration: 7, message: "FAILED", description: isFormValidData.message });

      setFormloading(true);

      if (!withForm) await req().post(`semcompletion/library/folders`, formData);
      else await req().put(`semcompletion/library/folders/${formData.id}`, formData);

      setFormloading(false);

      dispatch(hideModalPage());

      folderRefetch();
      folderOptionsRefetch();

      notification.success({
        duration: 7,
        message: "SUCCESS",
        description: withForm ? "You have successfully updated a folder" : "You have successfully added a folder",
      });
    } catch (error) {
      notification.error({ duration: 7, message: "FAILED", description: getErrorMessage(error) });
    }
  }, [JSON.stringify(formData), withForm]);

  const getNewFolderOptions = useCallback(
    (id) => {
      const childFolders = folderOptions.filter((d) => d.parentFolder === id);

      if (childFolders.length === 0) return null;

      let childFoldersIds = [];

      childFolders.map((d) => {
        childFoldersIds.push(d.id);

        const childIdArr = getNewFolderOptions(d.id);
        if (childIdArr) childFoldersIds = [...childFoldersIds, ...childIdArr];
      });

      return childFoldersIds;
    },
    [folderOptions]
  );

  useEffect(() => {
    if (selectedFolder && !withForm) setFormData({ parentFolder: selectedFolder.id });

    if (withForm) {
      let childFoldersIds = getNewFolderOptions(formData.id);
      childFoldersIds ? childFoldersIds.push(formData.id) : (childFoldersIds = [formData.id]);

      let newFolderOptions = folderOptions.filter((d) => !childFoldersIds.includes(d.id));

      let archivedFolderIds = [];
      newFolderOptions
        .filter((d) => !d.active)
        .map((d) => {
          archivedFolderIds.push(d.id);

          const childFolderIds = getNewFolderOptions(d.id);
          if (childFolderIds) archivedFolderIds = [...archivedFolderIds, ...childFolderIds];
        });

      newFolderOptions = newFolderOptions.filter((d) => !archivedFolderIds.includes(d.id));

      setOptions(newFolderOptions);
    }
  }, []);

  return (
    <ScrollView className={container()}>
      <div className="row">
        <div className="col-100">
          {selectedFolder ? (
            <FormItem label="Parent Folder">
              {withForm ? (
                <DropDown
                  name="parentFolder"
                  onChange={handleChange}
                  options={options.map((d) => ({ label: d.name, value: d.id }))}
                  placeholder="Click to choose"
                  value={formData.parentFolder}
                />
              ) : (
                <Typography.Text>{selectedFolder.name}</Typography.Text>
              )}
            </FormItem>
          ) : withForm ? (
            <FormItem label="Parent Folder">
              <DropDown
                name="parentFolder"
                onChange={handleChange}
                options={options.map((d) => ({ label: d.name, value: d.id }))}
                placeholder="Click to choose"
                value={formData.parentFolder}
              />
            </FormItem>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <FormItem label="Name" required={true}>
            <TextInput name="name" onChange={handleChange} placeholder="Enter Name" value={formData.name} />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <Button disabled={formLoading || !isFormValid(formData).status} onClick={submit}>
            {formLoading && <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />}{" "}
            Save
          </Button>
        </div>
      </div>
    </ScrollView>
  );
};

const container = () => css`
  background-color: ${colors.white};
  padding: 1rem 0;

  label {
    font-weight: 700;
    margin-bottom: 0.35rem;
    display: block;
  }

  .ant-select-clear {
    opacity: 1 !important;
  }

  .row {
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto 1.25rem auto;

    .col-50 {
      flex-basis: 49%;
    }

    .col-100 {
      flex-basis: 100%;
    }

    @media screen and (max-width: 650px) {
      flex-wrap: wrap;

      .col-50 {
        flex-basis: 100%;

        &:nth-child(odd) {
          margin-bottom: 1.25rem;
        }
      }
    }
  }
`;

export default memo(ManageLibraryModal);
