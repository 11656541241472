import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import queryString from "query-string";

// Actions
import { showContextMenu } from "../../actions/uiActions";
import { renewToken } from "../../actions/authActions";
import { getLanguage } from "../../actions/languageActions";

// Utilities
import pageNavigator from "../../utilities/page-navigator";
import getBackButtonURL from "../../utilities/get-back-button-url";

// Components
import UserSettings from "./UserSettings";
import TopBar from "../../components/ui/TopBar";
import TabBar from "../../components/ui/TabBar";
import TabView from "../../components/ui/TabView";
import Page from "../../components/ui/Page";
import { ArrowBackIcon, CogOutlineIcon } from "mdi-react";
import InlineSpinner from "../ui/InlineSpinner";

class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Page and navigation
      page: {},
      tabs: [],
      activeTabIndex: 0,
      backButtonUrl: getBackButtonURL(),
      reloadingLanguage: false, // removes the entire page while reload happens
    };
  }

  componentDidMount() {
    this._mounted = true;

    // Get tabId from queryParam
    let queryStrings = queryString.parse(window.location.search);
    if (queryStrings.tab) this.setState({ activeTabIndex: Number(queryStrings.tab) });

    this.setState({
      tabs: [
        <>
          <CogOutlineIcon /> {this.props.lang.settings}
        </>,
      ],
    });
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    let { tabs, backButtonUrl } = this.state;
    let { user, match } = this.props;

    const tabComponents = [<UserSettings key="user-settings" />];

    if (this.state.reloadingLanguage) {
      return (
        <Page>
          <TopBar title="..." />
          <TabBar tabs={[]} />
          <InlineSpinner title="Updating language..." style={{ marginTop: "3rem" }} />
        </Page>
      );
    }

    return (
      <Page>
        <TopBar
          title="Profile"
          actionLeft={
            <ArrowBackIcon
              onClick={() => {
                pageNavigator(backButtonUrl, "backward");
              }}
              data-test-id="arrow-back"
            />
          }
        />

        {Number(match.params.userId) === user.id && (
          <TabBar
            activeTabIndex={0}
            tabs={tabs.map((tab, tabIndex) => {
              return {
                title: tab,
                onClick: () => {
                  this.setState({ activeTabIndex: tabIndex });
                },
              };
            })}
          />
        )}
        <TabView activeTabIndex={0} tabs={tabComponents} useScrollView={true} />
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  pages: state.pages.pages,
  appConfig: state.appConfig,
  user: state.auth.user,
  lang: state.language.language,
  state: state, // This is used to update localStorage when changing language
});
const mapDispatchToProps = (dispatch) => ({
  renewToken: bindActionCreators(renewToken, dispatch),
  getLanguage: bindActionCreators(getLanguage, dispatch),
  showContextMenu: bindActionCreators(showContextMenu, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
