import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useQuery } from "react-query";
import params from "jquery-param";

import req from "../../../../utilities/request-utility";

const LIMIT = 40;
let statusCountTimeout;

function useOverviewData(selectedPunchList = "") {
  const dispatch = useDispatch();

  const selectedProject = useSelector((s) => s.semcompletion.selectedProject);
  const auth = useSelector((s) => s.auth);

  // META DATA
  const {
    isLoading: metaDataIsLoading,
    error: metaDataError,
    data: { data: metaDataData = {} } = {},
    refetch: metaDataRefetch,
  } = useQuery(`${selectedProject ? selectedProject.id : "project"}-punch-metaData`, () =>
    req()(`semcompletion/v2/punches/meta-data?${params({ punchListId: selectedPunchList })}`)
  );

  const { data: { data: assigneeList = [] } = {}, refetch: assigneeListRefetch } = useQuery(
    ["AssigneeList", selectedPunchList],
    () => req()(`semcompletion/v2/punches/assignee-list?${params({ punchListId: selectedPunchList })}`)
  );

  const metaData = { data: metaDataData, loading: metaDataIsLoading, error: metaDataError };

  // Data states
  const [openPunches, setOpenPunches] = useState({
    data: [],
    loading: false,
    error: false,
    endOfFeed: false,
  });

  const [clearedPunches, setClearedPunches] = useState({
    data: [],
    loading: false,
    error: false,
    endOfFeed: false,
  });

  const [closedPunches, setClosedPunches] = useState({
    data: [],
    loading: false,
    error: false,
    endOfFeed: false,
  });

  const [favoritedPunches, setFavoritedPunches] = useState({
    data: [],
    loading: false,
    error: false,
    endOfFeed: false,
  });

  const [assignedToYouPunches, setAssignedToYouPunches] = useState({
    data: [],
    loading: false,
    error: false,
    endOfFeed: false,
  });

  const [statusCounts, setStatusCounts] = useState({
    data: {},
    loading: true,
    error: false,
  });

  function getStatusCounts({ filterData = {}, setAsLoading = false }) {
    if (setAsLoading) setStatusCounts({ ...statusCounts, loading: true });

    let query = {};

    // Add query params to list
    if (filterData.punchList) query = { ...query, punchList: filterData.punchList };
    if (filterData.punchOwner) query = { ...query, punchOwner: filterData.punchOwner };
    if (filterData.punchOrigin) query = { ...query, punchOrigin: filterData.punchOrigin };
    if (filterData.punchCategory) query = { ...query, punchCategory: filterData.punchCategory };
    if (filterData.punchLocation) query = { ...query, punchLocation: filterData.punchLocation };
    if (filterData.system) query = { ...query, system: filterData.system };
    if (filterData.area) query = { ...query, area: filterData.area };
    if (filterData.searchterm) query = { ...query, searchterm: filterData.searchterm };
    if (filterData.user) query = { ...query, user: filterData.user };
    if (filterData.assignee) query = { ...query, assignee: filterData.assignee };

    clearTimeout(statusCountTimeout);
    statusCountTimeout = setTimeout(() => {
      req()(`semcompletion/v2/punches/status-count?${params({ ...query, projectId: selectedProject.id })} `)
        .then(({ data }) => setStatusCounts({ data, loading: false, error: false }))
        .catch(() => dispatch({ template: "error", content: "Could not get task punch count" }));
    }, 700);
  }

  function getRefreshDataLenght(number) {
    if (number < LIMIT) {
      return LIMIT;
    } else {
      return number;
    }
  }

  /**
   * @param {Object} options
   * @param {Number} options.tabIndex - The index of the for which to get data
   * @param {Number} options.refreshData - If set to true, the already loaded data will be replaced with new data
   */
  const getData = async ({ tabIndex, refreshData = false, filterData, clearDataBeforeFetch = false }) => {
    let baseURL = `semcompletion/v2/punches`;
    let query = {};

    // Add query params to list
    if (filterData.punchList) query = { ...query, punchList: filterData.punchList };
    if (filterData.punchOwner) query = { ...query, punchOwner: filterData.punchOwner };
    if (filterData.punchOrigin) query = { ...query, punchOrigin: filterData.punchOrigin };
    if (filterData.punchCategory) query = { ...query, punchCategory: filterData.punchCategory };
    if (filterData.punchLocation) query = { ...query, punchLocation: filterData.punchLocation };
    if (filterData.system) query = { ...query, system: filterData.system };
    if (filterData.area) query = { ...query, area: filterData.area };
    if (filterData.user) query = { ...query, user: filterData.user };
    if (filterData.assignee) query = { ...query, assignee: filterData.assignee };
    if (filterData.searchterm) query = { ...query, searchterm: filterData.searchterm };

    // Open
    if (tabIndex === 0) {
      if (refreshData === false && (openPunches.loading || openPunches.endOfFeed)) return;

      setOpenPunches({
        ...openPunches,
        loading: true,
        error: false,
        data: clearDataBeforeFetch ? [] : openPunches.data,
      });

      let URL = `${baseURL}?${params({
        ...query,
        status: "Open",
        limit: refreshData ? getRefreshDataLenght(openPunches.data.length) : LIMIT,
        offset: refreshData ? 0 : openPunches.data.length,
      })}`;

      try {
        const { data } = await req()(URL);

        setOpenPunches({
          data: [...(refreshData ? [] : openPunches.data), ...data],
          loading: false,
          error: false,
          endOfFeed: data.length < LIMIT ? true : false,
        });
      } catch (err) {
        // dispatch({ template: "error", content: "Failed to get open punches" });
        setOpenPunches({
          ...openPunches,
          error: true,
          loading: false,
        });
      }
    }

    // Cleared
    if (tabIndex === 1) {
      if (refreshData === false && (clearedPunches.loading || clearedPunches.endOfFeed)) return;

      setClearedPunches({
        ...clearedPunches,
        loading: true,
        error: false,
        data: clearDataBeforeFetch ? [] : clearedPunches.data,
      });

      let URL = `${baseURL}?${params({
        ...query,
        status: "Cleared",
        limit: refreshData ? getRefreshDataLenght(clearedPunches.data.length) : LIMIT,
        offset: refreshData ? 0 : clearedPunches.data.length,
      })}`;

      try {
        const { data } = await req()(URL);

        setClearedPunches({
          data: [...(refreshData ? [] : clearedPunches.data), ...data],
          loading: false,
          error: false,
          endOfFeed: data.length < LIMIT ? true : false,
        });
      } catch (err) {
        // dispatch({ template: "error", content: "Failed to get cleared punches" });
        setClearedPunches({
          ...clearedPunches,
          error: true,
          loading: false,
        });
      }
    }

    // Closed
    if (tabIndex === 2) {
      if (refreshData === false && (closedPunches.loading || closedPunches.endOfFeed)) return;

      setClosedPunches({
        ...closedPunches,
        loading: true,
        error: false,
        data: clearDataBeforeFetch ? [] : closedPunches.data,
      });

      let URL = `${baseURL}?${params({
        ...query,
        status: "Closed",
        limit: refreshData ? getRefreshDataLenght(closedPunches.data.length) : LIMIT,
        offset: refreshData ? 0 : closedPunches.data.length,
      })})}`;

      try {
        const { data } = await req()(URL);

        setClosedPunches({
          data: [...(refreshData ? [] : closedPunches.data), ...data],
          loading: false,
          error: false,
          endOfFeed: data.length < LIMIT ? true : false,
        });
      } catch (err) {
        // dispatch({ template: "error", content: "Failed to get closed punches" });
        setClosedPunches({
          ...closedPunches,
          error: true,
          loading: false,
        });
      }
    }

    if (tabIndex === 3) {
      if (refreshData === false && (favoritedPunches.loading || favoritedPunches.endOfFeed)) return;

      setFavoritedPunches({
        ...favoritedPunches,
        loading: true,
        error: false,
        data: clearDataBeforeFetch ? [] : favoritedPunches.data,
      });

      let URL = `${baseURL}/favorite?${params({
        ...query,
        limit: refreshData ? getRefreshDataLenght(favoritedPunches.data.length) : LIMIT,
        offset: refreshData ? 0 : favoritedPunches.data.length,
      })}`;

      try {
        const { data } = await req()(URL);

        setFavoritedPunches({
          data: [...(refreshData ? [] : favoritedPunches.data), ...data],
          loading: false,
          error: false,
          endOfFeed: data.length < LIMIT ? true : false,
        });
      } catch (err) {
        setFavoritedPunches({
          ...favoritedPunches,
          error: true,
          loading: false,
        });
      }
    }

    if (tabIndex === 4) {
      if (refreshData === false && (assignedToYouPunches.loading || assignedToYouPunches.endOfFeed)) return;

      setAssignedToYouPunches({
        ...assignedToYouPunches,
        loading: true,
        error: false,
        data: clearDataBeforeFetch ? [] : assignedToYouPunches.data,
      });

      let URL = `${baseURL}?${params({
        ...query,
        assignee: auth.user.id,
        status: "Open,Cleared",
        limit: refreshData ? getRefreshDataLenght(assignedToYouPunches.data.length) : LIMIT,
        offset: refreshData ? 0 : assignedToYouPunches.data.length,
      })}`;

      try {
        const { data } = await req()(URL);

        setAssignedToYouPunches({
          data: [...(refreshData ? [] : assignedToYouPunches.data), ...data],
          loading: false,
          error: false,
          endOfFeed: data.length < LIMIT ? true : false,
        });
      } catch (err) {
        // dispatch({ template: "error", content: "Failed to get closed punches" });
        setAssignedToYouPunches({
          ...assignedToYouPunches,
          error: true,
          loading: false,
        });
      }
    }
  };

  return {
    openPunches,
    clearedPunches,
    closedPunches,
    favoritedPunches,
    assignedToYouPunches,
    statusCounts,
    getStatusCounts,
    metaData,
    metaDataRefetch,
    getData,
    assigneeListRefetch,
    users: assigneeList.map((d) => ({ id: d.id, name: d.name })),
  };
}

export default useOverviewData;
