import req from "../utilities/request-utility";
import { GET_LANGUAGE, GET_LANGUAGE_SUCCESS, GET_LANGUAGE_FAILURE } from "./actionTypes";

export function getLanguage() {
  return function(dispatch, getState) {
    dispatch({
      type: GET_LANGUAGE
    });

    req()("/language/")
      .then(({ data }) => {
        return dispatch({
          type: GET_LANGUAGE_SUCCESS,
          payload: data
        });
      })
      .catch(() => {
        return dispatch({
          type: GET_LANGUAGE_FAILURE
        });
      });
  };
}
