import React, { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "react-query";
import { css } from "emotion";
import { Checkbox, notification } from "antd";
import * as yup from "yup";

import { hideModalPage } from "../../../../actions/uiActions";

import useOverviewData from "./hooks/useOverviewData";

import Button from "../../../ui/Button";
import DropDown from "../../../ui/DropDown";
import FormItem from "../../../ui/FormItem";
import InlineSpinner from "../../../ui/InlineSpinner";
import ScrollView from "../../../ui/ScrollView";
import TextInput from "../../../ui/TextInput";

import getErrorMessage from "../../../../utilities/get-error-message";
import req from "../../../../utilities/request-utility";

import colors from "../../../../style/colors";

const ManageEquipmentsModal = ({ editMode, getIsDataUpdated = null, initialForm, showDeletedRecords }) => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const [formLoading, setFormloading] = useState(false);
  const [form, setForm] = useState({
    system: initialForm?.customerSystemId || "",
    projectArea: initialForm?.areaId || "",
    jobpackageInstallation: initialForm?.jobPackageId || "",
    manufactures: initialForm?.manufactureId || "",
    jobpackageMCCR: initialForm?.jobpackageMccr || "",
    drawing: initialForm?.drawingId || "",
    datasheet: initialForm?.datasheetId || "",
    installed: initialForm?.installed || false,
    isDeleted: initialForm?.isDeleted || false,
    tagSignMounted: initialForm?.tagSignMounted || false,
    installOffshore: initialForm?.installOffshore || false,
    eex: initialForm?.eex || false,
    mccrOk: initialForm?.mccrOk || false,
    tagNo: initialForm?.tagNo || "",
    refNo: initialForm?.refNo || "",
    location: initialForm?.location || "",
    systemId: initialForm?.systemId || "",
    note: initialForm?.note || "",
    mcPackage: initialForm?.mcPackage || "",
  });

  const validationSchema = yup.object().shape({
    system: yup.string().required("System is required"),
    projectArea: yup.string().required("Area is required"),
    tagNo: yup.string().required("Tag No is required"),
  });

  const { metaData } = useOverviewData({ query: { isDeleted: showDeletedRecords } });

  const handleChange = useCallback(
    (e) => {
      if (e.target.name === "systemId" && e.target.value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`]+/g))
        e.target.value = e.target.value.replace(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`]+/g, "");

      if ((e.target.name === "refNo" || e.target.name === "systemId") && e.target.value.length > 10) return;

      if (e.target.type === "checkbox") return setForm({ ...form, [e.target.name]: e.target.checked });

      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    },
    [form]
  );

  const isFormValid = useCallback(async () => {
    try {
      await validationSchema.validate(form);

      return { status: true };
    } catch (error) {
      return {
        status: false,
        message: error.message,
      };
    }
  }, [form]);

  const saveEquipment = useCallback(async () => {
    const isFormValidData = await isFormValid();

    if (!isFormValidData.status)
      return notification.error({ duration: 7, message: "FAILED", description: isFormValidData.message });

    setFormloading(true);

    const handleOk = () => {
      dispatch(hideModalPage());

      queryClient.refetchQueries(["equipmentsPage", { isDeleted: showDeletedRecords }]);

      if (getIsDataUpdated) getIsDataUpdated(true);

      setFormloading(false);

      notification.success({ duration: 7, message: "SUCCESS", description: "You have successfully updated an equipment" });
    };

    const handleError = (err) => {
      const error = getErrorMessage(err);

      notification.error({ duration: 7, message: "FAILED", description: error });

      setFormloading(false);
    };

    if (editMode) req().put(`semcompletion/equipments/${initialForm.id}`, form).then(handleOk).catch(handleError);
    else req().post(`semcompletion/equipments`, form).then(handleOk).catch(handleError);
  }, [form, editMode, showDeletedRecords, getIsDataUpdated]);

  return (
    <ScrollView className={container()}>
      <div className="row">
        <div className="col-50">
          <FormItem label="System" required={true}>
            <DropDown
              name="system"
              onChange={handleChange}
              options={
                metaData.data.systems && metaData.data.systems.length !== 0
                  ? metaData.data.systems.map((d) => ({ label: `${d.systemNumber} - ${d.name}`, value: d.id }))
                  : []
              }
              value={form.system}
            />
          </FormItem>
        </div>
        <div className="col-50">
          <FormItem label="Area" required={true}>
            <DropDown
              name="projectArea"
              onChange={handleChange}
              options={
                metaData.data.projectAreas && metaData.data.projectAreas.length !== 0
                  ? metaData.data.projectAreas.map((d) => ({ label: `${d.code} - ${d.name}`, value: d.id }))
                  : []
              }
              value={form.projectArea}
            />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-50">
          <FormItem label="JobPackage Installation">
            <DropDown
              name="jobpackageInstallation"
              onChange={handleChange}
              options={
                metaData.data.jobPackages && metaData.data.jobPackages.length !== 0
                  ? metaData.data.jobPackages.map((d) => ({ label: d.title, value: d.id }))
                  : []
              }
              value={form.jobpackageInstallation}
            />
          </FormItem>
        </div>
        <div className="col-50">
          <FormItem label="Manufactures">
            <DropDown
              name="manufactures"
              onChange={handleChange}
              options={
                metaData.data.manufactures && metaData.data.manufactures.length !== 0
                  ? metaData.data.manufactures.map((d) => ({ label: d.name, value: d.id }))
                  : []
              }
              value={form.manufactures}
            />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <FormItem label="JobPackage MCCR">
            <DropDown
              name="jobpackageMCCR"
              onChange={handleChange}
              options={
                metaData.data.jobPackages && metaData.data.jobPackages.length !== 0
                  ? metaData.data.jobPackages.map((d) => ({ label: d.title, value: d.id }))
                  : []
              }
              value={form.jobpackageMCCR}
            />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <FormItem label="Drawing">
            <DropDown
              name="drawing"
              onChange={handleChange}
              options={
                metaData.data.drawings && metaData.data.drawings.length !== 0
                  ? metaData.data.drawings.map((d) => ({ label: `${d.revisionNo} - ${d.description}`, value: d.id }))
                  : []
              }
              value={form.drawing}
            />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <FormItem label="Datasheet">
            <DropDown
              name="datasheet"
              onChange={handleChange}
              options={
                metaData.data.drawings && metaData.data.drawings.length !== 0
                  ? metaData.data.drawings.map((d) => ({ label: `${d.revisionNo} - ${d.description}`, value: d.id }))
                  : []
              }
              value={form.datasheet}
            />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-25">
          <Checkbox checked={form.installed} onChange={handleChange} name="installed">
            Installed
          </Checkbox>
        </div>
        <div className="col-25">
          <Checkbox checked={form.tagSignMounted} onChange={handleChange} name="tagSignMounted">
            Tag Sign Mounted
          </Checkbox>
        </div>
        <div className="col-25">
          <Checkbox checked={form.installOffshore} onChange={handleChange} name="installOffshore">
            Install Offshore
          </Checkbox>
        </div>
        <div className="col-25">
          <Checkbox checked={form.eex} onChange={handleChange} name="eex">
            EEX
          </Checkbox>
        </div>
        <div className="col-25">
          <Checkbox checked={form.mccrOk} onChange={handleChange} name="mccrOk">
            MCCR Ok
          </Checkbox>
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <FormItem label="Tagno" required={true}>
            <TextInput name="tagNo" onChange={handleChange} value={form.tagNo} />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <FormItem label="RefNo">
            <TextInput name="refNo" onChange={handleChange} value={form.refNo} />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <FormItem label="Location">
            <TextInput name="location" onChange={handleChange} value={form.location} />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <FormItem label="System ID">
            <TextInput name="systemId" onChange={handleChange} value={form.systemId} />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <FormItem label="Note">
            <TextInput name="note" onChange={handleChange} value={form.note} />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <FormItem label="MC Package">
            <TextInput name="mcPackage" onChange={handleChange} value={form.mcPackage} />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <Button disabled={formLoading} onClick={saveEquipment}>
            {formLoading && <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />}{" "}
            {editMode ? "Update" : "Create"} Equipment
          </Button>
        </div>
      </div>
    </ScrollView>
  );
};

const container = () => css`
  background-color: ${colors.white};
  padding: 1rem 0;

  label {
    font-weight: 700;
    margin-bottom: 0.35rem;
  }

  label.input-label {
    display: block;
  }

  .row {
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto 1.25rem auto;

    .col-25 {
      flex-basis: 25%;
    }

    .col-50 {
      flex-basis: 49%;
    }

    .col-100 {
      flex-basis: 100%;
    }

    @media screen and (max-width: 650px) {
      flex-wrap: wrap;

      .col-50 {
        flex-basis: 100%;

        &:nth-child(odd) {
          margin-bottom: 1.25rem;
        }
      }
    }
  }
`;

export default memo(ManageEquipmentsModal);
