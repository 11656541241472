import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { keyBy } from "lodash";
import { Result } from "antd";

// UTILITIES
import req from "../../../../utilities/request-utility";

// ui-Components
import InlineSpinner from "../../../ui/InlineSpinner";

// COMPONENTS
import PunchListList from "./PunchListList";

const PunchList = ({ onUpdate, onUpdatePunchListActions, userId = null }) => {
  const [punchList, setPunchList] = useState([]);

  const { data: { data: allPunchList = [] } = {}, isLoading: allPunchListLoading = false } = useQuery(
    "PunchList",
    () => req()(`semcompletion/v2/punches/punch-list`),
    { refetchOnWindowFocus: false }
  );

  const { data: { data: userPunchList = [] } = {}, isLoading: userPunchListLoading = false } = useQuery(
    ["UserPunchList", userId],
    () => req()(`semcompletion/v2/punches/punch-list?user=${userId}`),
    { refetchOnWindowFocus: false }
  );

  const { data: punchListActions = [], isLoading: punchListActionsLoading = false } = useQuery(
    "PunchListActions",
    async () => {
      const actionsList = await req()(`semcompletion/v2/punches/punch-list/actions`);

      if (actionsList.data.length === 0) return [];

      if (!userId) return actionsList.data.map((d) => ({ ...d, active: false, userActionId: null }));

      const userActions = await req()(`semcompletion/v2/punches/punch-list/actions?user=${userId}`);

      if (userActions.data.length === 0) return actionsList.data.map((d) => ({ ...d, active: false, userActionId: null }));

      const userActionsObj = keyBy(userActions.data, "punchListActionId");

      return actionsList.data.map((d) => {
        const userActionObj = userActionsObj[d.id];
        if (userActionObj) return { ...d, active: userActionObj.active, userActionId: d.id };
        return { ...d, active: false, userActionId: null };
      });
    },
    { refetchOnWindowFocus: false }
  );

  const punchListActionsObj = punchListActions.length > 0 ? keyBy(punchListActions, "punchListId") : null;

  const onChangeStatus = (modifiedData) => {
    const newPunchList = punchList.map((d) => {
      const modifiedObj = modifiedData.find((p) => p.id === d.id);
      if (modifiedObj) return { ...d, active: modifiedObj.active };
      return d;
    });

    setPunchList(newPunchList);
  };

  const onChangePunchListActionStatus = (modifiedData) => {
    const newPunchList = punchList.map((d) => {
      d.actions = d.actions.map((p) => {
        const modifiedObj = modifiedData.find((m) => p.id === m.id || m.punchListId === d.id);
        if (modifiedObj) return { ...p, active: modifiedObj.active };
        return p;
      });

      return d;
    });

    setPunchList(newPunchList);
  };

  // get assigned punchlist to user and adjust punchlist data
  useEffect(() => {
    if (allPunchList.length > 0 || userPunchList.length > 0) {
      const assignedPunchListObj = keyBy(userPunchList, "id");

      const newPunchList = allPunchList.map((d) => {
        return {
          ...d,
          active: assignedPunchListObj[d.id] ? 1 : 0,
          actions: punchListActions.filter((p) => p.punchListId === d.id),
        };
      });

      setPunchList(newPunchList);
    }
  }, [userId, JSON.stringify(allPunchList), JSON.stringify(userPunchList), JSON.stringify(punchListActionsObj)]);

  useEffect(() => {
    // always call onUpdate if there's any changes in the punchlist
    if (onUpdate) onUpdate(punchList.map((d) => ({ active: d.active, id: d.id })));
    if (onUpdatePunchListActions) onUpdatePunchListActions(punchList.map((d) => d.actions));
  }, [JSON.stringify(punchList)]);

  const isLoading = allPunchListLoading || userPunchListLoading;

  return (
    <div>
      {isLoading && <InlineSpinner />}
      {!isLoading && punchList.length === 0 && <Result status="warning" title="No punch list found" />}

      {!isLoading &&
        punchList.length > 0 &&
        punchList.map((d) => {
          return (
            <PunchListList
              key={d.id}
              loading={allPunchListLoading || userPunchListLoading || punchListActionsLoading}
              onChangeStatus={onChangeStatus}
              onChangePunchListActionStatus={onChangePunchListActionStatus}
              data={d}
            />
          );
        })}
    </div>
  );
};

export default PunchList;
