import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";

const CheckIsAdmin = () => {
  const [isAdmin, setIsAdmin] = useState(false);

  const pages = useSelector(({ pages }) => pages.pages, isEqual);

  const getIsAdmin = () => {
    const hasAdmin = pages.find((d) => d.title.toLowerCase() === "admin");
    setIsAdmin(hasAdmin ? true : false);
  };

  useEffect(() => {
    getIsAdmin();
  }, []);

  return isAdmin;
};

export default CheckIsAdmin;
