import { WIREDIAGRAM } from "../actions/actionTypes";

const INITIAL_STATE = {
  isEditing: false,
};

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case WIREDIAGRAM.SETISEDITING:
      return { ...state, isEditing: payload.isEditing };
    default:
      return state;
  }
};
