import { LIBRARY } from "./actionTypes";

export const setFolders = (payload) => ({ type: LIBRARY.SETFOLDERS, payload });

export const setSelectedFolder = (payload) => ({ type: LIBRARY.SETSELECTEDFOLDER, payload });

export const setUrlSuffix = (payload) => ({ type: LIBRARY.SETURLSUFFIX, payload });

export const setSelectedManageLibraryPath = (payload) => ({ type: LIBRARY.SETSELECTEDMANAGELIBRARYPATH, payload });

export const setSelectedLibraryPath = (payload) => ({ type: LIBRARY.SETSELECTEDLIBRARYPATH, payload });

export const setLibraryFileQuery = (payload) => ({ type: LIBRARY.SETlIBRARYFILEQUERY, payload });

export const setLibraryFolderQuery = (payload) => ({ type: LIBRARY.SETLIBRARYFOLDERQUERY, payload });

export const setLibraryURLQuery = (payload) => ({ type: LIBRARY.SETLIBRARYURLQUERY, payload });

export const setManageLibraryFileQuery = (payload) => ({ type: LIBRARY.SETMANAGELIBRARYFILEQUERY, payload });

export const setManageLibraryFolderQuery = (payload) => ({ type: LIBRARY.SETMANAGELIBRARYFOLDERQUERY, payload });

export const setManageLibraryURLQuery = (payload) => ({ type: LIBRARY.SETMANAGELIBRARYURLQUERY, payload });
