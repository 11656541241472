import { MCTASKS } from "../actions/actionTypes";

const initialState = {
  newTasks: null,
};

export default function (state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case MCTASKS.SETNEWTASK:
      return { ...state, newTasks: payload !== null ? (state.newTasks ? [...state.newTasks, payload] : [payload]) : null };
    default:
      return state;
  }
}
