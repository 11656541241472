import { GET_PAGES, SET_TRANSITION_DIRECTION, GET_PAGES_FAILURE, GET_PAGES_SUCCESS } from "../actions/actionTypes";

const initialState = {
  allPages: [],
  pages: [],
  loading: true,
  error: false,
  transitionDirection: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PAGES:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case GET_PAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case GET_PAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        pages: action.payload.pages,
        allPages: action.payload.allPages,
      };

    case SET_TRANSITION_DIRECTION:
      return {
        ...state,
        transitionDirection: action.payload,
      };

    default:
      return state;
  }
}
