import { PDF } from "../actions/actionTypes";

export const initialState = {
  hasChanges: false,
  pdfViewer: null,
  readOnly: true,
};

export default function (state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case PDF.SETHASCHANGES:
      return { ...state, hasChanges: payload };
    case PDF.SETPDFVIEWER:
      return { ...state, pdfViewer: payload };
    case PDF.SETREADONLY:
      return { ...state, readOnly: payload };
    default:
      return state;
  }
}
